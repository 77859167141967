import React from 'react'

import { toast } from 'react-toastify'

import BlogForm from './blogComponents/BlogForm'
import WithHeader from '../../../layouts/WithHeader'

import { useCreateBlogMutation } from '../../../rtkStore/services/blogApi'
import { useNavigate } from 'react-router-dom'

export const BlogCreateScreen = () => {
  const navigate = useNavigate()

  const breadcrumbs = [
    {
      title: 'Blogs',
      path: '/admin/blog/list',
    },
    {
      title: 'Create Blog',
    },
  ]

  // RTK Query
  const [createBlog, { isLoading: creatingBlog, error }] =
    useCreateBlogMutation()

  // methods
  const handleBlogCreate = (data) => {
    console.log(data)
    createBlog(data)
      .unwrap()
      .then(() => {
        navigate(breadcrumbs[0].path)
        toast.success('Blog created successfully')
      })
      .catch(() => toast.error('Something went wrong!'))
  }

  return (
    <>
      <WithHeader
        breadcrumbs={breadcrumbs}
        isBusy={creatingBlog}
        error={error && (error?.data?.message ?? 'Something went wrong!')}
      >
        <BlogForm isBusy={creatingBlog} onSubmit={handleBlogCreate} />
      </WithHeader>
    </>
  )
}
