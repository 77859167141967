import React from 'react'
import styled from 'styled-components'
// import FullButton from '../Buttons/FullButton'
import gatewayStory from '../../assets/img/gatewayStory.png'

export default function Services() {
  return (
    <Wrapper id='aboutUs'>
      <div className='lightBg'>
        <div className='container'>
          <Advertising className='flexSpaceCenter'>
            <AddLeft>
              <h4 className='font15 semiBold'>A few words about our company</h4>
              <h2 className='font40 extraBold'>The AssistXGateway</h2>
              <h1 className='font20 bold'>Prepare, Prevent, Respond.</h1>
              <p className='font13'>
                {/* AssistXGateway Technologies was born out of partnership between AMI
                Expeditionary Healthcare (AMI), a leading global healthcare
                company, and emerging Australian technology firm, The Can Brand
                (TCB). 
                By  */}
                {/* Combining AMI Expeditionary Healthcare's expeditionary nature
                and expertise in providing solutions across sectors such as
                healthcare, security, assistance and aviation services with The
                Can Brand’s data science & AI capabilities, AssistXGateway has
                been founded on the principles of producing innovative and
                impactful solutions to problems of any degree of difficulty. */}
                AssistXGateway is health & security risk management platform.
                Our platform provides you and your employees information to
                prepare and prevent from health and security risks worldwide. We
                provide you with 24/7 comprehensive support & response wherever
                you are. Travel the world with peace of mind.
              </p>
              {/* <ButtonsRow
                className='flexNullCenter'
                style={{ margin: '30px 0' }}
              >
                <div style={{ width: '190px' }}>
                  <FullButton
                    title='Read More'
                    action={() => alert('clicked')}
                  />
                </div>
                <div style={{ width: '190px', marginLeft: '15px' }}>
                  <FullButton
                    title='Contact Us'
                    action={() => alert('clicked')}
                    border
                  />
                </div>
              </ButtonsRow> */}
            </AddLeft>
            <AddRight>
              <AddRightInner>
                <div>
                  <AddImgWrapp1>
                    <img src={gatewayStory} alt='office' />
                  </AddImgWrapp1>
                </div>
              </AddRightInner>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
`
const Advertising = styled.div`
  padding: 80px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 50px 0 30px 0;
    margin: 80px 0 0px 0;
  }
`
// const ButtonsRow = styled.div`
//   @media (max-width: 860px) {
//     justify-content: space-between;
//   }
// `
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 600px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }

    p {
      margin: 0 auto;
    }
  }
  > h4 {
    padding-top: 20px;
  }
  > h1 {
    line-height: 3rem;
    margin: 0px 0 10px 0;
  }
`
const AddRight = styled.div`
  width: 50%;
  float: right;
  @media (max-width: 860px) {
    width: 80%;
  }
`
const AddRightInner = styled.div`
  width: 100%;
`
const AddImgWrapp1 = styled.div`
  width: 50%;
  margin: 0 6% 10px 6%;
  float: right;

  img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  @media (max-width: 860px) {
    width: 80%;
    float: center;
    order: 2;
  }
`
