import React from 'react'
import styled from 'styled-components'
// import FullButton from '../Buttons/FullButton'
import travelAlert2 from '../../assets/img/image1.jpg'
import { Link as NewLink } from 'react-router-dom'
import FullButton from '../Buttons/FullButton'

export default function ExploreAlert() {
  return (
    <Wrapper id='#'>
      <div className='whiteBg'>
        <div className='container'>
          <Advertising className='flexSpaceCenter'>
            <AddLeft>
              <AddImgWrapp1>
                <img src={travelAlert2} alt='office' />
              </AddImgWrapp1>
            </AddLeft>
            <AddRight>
              <AddRightInner>
                <h2 className='font40 extraBold'>Explore Alerts</h2>
                <p className='font13'>
                  Discover the latest alerts related to your travel destinations
                  and receive real-time updates to stay informed and prepared.
                  Our alert system provides peace of mind during your travels.
                </p>
                <ButtonsRow
                  className='flexNullCenter'
                  style={{ margin: '30px 0' }}
                >
                  <div style={{ width: '190px' }}>
                    <a
                      href='https://alerts.assistxgateway.com/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <FullButton title='Explore' />
                    </a>
                  </div>
                </ButtonsRow>
              </AddRightInner>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
`
const Advertising = styled.div`
  padding: 80px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 50px 0 30px 0;
    margin: 80px 0 0px 0;
  }
`
// const ButtonsRow = styled.div`
//   @media (max-width: 860px) {
//     justify-content: space-between;
//   }
// `
const AddLeft = styled.div`
  float: left;
  align-text: left;
  width: 50%;
  p {
    max-width: 600px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }

    p {
      margin: 0 auto;
    }
  }
  > h4 {
    padding-top: 20px;
  }
  > h1 {
    line-height: 3rem;
    margin: 0px 0 10px 0;
  }
`
const AddRight = styled.div`
  width: 50%;
  float: right;
  @media (max-width: 860px) {
    width: 80%;
  }
`
const AddRightInner = styled.div`
  width: 100%;
`
const AddImgWrapp1 = styled.div`
  width: 50%;
  margin: 0 6% 10px 6%;
  float: left;

  img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  @media (max-width: 860px) {
    width: 80%;
    float: center;
    order: 2;
  }
`
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`
