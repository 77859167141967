import React from 'react'
import WithHeader from '../../../layouts/WithHeader'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import CustomLoader from '../../../components/shared/CustomLoader'
import TableFilter from '../../../components/shared/TableFilter'

import AddIcon from '@mui/icons-material/Add'
import { useListUserQuery } from '../../../rtkStore/services/userApi'
import { IconButton } from '@material-ui/core'
import { InfoOutlined, ModeEditOutlineTwoTone } from '@mui/icons-material'

const pageActions = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Link to='/admin/user/create'>
        <Button startIcon={<AddIcon />} variant='contained'>
          Add User
        </Button>
      </Link>
    </div>
  )
}

export const UserListScreen = () => {
  const [filterText, setFilterText] = React.useState('')
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false)
  const breadcrumbs = [
    {
      title: 'Users',
    },
  ]

  // RTK Query
  const { data: userList, isLoading: loadingUser, error } = useListUserQuery()

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Created Date',
      selector: (row) => moment(row?.createdAt).format('MMM Do YYYY'),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <Link to={`/admin/user/${row._id}/detail`}>
            <IconButton size='small' aria-label='View User' color='primary'>
              <InfoOutlined />
            </IconButton>
          </Link>
          {/* <Link to={`/admin/user/${row._id}/edit`}>
            <IconButton size='small' aria-label='Edit User'>
              <ModeEditOutlineTwoTone />
            </IconButton>
          </Link> */}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  const subHeaderComponent = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <TableFilter
        placeholder='Filter by title'
        value={filterText ? filterText : ''}
        onChange={(e) => setFilterText(e.target.value)}
        handleClear={handleClear}
      />
    )
  }, [filterText, resetPaginationToggle])

  return (
    <div>
      <WithHeader
        breadcrumbs={breadcrumbs}
        hasActions
        headerActions={pageActions}
        error={error && (error?.data?.message ?? 'Something went wrong!')}
      >
        <DataTable
          columns={columns}
          data={
            userList?.filter(
              (item) =>
                item &&
                item?.name.toLowerCase().includes(filterText.toLowerCase())
            ) ?? []
          }
          progressPending={loadingUser}
          progressComponent={<CustomLoader />}
          persistTableHead={true}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponent}
        />
      </WithHeader>
    </div>
  )
}
