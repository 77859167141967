import React, { useState } from 'react'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import Slider from 'react-slick'
import image31 from '../../assets/img/clients/logo001.png'
import image32 from '../../assets/img/clients/logo002.png'
import image33 from '../../assets/img/clients/logo003.png'
import image34 from '../../assets/img/clients/logo004.png'
import image35 from '../../assets/img/clients/logo005.png'
import image36 from '../../assets/img/clients/logo001.png'
import image37 from '../../assets/img/clients/logo002.png'
import image38 from '../../assets/img/clients/logo003.png'
import image39 from '../../assets/img/clients/logo004.png'
import image40 from '../../assets/img/clients/logo005.png'

const ClientCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    rows: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 1,
        },
      },
    ],
  }

  const [items] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])

  const imgList = [
    image31,
    image32,
    image33,
    image34,
    image35,
    image36,
    image37,
    image38,
    image39,
    image40,
  ]
  const urlList = [
    'https://www.thecanbrand.com/',
    'https://amiglobalassistance.com/',
    'https://www.firstsourceworld.com/',
    'https://www.pinnaclehealthcare.com.au/',
    'https://universalair.aero/',
    'https://www.thecanbrand.com/',
    'https://amiglobalassistance.com/',
    'https://www.firstsourceworld.com/',
    'https://www.pinnaclehealthcare.com.au/',
    'https://universalair.aero/',
  ]

  return (
    <Wrapper id='services'>
      <div className='container '>
        <div className='carousel-wrapper'>
          <Slider {...settings}>
            {items.map((item) => (
              <div key={item}>
                <a href={urlList[item]} rel='noreferrer' target='_blank'>
                  <img src={imgList[item]} alt='clientlogoes' />
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </Wrapper>
  )
}

export default ClientCarousel

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 0px;
`
