import React from "react";

import { useLocation, useNavigate } from "react-router-dom";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import getMuiIcon from "../../utils/getMuiIcon";

const ReusableSidebar = ({ menuList = [] }) => {
  const [open, setOpen] = React.useState({});

  const navigate = useNavigate();

  const location = useLocation();

  // methods
  const handleClick = (menu) => {
    menu?.subMenus?.length > 0
      ? setOpen({ [menu.title]: !open[menu.title] })
      : navigate(menu?.path);
  };

  const locationSubpath = location.pathname
    .split("/")
    .filter((el) => el.length)
    .splice(0, 2)
    .join("/");

  const isActiveRoute = (path) => {
    return path && path.includes(locationSubpath);
  };

  // menu styles
  const activeMenuItemStyles = (path) => {
    return {
      backgroundColor: !!isActiveRoute(path) ? "var(--background)" : "",
      borderLeft: !!isActiveRoute(path) ? "5px solid var(--primary)" : "",
      marginLeft: !!!isActiveRoute(path) ? "5px" : "",
    };
  };

  const activeTextStyles = (path) => {
    return {
      color: !!isActiveRoute(path) ? `var(--primary)` : "",
    };
  };

  React.useEffect(() => {
    if (!menuList.length) return;

    // expand nested menu on matched path
    const activeMenu = menuList
      .filter((m) => m?.subMenus?.length)
      .find((menu) => {
        return !!menu.subMenus.find((submenu) =>
          submenu.path.includes(locationSubpath)
        )
          ? menu
          : null;
      });

    activeMenu && setOpen({ [activeMenu.title]: !open[activeMenu.title] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List>
      {menuList.map((menu, index) => (
        <React.Fragment key={index}>
          <ListItem
            button
            key={`${menu.title}-${index}`}
            style={activeMenuItemStyles(menu.path)}
            onClick={() => handleClick(menu)}
          >
            <ListItemIcon>
              {getMuiIcon(menu.icon, {
                color: !!isActiveRoute(menu.path) ? "primary" : "",
              })}
            </ListItemIcon>
            <ListItemText
              primary={menu.title}
              style={activeTextStyles(menu.path)}
            />
            {menu?.subMenus?.length
              ? open[menu.title]
                ? getMuiIcon("KeyboardArrowUp")
                : getMuiIcon("KeyboardArrowDown")
              : null}
          </ListItem>
          {menu?.subMenus?.length && (
            <Collapse in={open[menu.title]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {menu.subMenus.map((submenu, i) => (
                  <ListItem
                    key={`${submenu.title}-${index}-${i}`}
                    button
                    style={{
                      paddingLeft: "30px",
                      ...activeMenuItemStyles(submenu.path),
                    }}
                    onClick={() => navigate(submenu.path)}
                  >
                    <ListItemIcon>
                      {getMuiIcon(submenu.icon, {
                        color: !!isActiveRoute(submenu.path) ? "primary" : "",
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={submenu.title}
                      style={activeTextStyles(submenu.path)}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default ReusableSidebar;
