import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      viewBox='0 0 64 64'
      width={43.42}
      height={43.42}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='graphic_design-browser-website-we_bdesign-online'
        data-name='graphic design-browser-website-we bdesign-online'
      >
        <path
          d='m3 43v2a4.012 4.012 0 0 0 4 4h50a4.012 4.012 0 0 0 4-4v-2z'
          fill='#9bc9ff'
        />
        <path d='m26 49h12v8h-12z' fill='#9bc9ff' />
        <path
          d='m19 57a2 2 0 0 0 -2 2v2h30v-2a2 2 0 0 0 -2-2z'
          fill='#9bc9ff'
        />
        <path
          d='m40.586 36.1-2.122 2.122-2.121 2.121 2.657 2.657 3 3 4.243-4.243z'
          fill='#9bc9ff'
        />
        <path
          d='m42 46 15.375 14.084a2.089 2.089 0 0 0 2.952-2.952l-12.946-14.132-1.138-1.243z'
          fill='#9bc9ff'
        />
        <path d='m11 13h16v16h-16z' fill='#9bc9ff' />
        <path d='m37 9h20v6h-20z' fill='#9bc9ff' />
        <path d='m37 19h20v6h-20z' fill='#9bc9ff' />
        <path d='m40 35h17v-6h-18v5a1 1 0 0 0 1 1z' fill='#9bc9ff' />
        <g fill='#1e81ce'>
          <path d='m62 45v-38a5 5 0 0 0 -5-5h-50a5 5 0 0 0 -5 5v38a5 5 0 0 0 5 5h18v6h-6a3.009 3.009 0 0 0 -3 3v2a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1v-2a3.009 3.009 0 0 0 -3-3h-6v-6h5.89c4.55 4.17 11.81 10.82 11.81 10.82a3.086 3.086 0 0 0 4.36-4.36s-2.8-3.07-5.91-6.46h1.85a5 5 0 0 0 5-5zm-58-38a3.009 3.009 0 0 1 3-3h50a3.009 3.009 0 0 1 3 3v35h-12.18l-.87-.95-5.66-5.66a1 1 0 0 0 -.68-.29 4.915 4.915 0 0 0 -1.44-3.24c-1.69-1.7-7.8-2.89-9.01-3.11a1.013 1.013 0 0 0 -.89.28 1 1 0 0 0 -.27.89c.22 1.2 1.4 7.31 3.1 9.01a4.976 4.976 0 0 0 3.25 1.43.978.978 0 0 0 .29.69l.95.95h-32.59zm36.59 30.51 4.24 4.25-2.83 2.83-4.24-4.25zm-7.08 0c-.77-.77-1.68-3.89-2.25-6.5 2.61.58 5.72 1.49 6.5 2.26a3 3 0 0 1 -4.25 4.24zm11.49 20.49a1 1 0 0 1 1 1v1h-28v-1a1 1 0 0 1 1-1zm-18-2v-6h10v6zm-20-8a3.009 3.009 0 0 1 -3-3v-1h34.59l2.7 2.71s.54.49 1.41 1.29zm52.59 9.81a1.09 1.09 0 0 1 -1.54 1.54l-14.6-13.38 2.76-2.77zm-6.27-9.81c-1.32-1.44-2.6-2.84-3.67-4h10.35v1a3.009 3.009 0 0 1 -3 3z' />
          <path d='m33 45h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z' />
          <path d='m19 32h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2z' />
          <path d='m15 32h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2z' />
          <path d='m23 32h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2z' />
          <path d='m27 10h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2z' />
          <path d='m31 15a1 1 0 0 0 1-1v-1a1 1 0 0 0 -2 0v1a1 1 0 0 0 1 1z' />
          <path d='m31 11a1 1 0 0 0 1-1v-1a1 1 0 0 0 -2 0v1a1 1 0 0 0 1 1z' />
          <path d='m23 10h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2z' />
          <path d='m31 19a1 1 0 0 0 1-1v-1a1 1 0 0 0 -2 0v1a1 1 0 0 0 1 1z' />
          <path d='m31 27a1 1 0 0 0 1-1v-1a1 1 0 0 0 -2 0v1a1 1 0 0 0 1 1z' />
          <path d='m31 23a1 1 0 0 0 1-1v-1a1 1 0 0 0 -2 0v1a1 1 0 0 0 1 1z' />
          <path d='m27 32h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2z' />
          <path d='m7 26a1 1 0 0 0 1-1v-1a1 1 0 0 0 -2 0v1a1 1 0 0 0 1 1z' />
          <path d='m19 10h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2z' />
          <path d='m7 18a1 1 0 0 0 1-1v-1a1 1 0 0 0 -2 0v1a1 1 0 0 0 1 1z' />
          <path d='m7 30a1 1 0 0 0 1-1v-1a1 1 0 0 0 -2 0v1a1 1 0 0 0 1 1z' />
          <path d='m7 14a1 1 0 0 0 1-1v-1a1 1 0 0 0 -2 0v1a1 1 0 0 0 1 1z' />
          <path d='m7 34a1 1 0 0 0 1-1v-1a1 1 0 0 0 -2 0v1a1 1 0 0 0 1 1z' />
          <path d='m7 22a1 1 0 0 0 1-1v-1a1 1 0 0 0 -2 0v1a1 1 0 0 0 1 1z' />
          <path d='m7 10h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2z' />
          <path d='m11 10h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2z' />
          <path d='m15 10h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2z' />
          <path d='m10 34h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2z' />
          <path d='m11 30h16a1 1 0 0 0 1-1v-16a1 1 0 0 0 -1-1h-16a1 1 0 0 0 -1 1v16a1 1 0 0 0 1 1zm1-16h14v14h-14z' />
          <path d='m37 16h20a1 1 0 0 0 1-1v-6a1 1 0 0 0 -1-1h-20a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1zm1-6h18v4h-18z' />
          <path d='m37 26h20a1 1 0 0 0 1-1v-6a1 1 0 0 0 -1-1h-20a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1zm1-6h18v4h-18z' />
          <path d='m57 28h-18a1 1 0 0 0 0 2h17v4h-11a1 1 0 0 0 0 2h12a1 1 0 0 0 1-1v-6a1 1 0 0 0 -1-1z' />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
