import React from "react";

import { IconButton, Input, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const TableFilter = ({
  placeholder = "Search",
  handleClear,
  ...otherProps
}) => {
  return (
    <Input
      placeholder={placeholder}
      {...otherProps}
      style={{ width: 300 }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={handleClear}>
            <CloseIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default TableFilter;
