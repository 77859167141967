import { createSlice } from '@reduxjs/toolkit'

const _currentUser = localStorage.getItem('adminInfo')
  ? JSON.parse(localStorage.getItem('adminInfo'))
  : null

export const authSlice = createSlice({
  name: 'auth',

  initialState: {
    user: _currentUser,
  },

  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload
      localStorage.setItem('adminInfo', JSON.stringify(payload))
    },
    removeUser: (state) => {
      state.user = null
      localStorage.removeItem('adminInfo')
    },
  },
})

export const { setUser, removeUser } = authSlice.actions

export const selectLoggedInUser = (state) => state.auth.user
