import React from 'react'

export const DashboardScreen = () => {
  return (
    <div style={{ minHeight: '90vh', padding: '100px 0px' }}>
      <div className='container'>
        <h3>Dashboard comming soon !!!</h3>
      </div>
    </div>
  )
}
