import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'
import { Link as RouterLink } from 'react-router-dom'
import Sidebar from '../Nav/Sidebar'
import Backdrop from '../Elements/Backdrop'
import BurgerIcon from '../../assets/svg/BurgerIcon'
import logo from '../../assets/img/logo.png'
import logo2 from '../../assets/img/logo2.png'
import { useLocation } from 'react-router-dom'
import { Button, Menu, MenuItem, useMediaQuery } from '@mui/material'

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY)
  const [sidebarOpen, toggleSidebar] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (url) => {
    setAnchorEl(null)
    if (url) {
      window.open(url, '_blank')
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        setAnchorEl(null)
      }
    }

    window.addEventListener('click', handleOutsideClick)

    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [anchorEl])
  // const isResettingHash = React.useRef(false)

  const location = useLocation()

  const isSmallScreen = useMediaQuery('(max-width: 600px)')

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setY(window.scrollY)
    })
    return () => {
      window.removeEventListener('scroll', () => {
        setY(window.scrollY)
      })
      // clearTimeout(setHistory)
    }
  }, [location.hash])

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      window.history.replaceState(null, null, ' ')
    }

    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    if (location.pathname === '/' && !location.hash) {
      window.scrollTo({ top: 1, left: 0, behavior: 'smooth' })
      setTimeout(() => {
        handleScrollToTop()
      }, 200)
    }

    return () => {
      clearTimeout(handleScrollToTop)
    }
  }, [location])

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className='flexCenter animate'
        style={
          y > 100
            ? {
                height: '80px',
                backgroundColor: '#fff',
                boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
              }
            : { height: '90px' }
        }
      >
        <NavInner className='container flexSpaceCenter'>
          <RouterLink className='pointer flexNullCenter' to='/' smooth={true}>
            {y > 100 ||
            (location.pathname !== '') & (location.pathname !== '/') ? (
              <img
                className='logoImage'
                src={logo}
                style={{ width: '150px' }}
                alt='logoImage'
              ></img>
            ) : (
              <img
                className='logoImage'
                src={isSmallScreen ? logo : logo2}
                style={{ width: '150px' }}
                alt='logoImage'
              ></img>
            )}
          </RouterLink>
          <BurgerWrapper
            className='pointer'
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurgerWrapper>
          <UlWrapper className='flexNullCenter'>
            <li className='semiBold font15 pointer'>
              {location.pathname === '' || location.pathname === '/' ? (
                <Link
                  className={y > 100 ? 'darkColor' : 'whiteColor'}
                  style={{
                    padding: '10px 15px',
                  }}
                  to='home'
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Home
                </Link>
              ) : (
                <RouterLink style={{ padding: '10px 15px' }} to='/'>
                  Home
                </RouterLink>
              )}
            </li>
            <li className='semiBold font15 pointer'>
              {location.pathname === '' || location.pathname === '/' ? (
                <Link
                  // activeClass='active'
                  className={y > 100 ? 'darkColor' : 'whiteColor'}
                  style={{ padding: '10px 15px' }}
                  to='system'
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Systems
                </Link>
              ) : (
                <RouterLink
                  // activeClass='active'
                  style={{ padding: '10px 15px' }}
                  to='/#system'
                >
                  Systems
                </RouterLink>
              )}
            </li>
            {/*  <li className='semiBold font15 pointer'>
              {location.pathname === '' || location.pathname === '/' ? (
                <Link
                  // activeClass='active'
                  className={y > 100 ? 'darkColor' : 'whiteColor'}
                  style={{ padding: '10px 15px' }}
                  to='services'
                  spy={true}
                  smooth={true}
                  offset={-60}
                >
                  Services
                </Link>
              ) : (
                <RouterLink
                  // activeClass='active'
                  style={{ padding: '10px 15px' }}
                  to='/#services'
                >
                  Services
                </RouterLink>
              )}
            </li> */}
            <li className='semiBold font15 pointer'>
              {location.pathname === '' || location.pathname === '/' ? (
                <Link
                  className={y > 100 ? 'darkColor' : 'whiteColor'}
                  style={{ padding: '10px 15px' }}
                  to='explore'
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Explore
                </Link>
              ) : (
                <RouterLink style={{ padding: '10px 15px' }} to='/#explore'>
                  Explore
                </RouterLink>
              )}
            </li>
            <li className='semiBold font15 pointer'>
              {location.pathname === '' || location.pathname === '/' ? (
                <Link
                  // activeClass='active'
                  className={y > 100 ? 'darkColor' : 'whiteColor'}
                  style={{ padding: '10px 15px' }}
                  to='resources'
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Resources
                </Link>
              ) : (
                <RouterLink
                  // activeClass='active'
                  style={{ padding: '10px 15px' }}
                  to='/#resources'
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Resources
                </RouterLink>
              )}
            </li>
            <li className='semiBold font15 pointer'>
              {location.pathname === '' || location.pathname === '/' ? (
                <Link
                  // activeClass='active'
                  className={y > 100 ? 'darkColor' : 'whiteColor'}
                  style={{ padding: '10px 15px' }}
                  to='news'
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  News
                </Link>
              ) : (
                <RouterLink
                  // activeClass='active'
                  style={{ padding: '10px 15px' }}
                  to='/#news'
                >
                  News
                </RouterLink>
              )}
            </li>
            {/* Pricing */}
            <li className='semiBold font15 pointer'>
              {location.pathname === '' || location.pathname === '/' ? (
                <RouterLink
                  style={{ padding: '10px 15px' }}
                  to='/pricing'
                  className={y > 100 ? 'darkColor' : 'whiteColor'}
                >
                  Pricing
                </RouterLink>
              ) : (
                <RouterLink style={{ padding: '10px 15px' }} to='/pricing'>
                  Pricing
                </RouterLink>
              )}
            </li>
            {/* End Pricing */}
            <li className='semiBold font15 pointer'>
              {location.pathname === '' || location.pathname === '/' ? (
                <Link
                  // activeClass='active'
                  className={y > 100 ? 'darkColor' : 'whiteColor'}
                  style={{ padding: '10px 15px' }}
                  to='contact'
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Contact Us
                </Link>
              ) : (
                <RouterLink
                  // activeClass='active'
                  style={{ padding: '10px 15px' }}
                  to='/#contact'
                >
                  Contact Us
                </RouterLink>
              )}
            </li>
          </UlWrapper>
          <UlWrapperRight className='flexNullCenter'>
            <li className='semiBold font15 pointer flexCenter'>
              {/* {location.pathname === '' || location.pathname === '/' ? (
                <Link
                  to='contact'
                  className='radius6 NabBtn'
                  style={{ padding: '10px 15px', color: '#fff' }}
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Get Started
                </Link>
              ) : (
                <RouterLink
                  // activeClass='active'
                  className='radius6 NabBtn'
                  style={{ padding: '10px 15px', color: '#fff' }}
                  to='/#contact'
                >
                  Get Started
                </RouterLink>
              )} */}
              <div>
                <Button
                  variant='contained'
                  onClick={handleClick}
                  aria-controls='dropdown-menu'
                  aria-haspopup='true'
                  style={{ color: 'white', backgroundColor: '#3f51bf' }}
                >
                  Login
                </Button>
                <Menu
                  id='dropdown-menu'
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => handleClose(null)}
                >
                  <MenuItem
                    onClick={() =>
                      handleClose('https://client.assistxgateway.com/')
                    }
                  >
                    Client
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleClose('https://provider.medxgateway.com/login')
                    }
                  >
                    Provider
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleClose('https://alerts.assistxgateway.com/login')
                    }
                  >
                    Alert
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleClose('https://api.assistxgateway.com/login')
                    }
                  >
                    API
                  </MenuItem>
                </Menu>
              </div>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurgerWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`
