import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width={43.42}
      height={43.42}
      viewBox='0 0 512 512.00029'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m423.5625 270.199219c-6.277344-6.277344-6.277344-16.453125 0-22.730469l34.089844-34.089844c6.277344-6.277344 16.453125-6.277344 22.726562 0 6.277344 6.277344 6.277344 16.453125 0 22.726563l-34.089844 34.09375c-6.277343 6.277343-16.453124 6.277343-22.726562 0zm0 0'
        fill='#a89e9f'
      />
      <path
        d='m440.605469 332.699219c-6.273438-6.273438-6.273438-16.449219 0-22.726563l34.09375-34.089844c6.273437-6.277343 16.453125-6.277343 22.726562 0 6.277344 6.273438 6.277344 16.449219 0 22.726563l-34.089843 34.089844c-6.277344 6.277343-16.453126 6.277343-22.730469 0zm0 0'
        fill='#a89e9f'
      />
      <path
        d='m241.738281 88.375c6.277344 6.277344 16.453125 6.277344 22.730469 0l34.089844-34.089844c6.277344-6.277344 6.277344-16.453125 0-22.726562-6.277344-6.277344-16.453125-6.277344-22.726563 0l-34.09375 34.089844c-6.277343 6.277343-6.277343 16.453124 0 22.726562zm0 0'
        fill='#a89e9f'
      />
      <path
        d='m179.238281 71.332031c6.273438 6.273438 16.449219 6.273438 22.726563 0l34.089844-34.09375c6.277343-6.273437 6.277343-16.453125 0-22.726562-6.273438-6.277344-16.449219-6.277344-22.726563 0l-34.089844 34.089843c-6.277343 6.277344-6.277343 16.453126 0 22.730469zm0 0'
        fill='#a89e9f'
      />
      <path
        d='m510.808594 392.90625-79.546875-267.050781c-.78125-2.621094-2.84375-4.667969-5.472657-5.425781-2.625-.757813-5.460937-.128907-7.519531 1.671874l-90.910156 79.546876c-3.136719 2.746093-3.65625 7.433593-1.195313 10.792968l151.28125 206.683594c2.867188 3.914062 8.734376 4.367188 12.167969.933594l19.175781-19.175782c2.085938-2.085937 2.863282-5.148437 2.019532-7.976562zm0 0'
        fill='#cae0fa'
      />
      <path
        d='m391.507812 86.148438c-.761718-2.628907-2.804687-4.691407-5.425781-5.472657l-267.050781-79.546875c-2.828125-.84375-5.890625-.066406-7.976562 2.019532l-19.175782 19.175781c-3.433594 3.433593-2.980468 9.300781.933594 12.164062l206.683594 151.28125c1.421875 1.046875 3.089844 1.550781 4.742187 1.550781 2.242188 0 4.46875-.9375 6.050781-2.742187l79.546876-90.910156c1.800781-2.058594 2.429687-4.894531 1.671874-7.519531zm0 0'
        fill='#cae0fa'
      />
      <path
        d='m232.195312 398-28.410156-73.867188c-.976562-2.539062-3.171875-4.410156-5.832031-4.976562-2.660156-.5625-5.425781.257812-7.351563 2.179688l-45.453124 45.457031c-3.140626 3.136719-3.140626 8.222656 0 11.363281l51.136718 51.136719c3.09375 3.09375 8.265625 3.09375 11.363282 0l22.726562-22.726563c2.246094-2.246094 2.957031-5.605468 1.820312-8.566406zm0 0'
        fill='#cae0fa'
      />
      <path
        d='m190.601562 321.335938c1.921876-1.921876 2.742188-4.6875 2.179688-7.351563-.566406-2.660156-2.4375-4.855469-4.976562-5.828125l-73.867188-28.410156c-2.960938-1.140625-6.316406-.429688-8.5625 1.816406l-22.730469 22.726562c-3.136719 3.140626-3.136719 8.226563 0 11.363282l51.140625 51.140625c1.566406 1.566406 3.625 2.351562 5.679688 2.351562 2.058594 0 4.113281-.785156 5.683594-2.351562zm0 0'
        fill='#cae0fa'
      />
      <path
        d='m503.109375 8.828125c-8.386719-8.386719-24.8125-15.0625-55.449219.761719-18.003906 9.304687-38.671875 25.175781-58.191406 44.695312-101.375 101.375-260.296875 294.097656-261.890625 296.035156-2.628906 3.191407-2.402344 7.863282.523437 10.789063l22.726563 22.726563c1.5625 1.5625 3.617187 2.355468 5.683594 2.355468 1.804687 0 3.617187-.605468 5.105469-1.835937 1.933593-1.589844 194.660156-160.511719 296.035156-261.886719 19.519531-19.519531 35.390625-40.1875 44.695312-58.195312 15.824219-30.628907 9.148438-47.058594.761719-55.445313zm0 0'
        fill='#9bc9ff'
      />
      <path
        d='m503.109375 8.828125c-2.410156-2.410156-5.488281-4.671875-9.371094-6.308594 4.050781 9.632813 4.207031 24.246094-7.070312 46.078125-9.300781 18.003906-25.175781 38.671875-44.695313 58.191406-101.375 101.375-294.097656 260.296876-296.035156 261.890626-1.488281 1.226562-3.300781 1.832031-5.105469 1.832031-2.0625 0-4.121093-.792969-5.683593-2.351563l15.679687 15.675782c1.5625 1.5625 3.617187 2.355468 5.683594 2.355468 1.804687 0 3.617187-.605468 5.105469-1.832031 1.933593-1.59375 194.660156-160.515625 296.035156-261.890625 19.519531-19.519531 35.390625-40.183594 44.695312-58.191406 15.824219-30.632813 9.148438-47.0625.761719-55.449219zm0 0'
        fill='#50b2d2'
      />
      <g fill='#1e81ce'>
        <path d='m393.675781 512c-7.027343 0-10.628906-8.769531-5.683593-13.71875l64.285156-64.28125c3.136718-3.140625 8.226562-3.140625 11.363281 0 3.136719 3.136719 3.136719 8.222656 0 11.363281l-64.285156 64.28125c-1.566407 1.570313-3.625 2.355469-5.679688 2.355469zm0 0' />
        <path d='m385.640625 455.753906c-7.027344 0-10.628906-8.773437-5.683594-13.71875l40.179688-40.179687c3.136719-3.136719 8.222656-3.136719 11.363281 0 3.136719 3.140625 3.136719 8.226562 0 11.363281l-40.179688 40.179688c-1.570312 1.570312-3.625 2.355468-5.679687 2.355468zm0 0' />
        <path d='m373.585938 403.519531c-7.027344 0-10.628907-8.769531-5.679688-13.714843l28.121094-28.125c3.140625-3.136719 8.226562-3.136719 11.363281 0 3.140625 3.136718 3.140625 8.226562 0 11.363281l-28.121094 28.125c-1.570312 1.570312-3.625 2.351562-5.683593 2.351562zm0 0' />
        <path d='m7.972656 130.316406c-7.027344 0-10.628906-8.773437-5.683594-13.71875l64.285157-64.285156c3.136719-3.136719 8.226562-3.136719 11.363281 0 3.140625 3.140625 3.140625 8.226562 0 11.363281l-64.285156 64.285157c-1.566406 1.570312-3.625 2.355468-5.679688 2.355468zm0 0' />
        <path d='m64.222656 138.351562c-7.027344 0-10.628906-8.769531-5.683594-13.71875l40.175782-40.175781c3.140625-3.140625 8.226562-3.140625 11.363281 0 3.140625 3.136719 3.140625 8.222657 0 11.363281l-40.175781 40.175782c-1.570313 1.570312-3.625 2.355468-5.679688 2.355468zm0 0' />
        <path d='m116.453125 150.402344c-7.027344 0-10.628906-8.769532-5.683594-13.714844l28.125-28.125c3.136719-3.136719 8.222657-3.136719 11.363281 0 3.136719 3.136719 3.136719 8.226562 0 11.363281l-28.125 28.125c-1.570312 1.570313-3.625 2.351563-5.679687 2.351563zm0 0' />
      </g>
    </svg>
  )
}

export default SvgComponent
