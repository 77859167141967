import React from 'react'

import { CircularProgress, Paper } from '@material-ui/core'
import Alert from '@mui/material/Alert'

import PageBreadcrumbs from '../components/shared/PageBreadcrumbs'

const WithHeader = ({
  breadcrumbs = [],
  children,
  disablePaper = false,
  error = null,
  hasActions = false,
  isBusy = false,
  headerActions: HeaderActions,
}) => {
  return (
    <>
      <Paper
        elevation={0}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '1.5rem',
          padding: '0.75rem',
          position: 'sticky',
          top: 64,
          zIndex: 10,
          border: '1px solid lightGray',
        }}
      >
        <PageBreadcrumbs breadcrumbs={breadcrumbs} />
        {isBusy && <CircularProgress size={24} thickness={5} />}
        {!isBusy && hasActions && <HeaderActions />}
      </Paper>

      {error && (
        <Alert severity='error' style={{ marginBottom: '1.5rem' }}>
          <div>{JSON.stringify(error)}</div>
        </Alert>
      )}

      <div style={{ position: 'relative' }}>
        {!disablePaper ? (
          <Paper elevation={0} style={{ border: '1px solid lightGray' }}>
            {children}
          </Paper>
        ) : (
          <>{children}</>
        )}

        {/* {isBusy && (
          <div
            style={{
              position: "absolute",
              inset: 0,
              backgroundColor: "gray",
              opacity: 0.2,
              borderRadius: ".25rem",
              zIndex: 1,
              cursor: "wait",
            }}
          ></div>
        )} */}
      </div>
    </>
  )
}

export default WithHeader
