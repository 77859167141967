import React from 'react'
import styled from 'styled-components'
import NewsBanner from '../../assets/img/NewsBanner.png'
// import AuthorImage from '../../assets/img/AuthorImage.jpeg'

export const NewsDetailScreen = () => {
  return (
    <Wrapper id='services'>
      <div style={{ padding: '60px 0' }}>
        <div className='container'>
          <div className='row' style={{ paddingBottom: '30px' }}>
            <div className='col-xs-12 col-sm-12'>
              <img
                src={NewsBanner}
                alt='hero background'
                style={{
                  width: '100%',
                  borderRadius: '5px',
                }}
              />
            </div>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <HeaderInfo>
                <h1 className='font40 extraBold'>
                  AMI and The Can Brand to form an AI-based technology joint
                  venture, AssistXGateway
                </h1>
                <NewsInfo>
                  <p> READ TIME: 5 MINUTES</p>
                  <p> AssistXGateway, January 31, 2022</p>
                </NewsInfo>
              </HeaderInfo>
              <NewsContent>
                <p className='font13'>
                  AMI Expeditionary Healthcare (AMI), a leading healthcare
                  company, has entered a comprehensive joint venture with The
                  Can Brand (TCB), an emerging Australian technology company to
                  develop AI based healthcare technologies. MedXGateway Pty Ltd
                  a joint venture entity has been formed.
                </p>

                <p className='font13' style={{ margin: '20px 0px' }}>
                  The new joint venture will bring together AMI’s healthcare
                  expertise and TCB’s data science & AI capabilities to build
                  modern healthcare systems and technologies that provide
                  affordable healthcare to many around the world. The joint
                  venture will develop products & services that will be rolled
                  out to markets in Asia & Pacific, South Asia, Middle East, USA
                  & Africa.
                </p>

                <p className='font13' style={{ margin: '20px 0px' }}>
                  “This joint venture will bring the best of healthcare delivery
                  and technology development. The products will be developed to
                  suit both online and onsite delivery of healthcare. AMI’s
                  significant experience and footprint in Australia, USA &
                  Africa together with TCB’s expanding network in Nepal, India
                  and Philippines will give these products & services immediate
                  access to multiple emerging markets.” – said Sudeep Acharya,
                  CEO of The Can Band.
                </p>
                <p className='font13' style={{ margin: '20px 0px' }}>
                  “AMI can provide people, facilities, equipment, consumables,
                  pharmacy products, procedures, training, or any combination of
                  these services depending upon the customer’s needs,
                  requirements and desired healthcare outcomes. With this joint
                  venture we look forward to using AI and other advanced
                  technology to drive efficiency that will improve both quality
                  of care and reduce the cost burden to our customers.” – said
                  Jack Walker, Director of AMI.
                </p>
                <p className='font13' style={{ margin: '20px 0px' }}>
                  About AMI – AMI Expeditionary Healthcare (AMI) provides
                  medical services to international aid organizations,
                  humanitarian concerns, the private sector and government
                  agencies in a wide range of remote and challenging
                  environments. AMI has delivered countless healthcare solutions
                  to over 140 clinical and hospital settings on four continents.
                  For more info visit{' '}
                  <span>
                    <a
                      href='https://ami.health/'
                      rel='noreferrer'
                      target='_blank'
                    >
                      https://ami.health/
                    </a>
                  </span>
                </p>
                <p className='font13' style={{ margin: '20px 0px' }}>
                  About The Can Brand – The Can Brand is an Australian owned and
                  managed technology company. We are the Can People with Can
                  Vision. We have developed effective and efficient managements
                  systems, processes, and knowhow to solve big problems in
                  import export, trade, ecommerce, logistics and distribution.
                  For more info visit{' '}
                  <span>
                    <a
                      href='https://www.thecanbrand.com'
                      rel='noreferrer'
                      target='_blank'
                    >
                      https://www.thecanbrand.com
                    </a>
                  </span>
                </p>
              </NewsContent>
            </div>
          </div>
          {/* <AuthorSection>
            <div className='row'>
              <div className='col-xs-12 col-sm-3'>
                <img
                  src={AuthorImage}
                  alt='hero background'
                  style={{
                    width: '100%',
                    borderRadius: '120px',
                  }}
                />
              </div>
              <div className='col-xs-12 col-sm-9'>
                <AuthorDetail>
                  <h5> JULIA FARINA</h5>
                  <p>
                    Julia is a Senior Product Marketing Manager at AssistXGateway.
                  </p>
                  <a
                    href='https://www.facebook.com/firstsourceworld'
                    rel='noreferrer'
                    target='_blank'
                    className='SocialMediaIcons mt-5'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='22'
                      height='22'
                      fill='#000'
                      className='bi bi-linkedin'
                      viewBox='0 0 16 16'
                    >
                      <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                    </svg>
                  </a>
                </AuthorDetail>
              </div>
            </div>
          </AuthorSection> */}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 80px;
  min-height: 90vh;
`
const HeaderInfo = styled.div`
  padding: 40px 0px;
  > h1 {
    color: #000;
    font-size: 42px;
    line-height: 52px;
  }
  @media (max-width: 860px) {
    > h1 {
      font-size: 32px;
      line-height: 42px;
    }
  }
`
const NewsInfo = styled.section`
  padding-bottom: 20px;
  font-size: 18px;
  color: #666666;
`
const NewsContent = styled.div`
  > p {
    font-size: 18px;
    line-height: 29px;
  }
`
// const AuthorSection = styled.div`
//   background-color: #f2f4f8;
//   min-height: 50px;
//   border-radius: 5px;
//   padding: 40px;
//   margin: 40px 0px;
// `
// const AuthorDetail = styled.div`
//   > h5 {
//     font-size: 20px;
//     padding: 40px 0px 20px 0px;
//     color: #666666;
//   }
//   > p {
//     color: #000;
//     padding-bottom: 60px;
//   }
// `
