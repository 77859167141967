import React from 'react'
import styled from 'styled-components'
export const TermsConditionsScreen = () => {
  return (
    <Wrapper id='privacy-policy'>
      <div className='container'>
        <NewsContent>
        <h3>Terms and Conditions of Service:</h3>
          <h1>Introduction:</h1>
          <p>
            These Terms of Service, together with the Subscription Agreement and
            Purchase Order Form (the “Subscription Agreement” and collectively
            the “Agreement”), constitute a legal agreement between GatewayX
            Technologies Pty Ltd, Australian Company Number 656 461 577, with
            address Unit 27, 2 O’Connell St, Parramatta, NSW, Australia, 2150
            (“GatewayX”, “we”, “us” or “our”), and a corporation or other legal
            entity, (the “Client”), or you as an employee, consultant, agent or
            other permitted individual using our services, (the “User”), (both
            referred to as “you” if applicable to both), governing the access to
            the GatewayX applications, being the AssistXAlerts App and the
            AssistX Customer App/ AssistXTRM, (together, the “App”) and GatewayX
            platforms, being the AssistXProvider App/ AssistXCMS (the
            “Platform”), (jointly referred to as the “GatewayX Services”) or any
            third party app or services provided to you in which the GatewayX
            Services have been incorporated or form an integral part of. The
            content and functionality of the GatewayX Services are described at{" "}
            <a href="https://gatewayx.tech/" target="_blank">
              https://gatewayx.tech/
            </a>{" "}
            and{" "}
            <a href="https://assistxgateway.com/" target="_blank">
              https://assistxgateway.com/
            </a>
            .{" "}
          </p>
          <p>
            GatewayX and the Client or User are each referred to as a{" "}
            <span style={{ fontWeight: "600" }}>“Party”</span>, and jointly
            referred to as the{" "}
            <span style={{ fontWeight: "600" }}>“Parties”</span>.
          </p>
          <p style={{ fontWeight: "600" }}>
            If you use the GatewayX Services in your capacity as an employee,
            consultant, agent or other representative of a Client, please note
            that, in case of conflict between these Terms of Service and any
            separate written agreement between your employer or contracted party
            and GatewayX, such agreement shall prevail, to the extent necessary
            to resolve such conflict.
          </p>
          <p>
            If you are an appointed representative of the Client you will, by
            entering into a Subscription Agreement or by using the GatewayX
            Services, also legally bind the Client you represent to the terms
            and conditions stated herein. This will apply irrespective of
            whether you have entered into an agreement directly with GatewayX or
            if you have entered into an agreement with a partner to GatewayX (“
            <span style={{ fontWeight: "600" }}>Partner</span>”).
          </p>
          <p>
            The terms and conditions of these Terms of Service shall apply
            between you and GatewayX. In case of conflict between what is stated
            in a Subscription Agreement and this Agreement, the Subscription
            Agreement shall prevail.
          </p>
          <p>
            These Terms of Service contain the following clauses:
            <li style={{ listStyle: "none" }}>1. Client Subscriptions</li>
            <li style={{ listStyle: "none" }}>2. Terms of Use</li>
            <li style={{ listStyle: "none" }}>3. Privacy Policy</li>
          </p>
          <h2>1. Client Subscriptions:</h2>
          <h3>1.1. The GatewayX Services:</h3>
          <p>
            1.1.1. The GatewayX Services’ availability for the Client is subject
            to the Subscription Agreement
          </p>
          <p>1.1.2. The Subscription allows the Client to:</p>
          <p>
            1.1.2.1. offer its Users to activate and gain access to the GatewayX
            Services; and
          </p>
          <p>
            1.1.2.2. select individuals, (the “Admin Users”), who are Users with
            additional administrative privileges, such as access to Users’
            personal data and additional functions and content restricted only
            to Admin Users
          </p>
          <p>1.1.3. A requirement for access is that:</p>
          <p>1.1.3.1. the Client and GatewayX have a valid Agreement; and </p>
          <p>1.1.3.2. the Client and Users accept these Terms of Service, </p>
          <p>1.1.4. &emsp; The GatewayX Services are
          provided as subscriptions and may be accessed by the maximum number of
          Users and Admin Users purchased according to the Subscription
          Agreement between the Client and GatewayX or its duly appointed
          Partner. Should the number of Client’s registered Users or Admin Users
          at any time exceed the agreed number of subscriptions set out in the
          Subscription Agreement, GatewayX reserves the right to charge the
          Client for such additional subscriptions in accordance with clause
          1.3.3 below. </p>
          <h3>1.2. Right to vary terms of service </h3>
          <p>1.2.1. &emsp; GatewayX
          reserves the right to vary these Terms of Service from time to time
          and will advise you by email when changes are made. You should refer
          to the AssistXGateway website
          (<a href="https://assistxgateway.com/terms-condition" target="_blank" rel="noreferrer"
          >https://assistxgateway.com/terms-condition</a>) for the latest version of
          the Terms of Service. You shall be deemed to have accepted any
          variations or amendments unless you inform us in writing at
          sales@gatewayx.tech of your refusal to accept the variation or
          amendment within 14 days of its publication. Changes made for legal
          reasons will be effective immediately. If there is a conflict between
          these Terms of Service and any later version of the Terms of Service,
          the later version of the Terms of Service will prevail. If you do not
          agree to a later version of these Terms of Service, you should
          discontinue your use of the GatewayX Services. </p>
          
          <h3>1.3. Fees and Payments:</h3>
          <p>1.3.1.  &emsp; All fees and charges are charged in accordance with the
          Subscription Agreement. The fees stated in the Subscription Agreement
          are based on conditions and prerequisites stated by the Client prior
          to entering into the Subscription Agreement. If the actual conditions
          and prerequisites deviate from what the Client has stated and the
          deviation results in additional work for GatewayX, GatewayX has a
          right to adjust the agreed fee to cover its actual costs for any
          additional work required to implement and provide the Client with the
          agreed GatewayX Services. If no actual conditions or prerequisites
          have been agreed, GatewayX has the right to charge the Client for
          additional work pertaining to deviations from what GatewayX reasonably
          could have expected regarding the Client. </p>
          <p>1.3.2. &emsp; Fees are based on the
          agreed GatewayX Services, and the Client shall pay all fees specified
          in this Agreement. The fees payable by the Client may consist of a)
          fixed fees, such as subscriptions, which are due at the end of the
          billing period as stated in the Subscription Agreement and if not
          stated in the Subscription Agreement, as stated in this Agreement, and
          b) usage-based fees, such as SMS costs, which are due in arrears and
          c) any other fees stated in the Subscription Agreement.</p>
          <p>1.3.3. &emsp; Except
          as otherwise specified herein, (i) fixed fees are based on services
          purchased and not actual usage, (ii) fees paid are non-refundable.
          GatewayX Services purchased may be cancelled at any time and the
          Client shall only be charged for the number of days used. When
          calculating the number of days used, GatewayX will calculate the
          number of days since the last full payment (being a payment resulting
          in no money being owed to GatewayX by the Client), and attribute
          1/30th of the price of the monthly subscription as specified in the
          Subscription Agreement for each day since the last full payment. Fees
          are based on periods that begin on the start date; therefore, fees for
          additional services added during a relevant term will be charged for
          the remaining part of such term and prorated on the following invoice.
          Client shall, unless expressly stated as included in the fixed fee,
          pay all usage-based fees. </p>
          <p>1.3.4. &emsp;  Additional User subscriptions may be
          added during the applicable subscription term at the same pricing as
          that for the pre-existing subscriptions thereunder and prorated in
          accordance with the calculation contained in clause 1.3.3. The added
          User subscriptions shall terminate on the same date as the
          pre-existing subscriptions. User subscriptions are for designated
          Users only and cannot be shared or used by more than one (1) User but
          may be reassigned to new Users replacing former Users who no longer
          require ongoing use of the GatewayX Services. </p>
          <p> 1.3.5. &emsp; Any User
          subscriptions registered by the Client exceeding the maximum number of
          subscriptions agreed upon in the Subscription Agreement shall be
          regarded as additional User subscriptions </p>
          <p> 1.3.6.  &emsp; Unless otherwise
          stated in this Agreement, invoiced charges are due net fourteen (14)
          days from the invoice date. The Client is responsible for providing
          complete and accurate billing and contact information to GatewayX and
          notifying GatewayX of any changes to such information. All prices are
          excluding VAT. </p>
          <p>1.3.7. &emsp; If any amount owing by the Client under this or
          any other agreement for the GatewayX Services is fourteen (14) or more
          days overdue, GatewayX may, without limiting its other rights and
          remedies, suspend the GatewayX Services to the Client until such
          amounts are paid in full. GatewayX will give the Client at least seven
          (7) days’ prior notice that the Client’s account is overdue before
          suspending the GatewayX Services for the Client. </p>
          <p>1.3.8. &emsp; Unless
          otherwise stated, GatewayX’s prices and fees do not include VAT.
          GatewayX has, once per year, the right to adjust the fees based on the
          latest available European Union Inflation Index (HICP) at the time of
          invoice compared to the date of the latest adjustment of the fees, or
          the first Subscription Start Date if no fees have been adjusted, plus
          an additional two (2) % per year. </p>
          <h3>1.4. Term and Termination </h3>
          <p>1.4.1. &emsp;The
          subscription, and this Agreement, commences on the Subscription Start
          Date (as defined in the Subscription Agreement). The initial term is
          the period stated above as Subscription Start Date and Subscription
          End Date as defined in the Subscription Agreement. The subscription
          term shall be for a period of one month. At the end of each 1-month
          period, the subscription term will automatically renew for an
          additional 1 month. </p>
          <p>1.4.2. &emsp; The Client may end the subscription term at
          any time and will only be charged for the days of usage as calculated
          in clause 1.3.3 </p>
          <p>1.4.3. &emsp; GatewayX may terminate this agreement by giving
          at least (90) days written notice to the Client. </p>
          <p>1.4.4. &emsp; GatewayX may
          terminate this Agreement for cause: </p>
          <p>1.4.4.1. upon thirty (30) days
          written notice to the other Party of a material breach if such breach
          remains uncured at the expiration of such period; or </p>
          <p>1.4.4.2. if the
          other Party becomes the subject of a petition in bankruptcy or any
          other proceeding relating to insolvency, receivership, liquidation, or
          assignment for the benefit of creditors. </p>
          <h3>1.5. Other:</h3>
          <p>1.5.1. &emsp; The Client
          hereby grants to GatewayX the right to use the Client’s company name
          and logo in marketing, sales, financial, public relations materials,
          and other communications solely to identify the Client as a customer
          to GatewayX or to Partner, and that Client uses the GatewayX
          Services. GatewayX hereby grants the Client the right to use
          GatewayX’s and AssistXGateway’s name and logo in marketing, sales,
          financial, public relations materials, and other communications solely
          to identify GatewayX as a supplier to the Client. </p>
          <p>1.5.2. &emsp; The Parties
          shall have the right but no obligation to issue press releases
          regarding the Agreement to the extent required under regulations of
          any applicable stock exchange.</p>
          <p>1.5.3. &emsp; GatewayX has the right to engage
          subcontractors for its performance of the GatewayX Services and is
          responsible for the acts and omissions of such subcontractors.</p>
          <p>1.5.4.
          &emsp; All information which is not publicly available, whether oral or
          written or in visual, electronic or tangible form, regarding or
          otherwise relating to a Party or to any of its business matters, which
          has been disclosed or may be disclosed to the other Party (the
          “<b>Receiving Party</b>“) or which the Receiving Party has or may otherwise
          become aware of in connection with this Agreement, shall at all times
          be kept strictly confidential by the Receiving Party and not be used
          by it for any other purpose than the performance or enforcement of
          this Agreement, nor be disclosed by it to any third party without the
          prior written consent of the other Party (such consent not to be
          unreasonably withheld). </p>
          <p>1.5.5. &emsp; The restriction in clause 1.5.4 above
          shall not apply to the Parties’ right to disclose information in
          accordance with clause 1.5.1 and 1.5.2 above. </p>
          
          <h2>2. Terms of Use </h2>
          <h3>2.1. General </h3>
          <p>2.1.1. &emsp; If a Client has acquired access to the GatewayX
          Services and intends to enable its employees, agents, directors, or
          similar Users to use the GatewayX Services, GatewayX will provide the
          GatewayX Services to the Client who in turn will provide access to the
          GatewayX Services to its Users. The Client shall be fully responsible
          under this Agreement for the acts and omissions of Users affiliated
          with the Client. </p>
          <p>2.1.2.  &emsp; Please note that, depending on your location,
          some of the terms in this Agreement may not apply to you. GatewayX
          will always provide the GatewayX Services in accordance with the legal
          requirements applicable to you. If there is a conflict between any
          terms in this Agreement and any legal requirements applicable to you
          in your jurisdiction, such legal requirements will prevail. </p>
          <p>2.1.3. &emsp;
          When a Client has acquired access to the GatewayX Services through
          Partner, and that Partner has granted the Client rights exceeding the
          rights stated in this Agreement, the Client and User acknowledge and
          accept that such rights cannot be claimed or enforced against GatewayX
          and that GatewayX has no liability for any damage or loss arising from
          those rights. </p>
         <h3> 2.2. Service </h3>
         <p>2.2.1. &emsp; If you use the GatewayX Services as
          a service provided by your employer, GatewayX provides the GatewayX
          Services to your employer or your agency who in turn provides access
          to the GatewayX Services to you. </p>
          <p>2.2.2. &emsp; GatewayX is constantly
          changing and improving the GatewayX Services. GatewayX may add or
          remove functionalities or features and may suspend or stop the
          GatewayX Services altogether. </p>
          <h3>2.3. Intellectual Property Rights </h3>
          <p>2.3.1. &emsp;
          GatewayX or its licensors own all Intellectual Property Rights (as
          defined in clause 2.3.2 below) and all other rights (whether or not
          capable of registration) relating to GatewayX’s business including the
          GatewayX Services, the Platform, the App, and its content (save for
          content provided by Partner or Client or third party content
          provider), trademarks, domain names and any other materials,
          documentation or services made available to you or otherwise offered
          by GatewayX. The information is protected by law, including Australian
          and foreign intellectual property law and market law. This means that
          trademarks, company names, product names, images and graphics,
          designs, layouts, information on products, services, and other content
          published on the GatewayX Website <a href="https://gatewayx.tech/" target="_blank" rel="noreferrer">
            (https://gatewayx.tech/)</a>, the  AssistXGateway Website 
            (<a href="https://assistxgateway.com/" target="_blank" rel="noreferrer">
            https://assistxgateway.com/</a>), in the App or in
          the Platform may not be copied or used without GatewayX’s prior
          written consent. </p>
          <p>2.3.2.  &emsp; “Intellectual Property Rights” means patents
          (including utility models), design patents, design rights (whether or
          not capable of registration), copyrights, copyright related rights,
          moral rights, rights in databases, trademarks, trade secrets,
          know-how, trade names, rights under marketing law and passing off,
          topography rights and semiconductor chip rights, and all other
          intellectual property rights; in all cases whether or not registered
          or registerable, and applications for any of the foregoing
          respectively, and all rights to apply for the same, and all rights and
          forms of protection of a similar nature or having a similar effect to
          any of these anywhere in the world. </p>
          <p>2.3.3. &emsp; Subject to your proper
          fulfillment of this Agreement, GatewayX grants you a personal,
          non-assignable and non-exclusive license to use, and, if you are a
          Client, enable your Users use the GatewayX Services. This license is
          for the sole purpose of enabling you to use the GatewayX Services as
          provided by GatewayX or a Partner, in the manner permitted by this
          Agreement. You may not copy, modify, distribute, sell, or lease any
          part of the GatewayX Services, the Platform, the App or included
          software, nor may you reverse engineer or attempt to extract the
          source code of the GatewayX Services, the Platform, the App or
          included software, unless laws prohibit those restrictions, or you
          have been granted GatewayX’s written permission. You shall not,
          directly, or indirectly, use, register or try to register any
          trademark or any other words or phrases, or name of a company or
          organization, which are or may be considered as similar to GatewayX’s
          trademarks, or attempt to do anything of the aforesaid. </p>
          <p>2.3.4. &emsp; You
          have no obligation to provide GatewayX with input, ideas, suggestions
          or improvements to the GatewayX Services (“Feedback”). However, if you
          submit any Feedback to GatewayX (or its duly appointed Partner), then
          you grant GatewayX a non-exclusive, worldwide, royalty-free license
          that is sub-licensable and transferable, to make, use, sell, have
          made, offer to sell, import, reproduce, publicly display, distribute,
          modify, or publicly perform the Feedback in any manner without any
          obligation, royalty or restriction based on Intellectual Property
          Rights or otherwise. </p>
          <h3>2.4. Use and Equipment: </h3>
          <p>2.4.1. &emsp; You warrant and
          undertake that you shall at all times conduct your business and use
          the GatewayX Services in full conformity with all applicable laws and
          regulations as well as in compliance with good and sound business
          practice. This includes but is not limited to that you may under no
          circumstances, and the Client shall procure that no Users of the
          Client, use the GatewayX Services to publish, distribute, copy or
          otherwise transmit any data or information that: </p>
          <p>2.4.1.1. &emsp; it does not
          have the right to distribute by law or agreement;</p>
          <p> 2.4.1.2. &emsp; is
          infringing, contributes to infringement, or encourages others to
          infringe intellectual property rights or trade secrets; </p>
          <p>2.4.1.3. &emsp;
          contains any viruses, trojans, Internet worms, malware, or that may
          otherwise damage or adversely affect the GatewayX Services; or</p>
          <p>2.4.1.4. &emsp; otherwise causes damage or other inconvenience to GatewayX or
          third parties. </p>
          <p>2.4.2. &emsp; You are not allowed to, in an automated and/or
          commercial way, disseminate, copy, or publish the information received
          through the GatewayX Services.</p>
          <p>2.4.3. &emsp; Notwithstanding clause 2.4.2,
          publishing information received through the GatewayX Services is
          allowed when the GatewayX Services are used as intended.</p>
          <p> 2.4.4. &emsp; You
          must ensure that no unauthorized person or entity (i.e. any other
          person or entity than you) is able to use your login details. You may
          not disclose your usernames, passwords or other access credentials to
          any unauthorized person and must ensure that documents containing your
          username, password or other access credentials are kept in such a way
          that unauthorized persons cannot gain access to the information. You
          are responsible for all acts or omissions by an unauthorized person
          using your access credentials. </p>
          <p>2.4.5. &emsp; Notwithstanding Clause 2.4.4,
          the Client may give access to the GatewayX Services to such
          sub-suppliers that require access to the GatewayX Services to fulfill
          their obligations towards the Client. The sub-supplier may only access
          the GatewayX Services for the purposes stated by the Client. </p>
          <p>2.4.6. &emsp;
          GatewayX has the right to, at any time, suspend or terminate the
          GatewayX Services if you violate the Agreement or otherwise use the
          GatewayX Services in a way that may cause GatewayX or a third party
          any harm. GatewayX shall not be liable for such suspension or
          termination. </p>
          <p>2.4.7. &emsp; If you as a Client has acquired access to the
          GatewayX Services by entering into an agreement with a Partner,
          GatewayX has the right to, at any time, suspend or terminate the
          GatewayX Services due to any breach by the Partner of its agreement
          with GatewayX, or termination of the agreement between GatewayX and
          the Partner for any other reason, irrespective of whether you have
          complied with the agreement between you and the Partner and this
          Agreement. GatewayX shall not be liable for such suspension or
          termination. </p>
          <p>2.4.8. &emsp; You are solely responsible for the technical
          equipment and any additional services needed to use the GatewayX
          Services, such as, but not limited to, computers, mobile phones,
          mobile phone subscriptions, Internet access, WiFi connection, and
          travel management services. You acknowledge and accept that the
          provision of the GatewayX Services is dependent upon third party
          providers, e.g. internet providers or cloud services, and that
          GatewayX has no responsibility for such third party </p>
          <h3>2.5.
          Indemnification: </h3>
          <p>2.5.1. &emsp; Each Party agrees to indemnify, defend, and
          hold harmless the other Party, its subsidiaries, agents, licensors,
          managers, consultants, officers, directors, employees, contractors,
          advisors, affiliates, and partners from and against all claims,
          losses, liability, expenses, damages, judgments, awards, and costs
          (including reasonable attorney’s fees), related to or arising from any
          breach of this Agreement. </p>.
          <p>2.5.2. &emsp; In addition to Clause 2.5.1 above the
          Client agrees to indemnify, defend, and hold harmless GatewayX its
          subsidiaries, agents, licensors, managers, consultants, officers,
          directors, employees, contractors, advisors, affiliates, and partners
          from and against all claims, losses, liability, expenses, damages,
          judgments, awards, and costs (including reasonable attorney’s fees),
          related to or arising from:</p>
          <p> 2.5.2.1.&emsp; the Client’s or its Users’ use or
          misuse of any material or information posted, provided, transmitted,
          or otherwise made available by you or by GatewayX; </p>
          <p>2.5.2.2. &emsp; the
          Client’s or its Users’ access or use of the GatewayX Services and
          related content; 2.5.2.3. the Client’s or its Users’ violation of any
          third-party right, including intellectual property and privacy rights;</p>
          <p>2.5.2.4. &emsp; the Client’s or its Users’ violation of a law, rule, or
          regulation; or</p>
          <p> 2.5.2.5. &emsp; another party’s use of the GatewayX Services
          using the Client’s or its Users’ access credentials. </p>
          <p>2.5.3. &emsp; GatewayX
          reserves the right to assume the exclusive defence and control of any
          matter which is subject to indemnification under this clause. In such
          case, you agree to cooperate with any reasonable requests assisting
          GatewayX’s defence of such matter. </p>
          <h3>2.6. Warranties and Disclaimers</h3>
          <p>2.6.1. &emsp; GatewayX does not and cannot warrant that GatewayX Services
          will operate in a manner that is completely error free, nor that
          information provided is always accurate. </p>
          <p>2.6.2. &emsp; GatewayX undertakes to
          ensure that the GatewayX Services in all material aspects corresponds
          with the descriptions at <a href="https://gatewayx.tech" rel="noreferrer" target="_blank">https://gatewayx.tech/</a> and &nbsp;
           <a href="https://assistxgateway.com/" target="_blank" rel="noreferrer">https://assistxgateway.com/. </a> </p>
          <p>2.6.3. &emsp; Use of the GatewayX Services is at
          your sole risk, and you assume full responsibility for all risk
          associated therewith. All information provided through GatewayX
          Services is provided “as is” and “as available”, without any
          warranties or conditions. GatewayX reserves the right, in its sole
          discretion and without any obligation, to correct any error or
          omission in any portion of the GatewayX Services, the Platform, or the
          App, with or without notice to you. </p>
          <p>2.6.4. &emsp; TO THE FULLEST EXTENT
          PERMISSIBLE UNDER APPLICABLE LAW, GATEWAYX AND ITS AFFILIATES HEREBY
          DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED,
          STATUTORY OR OTHERWISE. THESE INCLUDE, BUT ARE NOT LIMITED TO,
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          TITLE, AND NON-INFRINGEMENT. GATEWAYX WILL NOT BE LIABLE FOR ANY LOSS
          OR DAMAGE CAUSED BY ANY TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY
          INFECT YOUR EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
          MATERIAL DUE TO YOUR USE OF THE GATEWAYX SERVICES. </p>
          <p>2.6.5. &emsp;WITHOUT
          LIMITING THE FOREGOING, GATEWAYX AND ITS AFFILIATES MAKE NO WARRANTY
          AS TO THE SECURITY, RELIABILITY, AVAILABILITY, ACCURACY, QUALITY,
          TIMELINESS, USEFULNESS, ADEQUACY, COMPLETENESS, OR SUITABILITY OF THE
          GATEWAYX SERVICES AND INFORMATION RETRIEVED OR RECEIVED FROM IT.
          GATEWAYX AND ITS AFFILIATES ASSUME NO RESPONSIBILITY FOR COMPLETENESS,
          ERRORS, OR OMISSIONS IN THE INFORMATION, SERVICES, OR DOCUMENTS THAT
          ARE AVAILABLE IN, REFERENCED BY OR LINKED TO IN GATEWAYX SERVICES.</p>
          <p>2.6.6. &emsp; The foregoing does not affect any warranties which cannot be
          excluded or limited under applicable law. </p>
          <p>2.6.7. &emsp; GatewayX may at any
          time inform you, by updating a non-compliance list, that the GatewayX
          Services cannot be used in certain regions, countries, or states due
          to applicable laws, and shall not be liable for your use of the
          GatewayX Services in such country. For the updated list of where the
          GatewayX Services cannot be used due to applicable laws or technical
          limitations, please visit <a href="https://gatewayx.tech" rel="noreferrer" target="_blank">https://gatewayx.tech/</a> and &nbsp;
           <a href="https://assistxgateway.com/" target="_blank" rel="noreferrer">https://assistxgateway.com/. </a></p>
          <h3>2.7. Limitation of Liability: </h3>
          <p>2.7.1. &emsp;
          UNLESS AND TO THE EXTENT SPECIFICALLY STATED OTHERWISE, GATEWAYX SHALL
          HAVE NO LIABILITY FOR ANY DAMAGE OR LOSS OF ANY KIND (INCLUDING ANY
          CLAIMS AGAINST YOU BY THIRD PARTIES) UNDER OR IN CONNECTION WITH THIS
          AGREEMENT, INCLUDING YOUR USE OF THE GATEWAYX SERVICES, REGARDLESS OF
          HOW IT WAS CAUSED AND WHETHER SUCH DAMAGE OR LOSS WAS FORESEEABLE OR
          NOT AT THE TIME WHEN THIS AGREEMENT WAS ENTERED INTO (EVEN IF ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGE OR LOSS). </p>
          <p>2.7.2. &emsp; YOU AGREE THAT YOUR
          USE OF THE GATEWAYX SERVICES IS AT YOUR OWN RISK. IF YOU ARE NOT
          SATISFIED WITH THE GATEWAYX SERVICES, YOUR SOLE AND EXCLUSIVE REMEDY
          IS TO TERMINATE YOUR USE OF THE GATEWAYX SERVICES. </p>
          <p>2.7.3. &emsp; IN NO EVENT
          SHALL GATEWAYX OR ITS AFFILIATES BE LIABLE FOR ANY SPECIAL, PUNITIVE,
          INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT
          LIMITED TO PERSONAL INJURY, DEATH, LOSS OF GOODWILL, LOSS OF USE,
          EMOTIONAL DISTRESS, LOSS OF PROFITS, INTERRUPTION OF SERVICES OR
          PRODUCTION, LOSS OF DATA, OR OTHER INTANGIBLE LOSSES, WHETHER IN ANY
          ACTION IN WARRANTY, CONTRACT, OR ANY OTHER THEORY OF LIABILITY
          (INCLUDING, BUT NOT LIMITED TO NEGLIGENCE OR FUNDAMENTAL BREACH), OR
          OTHERWISE ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF,
          RELIANCE ON, OR THE INABILITY TO USE, THE GATEWAYX SERVICES. GATEWAYX
          SHALL NOT BE SUBJECT TO LIABILITY FOR THE TRUTH, ACCURACY,
          RELIABILITY, TIMELINESS, OR COMPLETENESS OF THE CONTENT OR ANY OTHER
          INFORMATION CONVEYED TO YOU OR FOR ERRORS, MISTAKES, OR OMISSIONS
          THEREIN OR FOR ANY DELAYS OR INTERRUPTIONS OF THE DATA OR INFORMATION
          STREAM FROM WHATEVER CAUSE. GATEWAYX UNDERTAKES NO LIABILITY TOWARDS
          YOU FOR ANY MALFUNCTION OR NON-COMPATIBILITY OF YOUR EQUIPMENT AND
          CONNECTED SERVICES WITH THE GATEWAYX SERVICES. GATEWAYX DISCLAIMS ANY
          AND ALL LIABILITY FOR THE ACTS, OMISSIONS, AND CONDUCT OF ANY THIRD
          PARTIES OUTSIDE GATEWAYX’S REASONABLE CONTROL.</p>
          <p> 2.7.4. &emsp; FURTHERMORE,
          GATEWAYX WILL NOT BE RESPONSIBLE FOR DAMAGE, LOSS, OR INJURY RESULTING
          FROM </p>
          <p>2.7.4.1. &emsp; UNAUTHORIZED ACCESS TO, OR HACKING OR TAMPERING OF, YOUR
          ACCOUNT OR THE INFORMATION THEREIN; </p>
          <p>2.7.4.2. &emsp; UNAUTHORIZED ACCESS TO OR
          USE OF GATEWAYX’S SERVERS AND ANY PERSONAL INFORMATION STORED THEREIN;
          OR </p>
          <p>2.7.4.3. &emsp; BUGS, VIRUSES, TROJANS, MALWARE, OR OTHER DESTRUCTIVE
          CODE. </p>
          <p>2.7.5. &emsp; IN NO EVENT SHALL GATEWAYX’S TOTAL LIABILITY TO YOU FOR
          ALL DAMAGES EXCEED YOUR ANNUAL PAYMENTS FOR THE GATEWAYX SERVICES.</p>
          <p>2.7.6. To the extent any of the above limitations of liability are
          restricted by mandatory applicable law, such limitations shall not
          apply to the extent of such restrictions. </p>
          <p>2.7.7. &emsp; GatewayX’s liability
          towards the Client or User is exclusively regulated herein and is not
          affected by any agreement between the Client and Partner which
          provides access to the GatewayX Services to the Client. </p>
          <p>2.7.8. &emsp; If you
          access the GatewayX Services through a third party app provided by
          Partner or Client where the GatewayX Services have been incorporated
          or form an integral part of the app, GatewayX is not in any way liable
          for content generated by the Partner or Client. </p>
          <p>2.7.9. &emsp; If you access
          to content provided by Partner or a third party content provider or
          Client, that has been incorporated, or form an integral part of
          GatewayX Services, GatewayX is not in any way liable for content
          generated by Partner or third party content provider or Client.</p>
          <p>2.7.10. &emsp; If you access content through a link in the GatewayX Services
          or a third-party app to an external third party content provider such
          as, but not limited to, a local news website, GatewayX is not in any
          way liable for content generated by the third party. </p>
          <h3>2.8. No Waiver:</h3>
          <p>2.8.1. &emsp; No waiver of any term of this Agreement shall be deemed a
          further or continuing waiver of such term or any other term. </p>
          <p>2.8.2. &emsp; A
          Party’s failure to assert any right or provision under this Agreement
          shall not constitute a waiver of such right or provision. </p>
          <h3>2.9. Assignment: </h3>
          <p>2.9.1. &emsp; A Party may not assign any or transfer any rights
          under these Terms of Use to any third party, unless a prior written
          approval from the other Party, which consent shall not be unreasonably
          withheld. </p>
          <h3>2.10. Miscellaneous:</h3>
          <p> 2.10.1. &emsp; If and to the extent that
          either GatewayX’s or Client’s performance of its obligations under
          this Agreement is impeded or made unreasonably onerous by
          circumstances beyond its reasonable control, including, but not
          limited to, general labour disputes, war, fire, lightning, flood, acts
          of terrorism, amendments to regulations issued by governmental
          authorities, intervention by governmental authorities and defects or
          delays in deliveries by sub-contractors caused by any such
          circumstance as referred to in this Clause, such Party shall be
          released from liability in damages and any other penalties for delay
          in performing or failure to perform such obligations. The Party
          wishing to claim relief by reason of any circumstance as referred to
          in this Clause shall without undue delay notify the other Party in
          writing. If performance is prevented for more than one (1) month as a
          result of any of the circumstances as referred to in this Clause, the
          Party not affected by force majeure shall be entitled to immediately
          terminate the Agreement by notice in writing. </p>
          <p>2.10.2. &emsp; The provisions
          of this Agreement are severable, and in the event any provision hereof
          is determined to be invalid or unenforceable, such invalidity or
          unenforceability shall not affect the validity or enforceability of
          the remaining provisions, but such provision shall be reformed, if
          reasonably possible, only to the extent necessary to make it
          enforceable. </p>
          <h3>2.11. Governing law and disputes: </h3>
          <p>2.11.1. &emsp; This Agreement
          and any non-contractual obligations arising out of or in connection
          with any clause of this Agreement shall be governed by and construed
          in accordance with the laws of Australia, excluding its conflict of
          laws principles providing for the application of the laws of any other
          jurisdiction. Specifically excluded from application to this Agreement
          is the United Nations Convention on the International Sale of Goods </p>

          <h2>3. Privacy Policy: </h2>
          <h3>3.1. General: </h3>
          <p>3.1.1. &emsp; We are committed to protecting
          your privacy and to comply with applicable data protection and privacy
          laws. Throughout this privacy policy, (the “<b>Privacy Policy</b>”), the term
          “<b>Personal Data</b>” means information relating to an identified or
          identifiable individual (i.e., a natural person). </p>
          <p>3.1.2. &emsp; This Privacy
          Policy applies to the processing of Personal Data collected or
          submitted by you in connection with the use of the products and the
          GatewayX Services, offered by us and our affiliates, partners,
          suppliers and clients. Please read this Privacy Policy carefully to
          understand how we collect, use, transfer, and store your data as you
          make full use of the Service. </p>
          <p>3.1.3. &emsp; We recognize our responsibility
          to protect the Personal Data and other information you have provide us
          with. We take appropriate technical and organizational information
          security measures to safeguard your Personal Data against loss and
          misuse, as well as unauthorized access.</p>
          <p> 3.1.4. &emsp; Please note that if you
          use the GatewayX Services as a service provided directly from GatewayX
          then this Privacy Policy only relates to processing of Personal Data
          for which we are the controller. </p>
          <p>3.1.5. &emsp; Unless your employer or
          contracted party has provided you with a privacy policy informing you
          about the processing applicable to the GatewayX Services, this privacy
          policy shall apply between you and your employer or contracted party.
          Please note that if your employer or contracted party is located
          outside of the EU/EEA, there may be limitations to which rights you as
          a data subject may exercise due to local mandatory law. For questions
          regarding the processing of your Personal Data in such cases, or if
          you want to exercise your rights, please contact your employer or
          contracted party as instructed in your employer’s or contracted
          party’s general privacy policy.</p>
        </NewsContent>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 100px;
`
const NewsContent = styled.div`
  > p {
    font-size: 18px;
    line-height: 29px;
    padding: 10px 0px;
  }
  > h1 {
    font-size: 30px;
    padding-top: 30px;
  }
  > h2 {
    font-size: 24px;
    padding-top: 20px;
  }
`
