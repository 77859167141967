import React from 'react'
import styled from 'styled-components'
import PlatformBox from '../Elements/PlatformBox'

export const WhomDoWeServe = () => {
  return (
    <Wrapper id='#'>
      <div className='lightBg' style={{ padding: '80px 0px' }}>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>Our Customers </h1>
            <p className='font13'>
              We serve businesses, travel assistance providers, and insurance
              companies. Our comprehensive solutions cater to the specific needs
              of these industries, offering support in travel arrangements,
              emergency assistance, and insurance services. With our expertise
              and tailored offerings, we aim to provide seamless and reliable
              support to our valued partners in their respective fields.
            </p>
          </HeaderInfo>
          <PlatFormBoxRow className='flex'>
            <PlatFormBoxWrapper>
              <PlatformBox
                icon='software'
                title='Businesses'
                subtitle='Businesses use our Alerts and Client systems to plan, prepare, and protect their employees.'
                url='https://client.assistxgateway.com/'
              />
            </PlatFormBoxWrapper>
            <PlatFormBoxWrapper>
              <PlatformBox
                icon='implement'
                title='Providers'
                subtitle='Medical and security service providers use our system to grow their businesses globally.'
                url='https://provider.medxgateway.com/'
              />
            </PlatFormBoxWrapper>
            <PlatFormBoxWrapper>
              <PlatformBox
                icon='staffing'
                title='Assistance Companies'
                subtitle='Assistance companies use our Provider system to manage cases and provider network.'
                url='https://alerts.assistxgateway.com/'
              />
            </PlatFormBoxWrapper>
            <PlatFormBoxWrapper>
              <PlatformBox
                icon='staffing'
                title='Insurance Companies'
                subtitle='Insurance companies use our systems to minimize travel risk exposure.'
                url='https://alerts.assistxgateway.com/'
              />
            </PlatFormBoxWrapper>
          </PlatFormBoxRow>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
`
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    width: 100%;
  }
`
const PlatFormBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
  padding: 20px 0px 0px 0px;
`
const PlatFormBoxWrapper = styled.div`
  width: 33%;
  margin: 2rem 0rem;
  margin-right: 0.5%;
  padding: 40px 20px;
  border: 1px solid #dde1e6;
  border-radius: 5px;
  background-color: #fff;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background-color: #f2f4f8;
  }
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`
