import React from 'react'

import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material'

import RichTextEditor from '../../../../components/shared/RichTextEditor'
import LoadingWrapper from '../../../../components/shared/LoadingWrapper'
import UploadImage from '../../../../components/shared/UploadImage'

const BlogForm = ({
  blogDetail = null,
  isBusy = false,
  onSubmit = () => {},
}) => {
  const navigate = useNavigate()

  // required enum list
  const blogType = ['Resource', 'Blog', 'News']
  const categoryEnums = [
    'E-book & Guides',
    'Customer Stories',
    'Press Release',
    'Media Kit',
  ]

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      image: blogDetail?.image ?? '',
      title: blogDetail?.title ?? '',
      author: blogDetail?.author ?? '',
      content: blogDetail?.content ?? '',
      tags: blogDetail?.tags ?? '',
      slug: blogDetail?.slug ?? '',
      feature: blogDetail?.feature ?? false,
      status: blogDetail?.status ?? true,
      type: blogDetail?.type ?? blogType[0],
      category: blogDetail?.category ?? categoryEnums[0],
    },
  })
  //   methods
  const handleLogoUrl = (imageUrl) => {
    reset({ ...blogDetail, image: imageUrl })
  }

  React.useEffect(() => {
    if (!blogDetail) return

    reset({ ...blogDetail, category: blogDetail?.category?._id ?? '' })
  }, [blogDetail, reset])

  return (
    <>
      <Box padding={3}>
        <Grid container spacing={6}>
          <Grid item md={2.5}>
            <Controller
              name='image'
              control={control}
              rules={{ required: 'Image is required' }}
              render={() => (
                <UploadImage
                  imageUrl={blogDetail ? blogDetail.image : null}
                  onUploadSuccess={handleLogoUrl}
                />
              )}
            />
            {errors.image && <p className='error'>{errors.image.message}</p>}
          </Grid>

          <Grid item md={9.5}>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <Controller
                  name='title'
                  control={control}
                  rules={{ required: 'Title is required' }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label='Title'
                      type='text'
                      size='small'
                      {...field}
                    />
                  )}
                />
                {errors.title && (
                  <p className='error'>{errors.title.message}</p>
                )}
              </Grid>

              <Grid item md={6}>
                <Controller
                  name='author'
                  control={control}
                  rules={{ required: 'Author is required' }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label='Author'
                      type='text'
                      size='small'
                      {...field}
                    />
                  )}
                />
                {errors.author && (
                  <p className='error'>{errors.author.message}</p>
                )}
              </Grid>

              {/* <Grid item md={6}>
                <Controller
                  name='category'
                  control={control}
                  rules={{ required: 'Select a category' }}
                  render={({ field, value }) => (
                    <FormControl fullWidth size='small'>
                      <InputLabel id='select-category'>Category</InputLabel>
                      <Select
                        fullWidth
                        labelId='select-category'
                        label='Category'
                        placeholder='Select Category'
                        id='category'
                        size='small'
                        value={value ? value : ''}
                        {...field}
                      >
                        {blogCategories?.map((cat) => (
                          <MenuItem key={cat._id} value={cat._id}>
                            {cat.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors.category && (
                  <p className='error'>{errors.category.message}</p>
                )}
              </Grid> */}

              <Grid item md={6}>
                <Controller
                  name='tags'
                  control={control}
                  rules={{ required: 'Tag is required' }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label='Tags'
                      type='text'
                      size='small'
                      {...field}
                    />
                  )}
                />
                {errors.tags && <p className='error'>{errors.tags.message}</p>}
              </Grid>

              <Grid item md={6}>
                <Controller
                  name='isActive'
                  control={control}
                  render={({ field, value }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          name='isActive'
                          color='primary'
                          checked={field.value}
                          value={value}
                          {...field}
                        />
                      }
                      label='Is Active ?'
                    />
                  )}
                />
                {errors.status && (
                  <p className='error'>{errors.status.message}</p>
                )}
              </Grid>

              <Grid item md={6}>
                <Controller
                  name='isFeatured'
                  control={control}
                  render={({ field, value }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          name='isFeatured'
                          color='primary'
                          checked={field.value}
                          value={value}
                          {...field}
                        />
                      }
                      label='Is Featured?'
                    />
                  )}
                />
                {errors.isFeatured && (
                  <p className='error'>{errors.isFeatured.message}</p>
                )}
              </Grid>

              <Grid item md={6}>
                <Controller
                  name='type'
                  control={control}
                  rules={{ required: 'Please select an blog type' }}
                  render={({ field, value }) => (
                    <FormControl fullWidth size='small'>
                      <InputLabel id='select-type'>Blog Type</InputLabel>
                      <Select
                        fullWidth
                        labelId='select-type'
                        label='Blog Type'
                        placeholder='Select Blog Type'
                        id='type'
                        value={value ? value : ''}
                        {...field}
                      >
                        {blogType?.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors.type && <p className='error'>{errors.type.message}</p>}
              </Grid>

              <Grid item md={6}>
                <Controller
                  name='category'
                  control={control}
                  rules={{ required: 'Please select an blog category' }}
                  render={({ field, value }) => (
                    <FormControl fullWidth size='small'>
                      <InputLabel id='select-type'>Blog Category</InputLabel>
                      <Select
                        fullWidth
                        labelId='select-category'
                        label='Blog Category'
                        placeholder='Select Blog Category'
                        id='category'
                        value={value ? value : ''}
                        {...field}
                      >
                        {categoryEnums?.map((cat) => (
                          <MenuItem key={cat} value={cat}>
                            {cat}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors.category && (
                  <p className='error'>{errors.category.message}</p>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <div
              style={{ color: 'gray', fontSize: 15, marginBottom: '.75rem' }}
            >
              Content
            </div>
            <Controller
              name='content'
              control={control}
              rules={{ required: 'Content is required' }}
              render={({ field: { onChange } }) => (
                <RichTextEditor
                  initialValue={
                    blogDetail?.content
                      ? blogDetail.content
                      : '<p>Enter Blog Content.</p>'
                  }
                  onChange={onChange}
                />
              )}
            />
            {errors.content && (
              <p className='error'>{errors.content.message}</p>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          background: '#fafafa',
        }}
      >
        <Button disabled={isBusy} onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button
          variant='contained'
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {blogDetail ? 'Update' : 'Add'}
        </Button>
      </Box>
    </>
  )
}

export default BlogForm
