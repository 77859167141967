import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { HomeRouter } from './routers/HomeRouter'
import { LoginScreen } from './screens/Admin/LoginScreen'
import { PageScreen404 } from './screens/ErrorPages/404PageScreen'
import { AdminRouter } from './routers/AdminRouter'

export default function App() {
  return (
    <Routes>
      <Route path='/*' element={<HomeRouter />} />
      <Route path='/admin/*' element={<AdminRouter />} />
      <Route path='/admin-login' element={<LoginScreen />} />
      <Route path='*' element={<PageScreen404 />} />
    </Routes>
  )
}
