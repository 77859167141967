import React from 'react'

import { Editor } from '@tinymce/tinymce-react'

const TINY_CLOUD_API_KEY = 'si6ytu3xmfudkxd11kqyp5mpcvbok2qa35pgqzf80r3tl2kg'

const RichTextEditor = ({
  initialValue,
  onChange,
  height = 200,
  menubar = false,
}) => {
  const editorRef = React.useRef(null)

  return (
    <Editor
      onInit={(evt, editor) => (editorRef.current = editor)}
      apiKey={TINY_CLOUD_API_KEY}
      onEditorChange={onChange}
      initialValue={initialValue}
      init={{
        height,
        menubar,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      }}
    />
  )
}

export default RichTextEditor
