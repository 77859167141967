import React from 'react'
import styled from 'styled-components'
// Assets
import TripIcon from '../../assets/svg/Services/TripIcon'
import MonitorIcon from '../../assets/svg/Services/MonitorIcon'
import BrowserIcon from '../../assets/svg/Services/BrowserIcon'
import PrinterIcon from '../../assets/svg/Services/PrinterIcon'
import SoftwareIcon from '../../assets/svg/Services/SoftwareIcon'
import ImplementIcon from '../../assets/svg/Services/ImplementIcon'
import StaffingIcon from '../../assets/svg/Services/StaffingIcon'

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon

  switch (icon) {
    case 'trip':
      getIcon = <TripIcon />
      break
    case 'software':
      getIcon = <SoftwareIcon />
      break
    case 'implement':
      getIcon = <ImplementIcon />
      break
    case 'staffing':
      getIcon = <StaffingIcon />
      break
    case 'monitor':
      getIcon = <MonitorIcon />
      break
    case 'browser':
      getIcon = <BrowserIcon />
      break
    case 'printer':
      getIcon = <PrinterIcon />
      break
    default:
      getIcon = <TripIcon />
      break
  }

  return (
    <Wrapper className='flex flexColumn'>
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className='font20 extraBold'>{title}</TitleStyle>
      <SubtitleStyle className='font13'>{subtitle}</SubtitleStyle>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
const IconStyle = styled.div`
  text-align: center;
  @media (max-width: 860px) {
    margin: 0 auto;
    float: center;
    text-align: center;
  }
`
const TitleStyle = styled.h2`
  text-align: center;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`
