import React from 'react'
import styled from 'styled-components'
import FullButton from '../Buttons/FullButton'
import { Link as NewLink } from 'react-router-dom'

import image from '../../assets/img/assistX.png'

export const Resources = () => {
  return (
    <Wrapper id='resources'>
      <div className='WhiteBg'>
        <div className='container'>
          <Advertising className='flexSpaceCenter'>
            <AddLeft>
              <h4 className='font15 semiBold'>RESOURCES</h4>
              <h2 className='font40 extraBold'>Knowledge Base</h2>
              <p className='font13'>
                Explore AssistXGateway's knowledge base to discover our
                publications, product tutorials and market updates relating to
                our products and services.
              </p>
              <ButtonsRow
                className='flexNullCenter'
                style={{ margin: '30px 0' }}
              >
                <div style={{ width: '190px' }}>
                  <NewLink to='/resources'>
                    <FullButton title='Explore' />
                  </NewLink>
                </div>
              </ButtonsRow>
            </AddLeft>
            <AddRight>
              <StoriesBoxWrapper>
                <NewLink to='/resources/detail'>
                  <img
                    src={image}
                    alt='hero background'
                    style={{
                      height: '200px',
                      width: '100%',
                      borderRadius: '5px 5px 0px 0px',
                      // overflow: 'hidden',
                    }}
                  />
                  <TextWrapper className=''>
                    <h3 className='font20'>
                      Technology solution that works for you
                    </h3>
                    {/* <p className='font13'>
                      Bitly’s sales development team sets meetings for account
                      executives who then deal directly with customers to close
                      deals.
                    </p> */}
                    <h4>
                      Read more&nbsp;&nbsp;
                      <i className='fas fa-angle-double-right'></i>
                    </h4>
                  </TextWrapper>
                </NewLink>
              </StoriesBoxWrapper>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 0px;
`
const Advertising = styled.div`
  display: flex;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0px 0 30px 0;
    margin: 40px 0 0px 0;
  }
`
const AddLeft = styled.div`
  width: 100%;
  // min-height: 300px;

  @media (max-width: 860px) {
    width: 100%;
    order: 0;
    margin-top: 40px;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`
const StoriesBoxWrapper = styled.div`
  width: 60%;
  float: right;
  min-height: 380px;
  background-color: #f2f4f8;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  @media (max-width: 860px) {
    width: 100%;
  }
`
const AddRight = styled.div`
  width: 100%;
  align-text: right;
  @media (max-width: 860px) {
    width: 100%;
  }
  > h4 {
    padding-bottom: 20px;
  }
`
const TextWrapper = styled.div`
  text-align: left;
  padding: 1rem 1rem;
  margin-bottom: 1.5rem;
  > h3 {
    padding: 1rem 0rem;
  }
  > p {
    font-size: 1rem;
    padding: 1rem 0rem;
  }
  > h4 {
    font-size: 1.25rem;
    color: #002984;
  }
`
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`
