import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'
import { Link as RouterLink } from 'react-router-dom'

// Assets
import CloseIcon from '../../assets/svg/CloseIcon'
import logo from '../../assets/img/logo2.png'
import { useLocation } from 'react-router-dom'
import { Button, Menu, MenuItem } from '@mui/material'

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const location = useLocation()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (url) => {
    setAnchorEl(null)
    toggleSidebar(!sidebarOpen)
    if (url) {
      window.open(url, '_blank')
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        setAnchorEl(null)
      }
    }
    window.addEventListener('click', handleOutsideClick)
    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [anchorEl])

  return (
    <Wrapper className='animate darkBg' sidebarOpen={sidebarOpen}>
      <SidebarHeader className='flexSpaceCenter'>
        <div className='flexNullCenter'>
          <Link className='pointer flexNullCenter' to='home' smooth={true}>
            <img
              onClick={() => toggleSidebar(!sidebarOpen)}
              className='logoImage'
              src={logo}
              style={{ width: '150px' }}
              alt='logoImage'
            ></img>
          </Link>
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className='animate pointer'
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className='flexNullCenter flexColumn'>
        <li className='semiBold font15 pointer'>
          {location.pathname === '' || location.pathname === '/' ? (
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='home'
              spy={true}
              smooth={true}
              offset={-60}
            >
              Home
            </Link>
          ) : (
            <RouterLink
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='/'
            >
              Home
            </RouterLink>
          )}
        </li>
        <li className='semiBold font15 pointer'>
          {location.pathname === '' || location.pathname === '/' ? (
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='system'
              spy={true}
              smooth={true}
              offset={-60}
            >
              Systems
            </Link>
          ) : (
            <RouterLink
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='/#system'
            >
              Systems
            </RouterLink>
          )}
        </li>
        <li className='semiBold font15 pointer'>
          {location.pathname === '' || location.pathname === '/' ? (
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='explore'
              spy={true}
              smooth={true}
              offset={-60}
            >
              Explore
            </Link>
          ) : (
            <RouterLink
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='/#explore'
            >
              Explore
            </RouterLink>
          )}
        </li>
        <li className='semiBold font15 pointer'>
          {location.pathname === '' || location.pathname === '/' ? (
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='resources'
              spy={true}
              smooth={true}
              offset={-60}
            >
              Resources
            </Link>
          ) : (
            <RouterLink
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='/#resources'
            >
              Resources
            </RouterLink>
          )}
        </li>
        <li className='semiBold font15 pointer'>
          {location.pathname === '' || location.pathname === '/' ? (
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='news'
              spy={true}
              smooth={true}
              offset={-60}
            >
              News
            </Link>
          ) : (
            <RouterLink
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='/#news'
            >
              News
            </RouterLink>
          )}
        </li>

        <li className='semiBold font15 pointer'>
          {location.pathname === '' || location.pathname === '/' ? (
            <RouterLink
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='pricing'
            >
              Pricing
            </RouterLink>
          ) : (
            <RouterLink
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='pricing'
            >
              Pricing
            </RouterLink>
          )}
        </li>
        <li className='semiBold font15 pointer'>
          {location.pathname === '' || location.pathname === '/' ? (
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='contact'
              spy={true}
              smooth={true}
              offset={-60}
            >
              Contact Us
            </Link>
          ) : (
            <RouterLink
              activeClass='active'
              className='whiteColor'
              style={{ padding: '10px 15px' }}
              to='/#contact'
            >
              Contact Us
            </RouterLink>
          )}
        </li>
      </UlStyle>
      <UlStyle2>
        <li className='semiBold font15 pointer flexCenter'>
          {/* {location.pathname === '' || location.pathname === '/' ? (
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              // activeClass='active'
              className='radius8 lightBg'
              style={{ padding: '10px 15px' }}
              to='contact'
              spy={true}
              smooth={true}
              offset={-60}
            >
              Get Started
            </Link>
          ) : (
            <RouterLink
              // activeClass='active'
              className='radius8 lightBg'
              style={{ padding: '10px 15px' }}
              to='/#contact'
            >
              Get Started
            </RouterLink>
          )} */}
          <div>
            <Button
              variant='contained'
              onClick={handleClick}
              aria-controls='dropdown-menu'
              aria-haspopup='true'
              style={{ color: 'white', backgroundColor: '#3f51bf' }}
            >
              Login
            </Button>
            <Menu
              id='dropdown-menu'
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => handleClose(null)}
              style={{ zIndex: '99999' }}
            >
              <MenuItem
                onClick={() =>
                  handleClose('https://client.assistxgateway.com/')
                }
              >
                Client
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleClose('https://provider.medxgateway.com/login')
                }
              >
                Provider
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleClose('https://alerts.assistxgateway.com/login')
                }
              >
                Alert
              </MenuItem>
              <MenuItem
                onClick={() => handleClose('https://assistxgateway.com/login')}
              >
                API
              </MenuItem>
            </Menu>
          </div>
        </li>
      </UlStyle2>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? '0px' : '-400px')};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`
const SidebarHeader = styled.div`
  padding: 20px 0;
`
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`
const UlStyle2 = styled.ul`
  li {
    margin: 5px 0;
  }
`
