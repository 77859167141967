import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      id='flat_final'
      width={43.42}
      height={43.42}
      viewBox='0 0 48 48'
      xmlns='http://www.w3.org/2000/svg'
      data-name='flat final'
    >
      <path
        d='m36.43 5v42.25h-31.68l.01-4.425h27.481v-37.825z'
        fill='#96bdf2'
      />
      <path d='m32.42.75v42.25h-31.67v-34.01l8.2-8.24z' fill='#cae0fa' />
      <g fill='#1e81ce'>
        <path d='m8.945.751v8.196h-8.195z' />
        <path d='m19.249 6.525a9.508 9.508 0 0 1 -1.665-.925 9.508 9.508 0 0 1 -1.665.924 7.972 7.972 0 0 1 -2.309.48s.358 4.233.776 4.963a6.292 6.292 0 0 0 3.2 2.63 6.292 6.292 0 0 0 3.2-2.63c.418-.73.776-4.963.776-4.963a7.967 7.967 0 0 1 -2.313-.479z' />
        <path d='m26.223 21.316h-17.278a.75.75 0 0 1 0-1.5h17.278a.75.75 0 0 1 0 1.5z' />
        <path d='m26.223 25.526h-8.639a.75.75 0 0 1 0-1.5h8.639a.75.75 0 0 1 0 1.5z' />
        <path d='m14.919 25.526h-5.974a.75.75 0 0 1 0-1.5h5.974a.75.75 0 0 1 0 1.5z' />
        <path d='m26.223 29.737h-17.278a.75.75 0 0 1 0-1.5h17.278a.75.75 0 0 1 0 1.5z' />
        <path d='m26.223 33.948h-2.729a.75.75 0 0 1 0-1.5h2.729a.75.75 0 0 1 0 1.5z' />
        <path d='m20.892 33.948h-11.947a.75.75 0 0 1 0-1.5h11.947a.75.75 0 1 1 0 1.5z' />
        <path d='m26.223 38.158h-17.278a.75.75 0 0 1 0-1.5h17.278a.75.75 0 0 1 0 1.5z' />
      </g>
    </svg>
  )
}

export default SvgComponent
