import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      viewBox='0 0 100 100'
      width={43.42}
      height={43.42}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='object'>
        <g id='_02' fill='#96bdf2' data-name='02'>
          <path d='m91.725 27.5a1.008 1.008 0 1 0 -1.008-1.008 1.008 1.008 0 0 0 1.008 1.008z' />
          <circle cx='88.427' cy='26.49' r='1.008' />
          <circle cx='85.13' cy='26.49' r='1.008' />
          <circle cx='51.594' cy='61.321' r='1.008' />
          <path d='m48.151 52.61a1 1 0 0 0 1-1 2.445 2.445 0 1 1 3.77 2.053 5.034 5.034 0 0 0 -2.327 4.226v.411a1 1 0 0 0 2 0v-.409a3.04 3.04 0 0 1 1.415-2.548 4.44 4.44 0 0 0 1.911-4.764 4.4 4.4 0 0 0 -3.291-3.291 4.448 4.448 0 0 0 -5.478 4.324 1 1 0 0 0 1 .998z' />
          <path d='m38 67.778a1 1 0 0 0 1.054.23l5.186-1.931a13.461 13.461 0 0 0 16.913-1.77 13.286 13.286 0 0 0 2.576-3.645 13.374 13.374 0 0 0 5.853-2.086l5.185 1.924a1 1 0 0 0 1.285-1.285l-2.005-5.415a13.517 13.517 0 0 0 -21.379-16.108 13.343 13.343 0 0 0 -2.568 3.608c-.006.013 0 .027-.01.04a13.487 13.487 0 0 0 -10.316 19.96l-2.007 5.419a1 1 0 0 0 .233 1.059zm5.451-21.17a11.512 11.512 0 0 1 18.706 12.714v.006a11.276 11.276 0 0 1 -2.416 3.564 11.472 11.472 0 0 1 -14.785 1.253.99.99 0 0 0 -.579-.185 1 1 0 0 0 -.349.062l-3.628 1.35 1.412-3.814a1 1 0 0 0 -.081-.865 11.505 11.505 0 0 1 1.719-14.085z' />
          <path d='m95.01 21.768h-88.021a1 1 0 0 0 -1 1v56.463a1 1 0 0 0 1 1h88.021a1 1 0 0 0 1-1v-56.463a1 1 0 0 0 -1-1zm-82.11 27.087a1.008 1.008 0 1 1 1.008-1.008 1.008 1.008 0 0 1 -1.008 1.008zm0-3.573a1.008 1.008 0 1 1 1.008-1.007 1.007 1.007 0 0 1 -1.008 1.007zm0-3.572a1.008 1.008 0 1 1 1.007-1.01 1.008 1.008 0 0 1 -1.007 1.01zm0-3.573a1.008 1.008 0 1 1 1.008-1.007 1.007 1.007 0 0 1 -1.008 1.007zm2.337-4.574h-4.676a1 1 0 0 1 0-2h4.675a1 1 0 0 1 0 2zm0-3.037h-4.676a1 1 0 0 1 0-2h4.675a1 1 0 0 1 0 2zm0-3.036h-4.676a1 1 0 0 1 0-2h4.675a1 1 0 0 1 0 2zm78.773 50.741h-74.2v-54.463h74.2z' />
        </g>
        <g id='_01' data-name='01'>
          <path
            d='m94.011 20.768h-88.022a1 1 0 0 0 -1 1v56.463a1 1 0 0 0 1 1h88.022a1 1 0 0 0 1-1v-56.463a1 1 0 0 0 -1-1zm-87.022 56.463v-54.463h9.821v54.463zm86.022 0h-74.201v-54.463h74.2zm-3.293-51.741a1.008 1.008 0 1 1 1.007 1.01 1.008 1.008 0 0 1 -1.007-1.01zm-3.3 0a1.008 1.008 0 1 1 1.009 1.01 1.008 1.008 0 0 1 -1.007-1.01zm-3.3 0a1.008 1.008 0 1 1 1.012 1.01 1.008 1.008 0 0 1 -1.008-1.01zm-70.211 10.64a1.008 1.008 0 1 1 -1.007-1.008 1.007 1.007 0 0 1 1.007 1.008zm0 3.572a1.008 1.008 0 1 1 -1.007-1.002 1.008 1.008 0 0 1 1.007 1zm0 3.573a1.008 1.008 0 1 1 -1.007-1.008 1.007 1.007 0 0 1 1.007 1.008zm0 3.572a1.008 1.008 0 1 1 -1.007-1.007 1.008 1.008 0 0 1 1.007 1.007zm2.329-21.357a1 1 0 0 1 -1 1h-4.674a1 1 0 0 1 0-2h4.674a1 1 0 0 1 1 1zm0 3.036a1 1 0 0 1 -1 1h-4.674a1 1 0 0 1 0-2h4.674a1 1 0 0 1 1 1zm0 3.037a1 1 0 0 1 -1 1h-4.674a1 1 0 1 1 0-2h4.674a1 1 0 0 1 1 1zm36.364 28.758a1.008 1.008 0 1 1 -1-1.008 1.007 1.007 0 0 1 1 1.008zm-5.449-9.711a4.445 4.445 0 1 1 6.858 3.731 3.039 3.039 0 0 0 -1.409 2.548v.411a1 1 0 0 1 -2 0v-.409a5.033 5.033 0 0 1 2.326-4.226 2.459 2.459 0 0 0 1.05-2.641 2.424 2.424 0 0 0 -1.787-1.787 2.448 2.448 0 0 0 -3.033 2.375 1 1 0 0 1 -2 0zm-9.151 16.168a1 1 0 0 0 1.055.23l5.185-1.931a13.449 13.449 0 0 0 19.49-5.415 13.374 13.374 0 0 0 5.852-2.086l5.186 1.924a1 1 0 0 0 1.285-1.285l-2.006-5.415a13.517 13.517 0 0 0 -21.379-16.108 13.318 13.318 0 0 0 -2.568 3.608c-.007.013-.005.027-.011.04a13.487 13.487 0 0 0 -10.315 19.96l-2.006 5.419a1 1 0 0 0 .232 1.059zm16.081-28.671a11.516 11.516 0 0 1 18.008 14.086 1 1 0 0 0 -.081.865l1.412 3.807-3.627-1.343a1 1 0 0 0 -.926.122 11.436 11.436 0 0 1 -4.3 1.855 13.469 13.469 0 0 0 -12.18-17.222 11.337 11.337 0 0 1 1.694-2.17zm-10.631 7.5a11.512 11.512 0 0 1 18.707 12.715v.006a11.276 11.276 0 0 1 -2.416 3.564 11.472 11.472 0 0 1 -14.785 1.253.99.99 0 0 0 -.579-.185 1.008 1.008 0 0 0 -.349.062l-3.628 1.35 1.412-3.814a1 1 0 0 0 -.081-.865 11.505 11.505 0 0 1 1.719-14.085z'
            fill='#1e81ce'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
