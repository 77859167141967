import React from 'react'

import { Outlet } from 'react-router-dom'

import Footer from '../components/Sections/Footer'
import TopNavbar from '../components/Nav/TopNavbar'

import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from 'react-cookie-consent'

const GuestLayout = ({ children }) => {
  return (
    <>
      <TopNavbar />
      <Outlet />
      {JSON.parse(window.localStorage.getItem('cookiesAccept')) !== true && (
        <CookieConsent
          debug={true}
          location='bottom'
          buttonText='Accept All Cookies'
          style={{ background: '#345', textAlign: 'left' }}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          expires={150}
          onAccept={() => window.localStorage.setItem('cookiesAccept', true)}
        >
          By clicking “Accept All Cookies”, you agree to the storing of cookies
          on your device to enhance navigation, analyse usage, and assist in our
          personalisation.&nbsp;
          <a href='/privacy-policy' style={{ color: 'white' }}>
            More information about your privacy notice.
          </a>
        </CookieConsent>
      )}
      <Footer />
    </>
  )
}

export default GuestLayout
