import React from 'react'

import ReusableSidebar from './ResusableSidebar'

const AdminSidebar = () => {
  const menus = [
    {
      title: 'Dashboard',
      path: '/admin/dashboard',
      icon: 'DashboardCustomize',
    },
    {
      title: 'Users',
      path: '/admin/user/list',
      icon: 'AdminPanelSettings',
    },
    {
      title: 'Blogs',
      path: '/admin/blog/list',
      icon: 'Notes',
    },
  ]
  return <ReusableSidebar menuList={menus} />
}

export default AdminSidebar
