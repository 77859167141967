import React from 'react'
import styled from 'styled-components'
import assistX from '../../assets/img/assistX_detailPage.png'
// import AuthorImage from '../../assets/img/AuthorImage.jpeg'

export const ResourcesDetailScreen = () => {
  return (
    <Wrapper id='services'>
      <div style={{ padding: '60px 0' }}>
        <div className='container'>
          <div className='row' style={{ paddingBottom: '30px' }}>
            <div className='col-xs-12 col-sm-12'>
              <img
                src={assistX}
                alt='hero background'
                style={{
                  width: '70%',
                  borderRadius: '5px',
                }}
              />
            </div>
            <div className='col-xs-12 col-sm-8 col-md-8 col-lg-8'>
              <HeaderInfo>
                <h1 className='font40 extraBold'>
                  Technology solution that works for you
                </h1>
                <NewsInfo>
                  <p> READ TIME: 5 MINUTES</p>
                  <p> AssistXGateway, October 16, 2022</p>
                </NewsInfo>
              </HeaderInfo>
              <NewsContent>
                <p className='font13'>
                  As your assistance business grows, the clients grow, the
                  transactions grow, the team grows and so does the challenges
                  in managing information, processes and tasks. Unlike in the
                  many other industries, the purpose-built solutions for
                  assistance industries are few. As a group, we have first-hand
                  experience that such systems are not readily available and
                  those available are expensive. We took the challenge ourselves
                  and developed an end-to-end suite of technologies for the
                  assistance industry.
                </p>
                <p className='font13' style={{ margin: '10px 0px' }}>
                  We have worked hard to solve four major problems, and with our
                  systems a complete solution can be provided.
                </p>
                <br />

                {/* <FirstList> */}
                <p className='font13' style={{ margin: '10px 0px' }}>
                  1. Travel risk management system for the clients – Clients
                  expect us to assist with the duty of care obligations for
                  their global workforce. Clients need assistance companies to
                  provide a system for them to manage travel risks and empower
                  their employees with information and training to stay safe and
                  seek support 24x7. AssistXGateway has developed{' '}
                  <strong>Customer system</strong> to provide such a platform.
                </p>
                <p className='font13' style={{ margin: '10px 0px' }}>
                  2. Case management system for the assistance team – The
                  assistance team needs a robust system to manage incoming
                  cases, communications, notes, attachments, reserves, guarantee
                  of payments, invoices, etc. Assistance team also needs a
                  database of providers in every country whom they could contact
                  to provide assistance to the client. AssistXGateway has
                  developed
                  <strong> Provider system </strong> to provide such a platform.
                </p>
                <p className='font13' style={{ margin: '10px 0px' }}>
                  3. Support system to manage the assistance call centre – When
                  the volumes of assistance requests surge, there comes the need
                  to run a 24x7 assistance call centre that can handle mass
                  casualty scenarios. Such operations need to be able to handle
                  hundreds of chats, calls and emails every hour and manage all
                  communications. AssistXGateway has developed{' '}
                  <strong> Support system </strong> to provide an excellent call
                  centre system.
                </p>
                <p className='font13' style={{ margin: '10px 0px' }}>
                  4. Alerts and news system to keep travellers safe – Travellers
                  need to know what is happening around them during the travel
                  to keep themselves safe. Clients have expectation that we will
                  provide them relevant information to keep their employees
                  safe. AssistXGateway has developed{' '}
                  <strong> Alerts system </strong>
                  for news service that notifies travellers of incidents 24x7 in
                  real time. We maintain a comprehensive level of information on
                  each country that is useful to the travellers to minimize
                  risks relating to travel.
                </p>
                {/* </FirstList> */}
                <br />
                <p className='font13' style={{ margin: '10px 0px' }}>
                  We understand every assistance business is unique and they
                  have their own requirements. We understand many of such
                  companies are growing with the industry and are not able to
                  afford expensive system with upfront fixed costs. Hence, we
                  have designed not only our technology but also our pricing in
                  a way it works for you.
                </p>
                <br />
                {/* <SecondList> */}
                <p className='font13' style={{ margin: '10px 0px' }}>
                  <li>
                    All AssistXGateway systems are offered primarily as
                    subscription-based software at an affordable per user per
                    month fee.
                  </li>
                </p>
                <p className='font13' style={{ margin: '10px 0px' }}>
                  <li>
                    You are free to upgrade or downgrade licences at any time
                    without any penalties or friction. We do not have lock in
                    period, minimum requirements or binding licences.
                  </li>
                </p>
                <p className='font13' style={{ margin: '10px 0px' }}>
                  <li>
                    All AssistXGateway system can be used individually, together
                    or in combination with other in-house or external systems.
                    You can mix and match the systems you need.
                  </li>
                </p>
                {/* </SecondList> */}
              </NewsContent>
            </div>
          </div>
          {/* <AuthorSection>
            <div className='row'>
              <div className='col-xs-12 col-sm-3'>
                <img
                  src={AuthorImage}
                  alt='hero background'
                  style={{
                    width: '100%',
                    borderRadius: '120px',
                  }}
                />
              </div>
              <div className='col-xs-12 col-sm-9'>
                <AuthorDetail>
                  <h5> JULIA FARINA</h5>
                  <p>
                    Julia is a Senior Product Marketing Manager at AssistXGateway.
                  </p>
                  <a
                    href='https://www.facebook.com/firstsourceworld'
                    rel='noreferrer'
                    target='_blank'
                    className='SocialMediaIcons mt-5'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='22'
                      height='22'
                      fill='#000'
                      className='bi bi-linkedin'
                      viewBox='0 0 16 16'
                    >
                      <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                    </svg>
                  </a>
                </AuthorDetail>
              </div>
            </div>
          </AuthorSection> */}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 80px;
  min-height: 90vh;
`
const HeaderInfo = styled.div`
  padding: 40px 0px;
  > h1 {
    color: #000;
    font-size: 42px;
    line-height: 52px;
  }
  @media (max-width: 860px) {
    > h1 {
      font-size: 32px;
      line-height: 42px;
    }
  }
`
const NewsInfo = styled.section`
  padding-bottom: 20px;
  font-size: 18px;
  color: #666666;
`
const NewsContent = styled.div`
  > p {
    font-size: 18px;
    line-height: 29px;
  }
`
// const AuthorSection = styled.div`
//   background-color: #f2f4f8;
//   min-height: 50px;
//   border-radius: 5px;
//   padding: 40px;
//   margin: 40px 0px;
// `
// const AuthorDetail = styled.div`
//   > h5 {
//     font-size: 20px;
//     padding: 40px 0px 20px 0px;
//     color: #666666;
//   }
//   > p {
//     color: #000;
//     padding-bottom: 60px;
//   }
// `
const FirstList = styled.div`
  padding-left: 0rem;
`
const SecondList = styled.div`
  padding-left: 0rem;
`
