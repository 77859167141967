import React from 'react'
import clsx from 'clsx'

import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { Container } from '@mui/system'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  Avatar,
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'

import logo from '../assets/img/logo.png'
import { removeUser } from '../rtkStore/authSlice'

// import { removeUser } from '../rtkStore/authSlice'

const _drawerWidth = 280

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: 10,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderBottom: '1px solid lightgray',
  },
  appBarShift: {
    width: `calc(100% - ${_drawerWidth}px)`,
    marginLeft: _drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: _drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: _drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -_drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

const Logo = () => {
  return <img src={logo} alt='FSW Logo' style={{ height: 30, width: '100%' }} />
}

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const opened = Boolean(anchorEl)

  const dispatch = useDispatch()

  // methods
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(removeUser())
  }

  return (
    <>
      <Tooltip title='Account settings'>
        <IconButton
          onClick={handleClick}
          size='small'
          aria-controls={opened ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={opened ? 'true' : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={opened}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <MenuItem
        onClick={() => {
          profileHandler();
        }}
        >
          <Avatar /> Edit Profile
        </MenuItem>
        <MenuItem
        onClick={() => {
          passwordHandler();
        }}
        >
          <Avatar /> Change Password
        </MenuItem>
        <Divider /> */}
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}

export default function SidebarLayout({ sidebar: SidebarMenus }) {
  const classes = useStyles()
  const theme = useTheme()

  const [open, setOpen] = React.useState(true)

  // methods
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        color='inherit'
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Container maxWidth='xl'>
          <Toolbar style={{ margin: '0 -20px' }}>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              // variant="h6"
              style={{
                paddingLeft: 10,
                textTransform: 'uppercase',
                fontWeight: 550,
                fontSize: 17,
              }}
              noWrap
              className={clsx(open && classes.hide)}
            >
              {/* First Source World */}
              <Logo />
            </Typography>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
              flexGrow={1}
            >
              <AccountMenu />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography
            noWrap
            style={{
              paddingLeft: 10,
              textTransform: 'uppercase',
              fontWeight: 550,
              fontSize: 17,
            }}
          >
            <Logo />
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <SidebarMenus />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        style={{ position: 'relative' }}
      >
        <div className={classes.drawerHeader} />

        <Container maxWidth='xl' style={{ padding: 0, position: 'relative' }}>
          <Outlet />
        </Container>
      </main>
    </div>
  )
}
