import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { Breadcrumbs, Typography } from "@material-ui/core";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { IconButton } from "@mui/material";

const PageBreadcrumbs = ({ breadcrumbs }) => {
  const navigate = useNavigate();

  const links = [
    {
      title: "Dashboard",
      path: "/admin/dashboard",
    },
    ...breadcrumbs,
  ];

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton size="small" onClick={() => navigate(-1)}>
        <ChevronLeftOutlinedIcon />
      </IconButton>

      <Breadcrumbs aria-label="breadcrumb">
        {links.map((link, index) => {
          return (
            <div key={`${link.title}-${index}`}>
              {link.path ? (
                <Link color="inherit" to={link.path}>
                  {link.title}
                </Link>
              ) : (
                <Typography color="textPrimary">{link.title}</Typography>
              )}
            </div>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

export default PageBreadcrumbs;
