import React from 'react'
import styled from 'styled-components'
import ServiceBox from '../Elements/ServiceBox'
// import FullButton from '../Buttons/FullButton'
// import gatewayStory from '../../assets/img/gatewayStory.png'

export default function Services() {
  return (
    <Wrapper id='#'>
      <div className='whiteBg' style={{ padding: '80px 0px' }}>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>We are here for you</h1>
            <p className='font13'>
              AssistXGateway provides range of services to individuals and
              enterprises to manage health & security risks. We help you care
              for your employees.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className='flex'>
            <ServiceBoxWrapper>
              <ServiceBox
                icon='software'
                title='Global travel assistance'
                subtitle=' Travel around the world with confidence that you and
                your employees will be assisted if anything arises.'
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon='implement'
                title='Patient escort & medevac'
                subtitle='We provide one stop international patient escort
                services and complete medical evacuation services
                around the world.'
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon='staffing'
                title='Personal travel support'
                subtitle='We provide global travel assistance to individuals
                before or during their travel or assistance for any
                travel related emergencies.'
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
`
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
  padding: 20px 0px 0px 0px;
`
const ServiceBoxWrapper = styled.div`
  width: 33%;
  margin: 2rem 0rem;
  margin-right: 5%;
  padding: 40px 20px;
  border: 1px solid #dde1e6;
  border-radius: 5px;
  background-color: #fff;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background-color: #f2f4f8;
  }
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    width: 100%;
  }
`
