import React from 'react'
import styled from 'styled-components'
import FullButton from '../Buttons/FullButton'
import { Link as NewLink } from 'react-router-dom'
import jointVenture from '../../assets/img/jointVenture3.png'

export default function News() {
  return (
    <Wrapper id='news'>
      <div className='lightBg'>
        <div className='container'>
          <Advertising className='flexSpaceCenter'>
            <AddLeft>
              <StoriesBoxWrapper>
                <NewLink to='/news/detail'>
                  <img
                    src={jointVenture}
                    alt='hero background'
                    style={{
                      height: '100%',
                      width: '100%',
                      borderRadius: '5px 5px 0px 0px',
                      // overflow: 'hidden',
                    }}
                  />
                  <TextWrapper className=''>
                    <h3 className='font20'>
                      AMI and The Can Brand to form an AI-based technology joint
                      venture, AssistXGateway
                    </h3>
                    {/* <p className='font13'>
                      The new joint venture will bring together AMI’s healthcare
                      expertise and TCB’s data science & AI capabilities to
                      build modern healthcare systems and technologies that
                      provide affordable healthcare to many around the world.
                    </p> */}
                    <h4>
                      Read more <i className='fas fa-angle-double-right'></i>
                    </h4>
                  </TextWrapper>
                </NewLink>
              </StoriesBoxWrapper>
            </AddLeft>
            <AddRight>
              <h2 className='font40 extraBold'>Our Newsroom</h2>
              <p className='font13'>
                Get up to date with our latest product launches, market
                engagements, new hires and more!
              </p>
              <ButtonsRow
                className='flexNullCenter'
                style={{ margin: '30px 0' }}
              >
                <div style={{ width: '190px' }}>
                  <NewLink to='/news'>
                    <FullButton title='More News' />
                  </NewLink>
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
`
const Advertising = styled.div`
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 50px 0 30px 0;
    margin: 80px 0 0px 0;
  }
`
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 600px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    padding: 40px 0px;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
  > h4 {
    padding-bottom: 20px;
  }
`
const AddRight = styled.div`
  width: 50%;
  float: right;
  @media (max-width: 860px) {
    width: 100%;
  }
`
const StoriesBoxWrapper = styled.div`
  width: 60%;
  ${'' /* float: right; */}
  min-height: 450px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  @media (max-width: 860px) {
    width: 100%;
  }
`
const TextWrapper = styled.div`
  text-align: left;
  padding: 1rem 1rem;
  margin-bottom: 1.5rem;
  > h3 {
    padding: 1rem 0rem;
  }
  > p {
    font-size: 1rem;
    padding: 1rem 0rem;
  }
  > h4 {
    font-size: 1.25rem;
    color: #002984;
  }
`
