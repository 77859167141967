import React from 'react'
import styled from 'styled-components'
// import ParticleBackground from '../particlebg/ParticleBackground'
import { TypeAnimation } from 'react-type-animation'
import Hero from '../../assets/727.jpg'
import travelAlert1 from '../../assets/img/image1.jpg'
import CarouselContainer from './CarouselContainer'

import { Link as RouterLink } from 'react-router-dom'

export default function Header() {
  return (
    <Wrapper
      id='home'
      className='flexSpaceCenter'
      style={{ position: 'relative' }}
    >
      {/* <div style={{ position: 'absolute', inset: 0 }}>
        <ParticleBackground />
      </div> */}

      <Image>
        <img
          src={Hero}
          alt='hero background'
          style={{ backgroundColor: '#fff' }}
        />
      </Image>

      <div className='container' style={{ zIndex: 1 }}>
        <Advertising className='flexSpaceCenter'>
          <LeftSide>
            <div>
              <AnimationText>
                <TypeAnimation
                  speed={50}
                  sequence={[
                    '#Travel Risk Mitigation',
                    9000,
                    '#Assistance Management',
                    9000,
                    '#Seamless Integration',
                    9000,
                  ]}
                  repeat={Infinity}
                />
              </AnimationText>
              <HeaderP className='font35'>
                <TypeAnimation
                  speed={100}
                  sequence={[
                    `AssistXGateway mitigates travel risks by monitoring news sources, providing real-time travel advisories and country reports, and facilitating emergency preparedness with document and medical history management.`,
                    9500,
                    `AssistXGateway streamlines assistance management for global companies with automated workflows, reserve and invoice management, and case management.`,
                    10000,
                    `AssistXGateway's API system enables easy integration with existing third-party systems and custom solutions, ensuring a seamless fit into customers' existing infrastructure.`,
                    9500,
                  ]}
                  repeat={Infinity}
                />
              </HeaderP>
              {/* AssistXGateway is health & security risk management platform. Our
              platform provides you and your employees information to prepare
              and prevent from health and security risks worldwide. We provide
              you with 24/7 comprehensive support & response wherever you are.
              Travel the world with peace of mind. */}
              {/* </HeaderP> */}
              {/* <BtnWrapper>
              <RouterLink
                activeClass='active'
                className='radius6 NabBtn'
                style={{ padding: '10px 15px', color: '#fff' }}
                to='/#contact'
              >
                Get Started
              </RouterLink>
            </BtnWrapper> */}
            </div>
          </LeftSide>
          {/*           <AddRight>
            <AddRightInner>
              <div>
                <AddImgWrapp1>
                  <CarouselContainer />
                </AddImgWrapp1>
              </div>
            </AddRightInner>
          </AddRight> */}
        </Advertising>
      </div>
    </Wrapper>
  )
}

const Image = styled.section`
  position: absolute;
  inset: 0;
  zindex: 0;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: 960px) {
    top: 70px;
  }
  @media (max-width: 660px) {
    top: 70px;
  }
`
const AnimationText = styled.section`
  color: #fff;
  font-size: 3.75rem;
  line-height: 4.5rem;
  font-weight: 800;
  @media (max-width: 960px) {
    font-size: 2.75rem;
    line-height: 3.5rem;
    font-weight: 800px;
  }
  @media (max-width: 560px) {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 800;
  }
`
const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 100vh;
  background-color: #f2ebed;
    url('../../assets/img/bg2.jpg');
  back @media (max-width: 960px) {
    flex-direction: column;
  }
`
const Advertising = styled.div`
  padding: 80px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 50px 0 30px 0;
    margin: 80px 0 0px 0;
  }
`
const LeftSide = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`
const HeaderP = styled.div`
  max-width: 620px;
  padding: 15px 0 50px 0;
  line-height: 1.8rem;
  color: #fff;
  font-size: 1.25rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
    font-size: 0.9rem;
    font-weight: 400;
  }
  @media (max-width: 560px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
  }
`
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`

const AddRight = styled.div`
  width: 100%;
  float: right;
  @media (max-width: 860px) {
    width: 80%;
  }
`
const AddRightInner = styled.div`
  width: 100%;
`
const AddImgWrapp1 = styled.div`
  width: 80%;
  margin: 0 6% 10px 6%;
  float: right;

  img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  @media (max-width: 860px) {
    width: 80%;
    float: center;
    order: 2;
  }
`
