import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { DashboardScreen } from '../screens/Dashboard/DashboardScreen'
import { UserListScreen } from '../screens/Admin/User/UserListScreen'
import { UserDetailScreen } from '../screens/Admin/User/UserDetailScreen'
import { UserEditScreen } from '../screens/Admin/User/UserEditScreen'

import { BlogListScreen } from '../screens/Admin/Blog/BlogListScreen'
import { BlogCreateScreen } from '../screens/Admin/Blog/BlogCreateScreen'
import { BlogDetailScreen } from '../screens/Admin/Blog/BlogDetailScreen'
import { BlogEditScreen } from '../screens/Admin/Blog/BlogEditScreen'

import SidebarLayout from '../layouts/SidebarLayout'
import AdminSidebar from '../components/Sidebar/AdminSidebar'
import UserCreateScreen from '../screens/Admin/User/UserCreateScreen'
import AuthenticatedRoute from './middleware/AuthenticatedRoute'

export const AdminRouter = () => {
  return (
    <Routes>
      <Route element={<AuthenticatedRoute />}>
        <Route element={<SidebarLayout sidebar={AdminSidebar} />}>
          <Route path='/dashboard' element={<DashboardScreen />} exact />

          <Route path='/user/list' element={<UserListScreen />} exact />
          <Route path='/user/create' element={<UserCreateScreen />} exact />
          <Route path='/user/:id/detail' element={<UserDetailScreen />} exact />
          <Route path='/user/:id/edit' element={<UserEditScreen />} exact />

          <Route path='/blog/list' element={<BlogListScreen />} exact />
          <Route path='/blog/create' element={<BlogCreateScreen />} exact />
          <Route path='/blog/:id/detail' element={<BlogDetailScreen />} exact />
          <Route path='/blog/:id/edit' element={<BlogEditScreen />} exact />
        </Route>{' '}
      </Route>
    </Routes>
  )
}
