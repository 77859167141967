import React from 'react'
import styled from 'styled-components'
import PlatformBox from '../Elements/PlatformBox'

export const PlateForm = () => {
  return (
    <Wrapper id='system'>
      <div className='whiteBg' style={{ padding: '80px 0px' }}>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>Our Systems</h1>
            <p className='font13'>
              AssistXGateway is built with advanced technology to provide
              real-time global risk monitoring, streamline assistance
              management, and enable seamless integration with existing systems.
              Its capabilities help businesses and assistance companies around
              the world to manage risks, increase efficiency, and deliver better
              customer experiences.
            </p>
          </HeaderInfo>
          <PlatFormBoxRow className='flex'>
            <PlatFormBoxWrapper>
              <PlatformBox
                icon='staffing'
                title='Alerts System'
                subtitle='Free travel risk management system for businesses to fulfill their duty of care obligations.'
                url='https://alerts.assistxgateway.com/'
              />
            </PlatFormBoxWrapper>
            <PlatFormBoxWrapper>
              <PlatformBox
                icon='software'
                title='Client System'
                subtitle='Premium travel risk management system for businesses to protect their employees.'
                url='https://client.assistxgateway.com/'
              />
            </PlatFormBoxWrapper>
            <PlatFormBoxWrapper>
              <PlatformBox
                icon='implement'
                title='Provider System'
                subtitle='Premium case management system for assistance companies to manage complex cases and provider network.'
                url='https://provider.medxgateway.com/'
              />
            </PlatFormBoxWrapper>
            <PlatFormBoxWrapper>
              <PlatformBox
                icon='staffing'
                title='API System'
                subtitle='Premium data exchange system for integrating and building other apps on our ecosystem.'
                url='https://api.assistxgateway.com/'
              />
            </PlatFormBoxWrapper>
          </PlatFormBoxRow>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
`
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    width: 100%;
  }
`
const PlatFormBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
  padding: 20px 0px 0px 0px;
`
const PlatFormBoxWrapper = styled.div`
  width: 33%;
  margin: 2rem 0rem;
  margin-right: 0.5%;
  padding: 40px 20px;
  border: 1px solid #dde1e6;
  border-radius: 5px;
  background-color: #fff;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background-color: #f2f4f8;
  }
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`
