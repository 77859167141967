import React from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import BlogForm from './blogComponents/BlogForm'
import WithHeader from '../../../layouts/WithHeader'

import {
  useGetBlogDetailQuery,
  useUpdateBlogMutation,
} from '../../../rtkStore/services/blogApi'

export const BlogEditScreen = () => {
  let { id } = useParams()

  let navigate = useNavigate()

  const breadcrumbs = [
    {
      title: 'Blogs',
      path: '/admin/blog/list',
    },
    {
      title: 'Edit Blog',
    },
  ]

  // RTK Query
  const {
    data: blogDetail,
    isLoading: loadingBlogDetail,
    error,
  } = useGetBlogDetailQuery(id)

  const [updateBlog, { isLoading: updatingBlog }] = useUpdateBlogMutation()

  // method
  const handleBlogUpdate = (data) => {
    updateBlog(data)
      .unwrap()
      .then(() => {
        navigate(breadcrumbs[0].path)
        toast.success('Blog successfully updated!')
      })
      .catch(() => toast.error('Something went wrong!'))
  }

  return (
    <>
      <WithHeader
        breadcrumbs={breadcrumbs}
        isBusy={loadingBlogDetail || updatingBlog}
        error={error}
      >
        <BlogForm
          blogDetail={blogDetail}
          isBusy={loadingBlogDetail || updatingBlog}
          onSubmit={handleBlogUpdate}
        />
      </WithHeader>
    </>
  )
}
