import React from 'react'

import { useParams } from 'react-router-dom'

import WithHeader from '../../../layouts/WithHeader'
import { UserForm } from './userComponents/UserForm'

import { useDetailUserQuery } from '../../../rtkStore/services/userApi'

export const UserEditScreen = () => {
  const { id } = useParams()

  const breadcrumbs = [
    {
      title: 'Users',
      path: '/app/user/list',
    },
    { title: 'Edit User' },
  ]

  //   RTK Query
  const {
    data: userDetail,
    isLoading: loadingUserDetail,
    error,
  } = useDetailUserQuery(id)

  //   methods
  const handleAdminUpdate = (data) => {
    console.log(data)
  }

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingUserDetail}
      error={error}
    >
      <UserForm
        userDetail={userDetail}
        isBusy={loadingUserDetail}
        onSubmit={handleAdminUpdate}
      />
    </WithHeader>
  )
}
