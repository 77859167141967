import React from 'react'

import { Box, Typography } from '@mui/material'

export const InformationTile = ({
  title,
  subtitle,
  icon: Icon = null,
  children,
}) => {
  return (
    <Box display='flex' alignItems='start' gap={1.5}>
      {Icon && <Icon />}
      <Box className='w-full'>
        <Typography style={{ color: 'gray', fontSize: 12 }}>{title}</Typography>
        {children ? <>{children}</> : subtitle?.length ? subtitle : 'N/A'}
      </Box>
    </Box>
  )
}
