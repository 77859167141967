import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, IconButton } from '@mui/material'
import DataTable from 'react-data-table-component'
import ReactHtmlParser from 'html-react-parser'
import AddIcon from '@mui/icons-material/Add'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import ModeEditOutlineTwoToneIcon from '@mui/icons-material/ModeEditOutlineTwoTone'

import CustomLoader from '../../../components/shared/CustomLoader'
import TableFilter from '../../../components/shared/TableFilter'
import WithHeader from '../../../layouts/WithHeader'

import { useGetBlogListQuery } from '../../../rtkStore/services/blogApi'

const pageActions = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Link to='/admin/blog/create'>
        <Button startIcon={<AddIcon />} variant='contained'>
          Add Blog
        </Button>
      </Link>
    </div>
  )
}

const ExpandedRow = ({ data }) => (
  <Box
    style={{
      background: '#fafafa',
      border: '1px solid lightgray',
      borderTop: 'none',
      borderRadius: '0 0 .5rem .5rem',
      padding: '.25rem 1rem',
      marginBottom: '.25rem',
    }}
  >
    {ReactHtmlParser(data.content)}
  </Box>
)

export const BlogListScreen = () => {
  const [filterText, setFilterText] = React.useState('')
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false)

  const breadcrumbs = [
    {
      title: 'Blogs',
    },
  ]

  const subHeaderComponent = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }
    return (
      <TableFilter
        placeholder='Filter by title'
        value={filterText ? filterText : ''}
        onChange={(e) => setFilterText(e.target.value)}
        handleClear={handleClear}
      />
    )
  }, [filterText, resetPaginationToggle])

  const columns = [
    {
      name: 'Image',
      width: '8%',
      selector: (row) => (
        <a href={row.image} target='_blank' rel='noreferrer'>
          <img
            src={`${row.image}?w=248&h=248fit=crop&auto=format`}
            style={{
              height: 60,
              width: 60,
              borderRadius: '.25rem',
              border: '1px solid lightgray',
              objectFit: 'cover',
              marginTop: '.25rem',
            }}
            alt={row.title}
          />
        </a>
      ),
    },
    {
      name: 'Title',
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: 'Author',
      selector: (row) => row.author,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => <div> {row.status ? 'Active' : 'Inactive'} </div>,
      sortable: true,
    },
    {
      name: 'Featured',
      sortable: true,
      selector: (row) =>
        row.featured ? (
          <IconButton aria-label='Featured' style={{ color: 'green' }}>
            <DoneIcon />
          </IconButton>
        ) : (
          <IconButton aria-label='Not Featured' style={{ color: 'red' }}>
            <CloseIcon />
          </IconButton>
        ),
    },
    {
      name: 'Category',
      selector: (row) => row?.category ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Tags',
      selector: (row) => row.tags,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <Link to={`/admin/blog/${row._id}/detail`}>
            <IconButton aria-label='View Blog'>
              <InfoOutlined color='primary' />
            </IconButton>
          </Link>
          {/* {getAdmin.savedUser.role.permission.includes('Edit Blog') && ( */}
          <Link to={`/admin/blog/${row._id}/edit`}>
            <IconButton aria-label='Edit Blog'>
              <ModeEditOutlineTwoToneIcon />
            </IconButton>
          </Link>
          {/* )} */}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  // RTK Query
  const {
    data: blogList,
    isLoading: loadingBlogs,
    error,
  } = useGetBlogListQuery()

  // if (error) return <div>{JSON.parse(error)}</div>;
  return (
    <>
      {/* {getAdmin.savedUser.role.permission.includes('List Blog') ? ( */}
      <>
        <WithHeader
          breadcrumbs={breadcrumbs}
          hasActions
          headerActions={pageActions}
          error={
            error && (error?.data?.message ?? 'Something went wrong badly!')
          }
        >
          <DataTable
            columns={columns}
            data={
              blogList?.filter(
                (item) =>
                  item.title &&
                  item.title.toLowerCase().includes(filterText.toLowerCase())
              ) ?? []
            }
            progressPending={loadingBlogs}
            progressComponent={<CustomLoader />}
            persistTableHead={true}
            pagination
            subHeader
            subHeaderComponent={subHeaderComponent}
            expandableRows
            expandableRowsComponent={ExpandedRow}
          />
        </WithHeader>
      </>
      {/* ) : (
        <>
          <Container className='dashboard-container page-401'></Container>
        </>
      )} */}
    </>
  )
}
