import React from 'react'

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { UserForm } from './userComponents/UserForm'
import WithHeader from '../../../layouts/WithHeader'

import { useAddUserMutation } from '../../../rtkStore/services/userApi'

const UserCreateScreen = () => {
  const navigate = useNavigate()

  const breadcrumbs = [
    {
      title: 'Users',
      path: '/admin/user/list',
    },
    { title: 'Add User' },
  ]

  // RTK Query
  const [createAdmin, { isLoading: creatingAdmin, error }] =
    useAddUserMutation()

  // methods
  const handleAdminCreate = (data) => {
    createAdmin(data)
      .unwrap()
      .then(() => {
        navigate(breadcrumbs[0].path)
        toast.success('Admin successfully created!')
      })
      .catch(() => toast.error('Admin successfully created!'))
  }

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={creatingAdmin}
      error={error && (error?.data?.message ?? 'Something went wrong!')}
    >
      <UserForm isBusy={creatingAdmin} onSubmit={handleAdminCreate} />
    </WithHeader>
  )
}

export default UserCreateScreen
