import React from 'react'
import styled from 'styled-components'
import { Link as NewLink } from 'react-router-dom'
import { Link } from 'react-scroll'
// import Pagination from '@mui/material/Pagination'
// import Stack from '@mui/material/Stack'

export const NewsListScreen = () => {
  return (
    <Wrapper id='news'>
      <BannerSection>
        <HeaderInfo>
          <h1 className='font40 extraBold'>Newsroom</h1>
          <p className='font18 white'>
            Your one-stop shop for everything AssistXGateway.
          </p>
          <ButtonsRow className='flexCenter' style={{ margin: '30px 0' }}>
            <div style={{ width: 'auto' }}>
              <BannerSectionButton className='animate pointer radius8'>
                Download Media Kit
              </BannerSectionButton>
            </div>
            <div style={{ width: 'auto', marginLeft: '15px' }}>
              <BannerSectionButton className='animate pointer radius8' border>
                Make a Media Inquiry
              </BannerSectionButton>
            </div>
          </ButtonsRow>
        </HeaderInfo>
      </BannerSection>
      <WhiteBg>
        <div className='container' style={{ padding: '20px 10px' }}>
          <DivWrapper className='row'>
            <Sidebar className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <CategoryList>
                <Link
                  activeClass='active'
                  style={{ padding: '10px 0px' }}
                  to='featureNews'
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <ListItem>Featured News</ListItem>
                </Link>
              </CategoryList>
              <CategoryList>
                <Link
                  activeClass='active'
                  style={{
                    padding: '10px 0px',
                  }}
                  to='pressReleases'
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <ListItem>Press Releases</ListItem>
                </Link>
              </CategoryList>
              <CategoryList>
                <Link
                  activeClass='active'
                  style={{ padding: '10px 0px' }}
                  to='inTheNews'
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <ListItem>In the News</ListItem>
                </Link>
              </CategoryList>
              <CategoryList>
                <Link
                  activeClass='active'
                  style={{ padding: '10px 0px' }}
                  to='mediaKit'
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <ListItem>Media Kit</ListItem>
                </Link>
              </CategoryList>
            </Sidebar>
            <Main className='col-xs-12 col-sm-8 col-md-8 col-lg-8'>
              <NewLink to='/news/detail'>
                <LongBox>
                  <StoryInfo id='featureNews'>
                    <p>
                      JUNE 14, 2022{' '}
                      <span
                        style={{
                          backgroundColor: '#002984',
                          color: '#fff',
                          padding: '5px',
                          marginLeft: '10px',
                          borderRadius: '5px',
                        }}
                      >
                        FEATURE
                      </span>
                    </p>
                    <h4>
                      AMI and The Can Brand to form an AI-based technology joint
                      venture, AssistXGateway
                    </h4>
                    <h5> Read Article</h5>
                  </StoryInfo>
                </LongBox>
              </NewLink>
              <hr></hr>
              <NewsCategory id='pressReleases'>
                <h2>Press Releases</h2>
                {/* <NewsList>
                  <p>
                    JUNE 14, 2022{' '}
                    <span
                      style={{
                        backgroundColor: '#3F51BF',
                        color: '#fff',
                        padding: '5px',
                        marginLeft: '10px',
                        borderRadius: '5px',
                      }}
                    >
                      HIRES
                    </span>
                  </p>
                  <NewLink to='/news/detail'>
                    <LinkUrl>
                      <h5>
                        AssistXGateway Hires John McCauley as CFO to Round Out C-Suite
                      </h5>
                    </LinkUrl>
                  </NewLink>
                </NewsList>

                <NewsList>
                  <p>
                    MAY 11, 2022
                    <span
                      style={{
                        backgroundColor: '#3F51BF',
                        color: '#fff',
                        padding: '5px',
                        marginLeft: '10px',
                        borderRadius: '5px',
                      }}
                    >
                      {' '}
                      PRODUCT
                    </span>
                  </p>
                  <NewLink to='/news/detail'>
                    <LinkUrl>
                      <h5>
                        Introducing AssistXGateway Routing Forms, a step Towards
                        Omnichannel Scheduling
                      </h5>
                    </LinkUrl>
                  </NewLink>
                </NewsList>

                <Stack spacing={2} style={{ paddingTop: '30px' }}>
                  <Pagination
                    count={10}
                    variant='outlined'
                    color='primary'
                    shape='rounded'
                  />
                </Stack> */}
                <h3> No content available.</h3>
              </NewsCategory>
              <hr></hr>
              <NewsCategory id='inTheNews'>
                <h2>In the News</h2>
                {/* <NewsList>
                  <p>SEPTEMBER 06, 2022</p>
                  <NewLink to='/news/detail'>
                    <LinkUrl>
                      <h5>
                        AssistXGateway Overload ? How to Manage Your Schedule With
                        Calendly's AI Assistant
                      </h5>
                    </LinkUrl>
                  </NewLink>
                </NewsList>
                <NewsList>
                  <p>SEPTEMBER 11, 2022</p>
                  <NewLink to='/news/detail'>
                    <LinkUrl>
                      <h5>
                        Best Appointment Scheduling Software for Small
                        Organizations
                      </h5>
                    </LinkUrl>
                  </NewLink>
                </NewsList>
                <Stack spacing={2} style={{ paddingTop: '30px' }}>
                  <Pagination
                    count={10}
                    variant='outlined'
                    color='primary'
                    shape='rounded'
                  />
                </Stack> */}
                <h3> No content available.</h3>
              </NewsCategory>
              <hr></hr>
              <NewsCategory id='mediaKit'>
                <h2>Media Kit</h2>
                {/* <NewsList>
                  <p>SEPTEMBER 06, 2022</p>
                  <NewLink to='/news/detail'>
                    <LinkUrl>
                      <h5>
                        AssistXGateway Overload ? How to Manage Your Schedule With
                        Calendly's AI Assistant
                      </h5>
                    </LinkUrl>
                  </NewLink>
                </NewsList>
                <NewsList>
                  <p>SEPTEMBER 11, 2022</p>
                  <NewLink to='/news/detail'>
                    <LinkUrl>
                      <h5>
                        Best Appointment Scheduling Software for Small
                        Organizations
                      </h5>
                    </LinkUrl>
                  </NewLink>
                </NewsList>
                <Stack spacing={2} style={{ paddingTop: '30px' }}>
                  <Pagination
                    count={10}
                    variant='outlined'
                    color='primary'
                    shape='rounded'
                  />
                </Stack> */}
                <h3> No content available.</h3>
              </NewsCategory>
            </Main>
          </DivWrapper>
        </div>
      </WhiteBg>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
  padding: 100px 0px 80px 0px;
  position: relative;
`
const WhiteBg = styled.section`
  padding: 80px 20px;
  background-color: #fff;
  @media (max-width: 860px) {
    padding: 20px 20px;
  }
`
const BannerSection = styled.section`
  padding: 70px 0px 40px 0px;
  background-color: #3f51bf;
  text-align: center;
  color: #fff;
`

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    padding: 0px 10px;
  }
`
const StoryInfo = styled.div`
  color: #000;
  > p {
    font-size: 16px;
    padding: 1.5rem 0;
    font-weight: 500;
  }
  > h4 {
    font-size: 24px;
  }
  > h5 {
    padding: 1.5rem 0;
    font-size: 18px;
    color: #006bff;
  }
`
const LongBox = styled.div`
  min-height: 100px;
  background-color: #f2f4f8;
  padding: 1.2rem;
  border-radius: 5px;
  margin-bottom: 40px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  :hover {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
      rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }
`
const CategoryList = styled.section`
  width: 80%;
  min-height: 20px;
  :hover {
    background-color: #f2f4f8;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 5px;
  }
`
const ListItem = styled.section`
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  // color: #000;
`
const NewsCategory = styled.section`
  padding: 2rem 0;

  > h2 {
    font-size: 32px;
    color: #000;
  }
`
// const NewsList = styled.div`
//   padding-top: 40px;
//   > p {
//     font-size: 16px;
//     line-height: 32px;
//     font-weight: 600;
//     color: gray;
//   }
// `
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`
const BannerSectionButton = styled.button`
  border: 1px solid ${(props) => (props.border ? '#fff' : '#002984')};
  background-color: ${(props) => (props.border ? 'transparent' : '#002984')};
  width: 100%;
  padding: 15px;
  outline: none;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.8px;
  color: ${(props) => (props.border ? '#fff' : '#fff')};
  :hover {
    background-color: ${(props) => (props.border ? '#fff' : '#3f51bf')};
    border: 1px solid #fff;
    color: ${(props) => (props.border ? '#002984' : '#fff')};
  }
  @media (max-width: 860px) {
    padding: 10px;
    outline: none;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 0.6px;
  }
`
// const LinkUrl = styled.div`
//   font-size: 18px;
//   line-height: 38px;
//   color: #006bff;
// `
const DivWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`
const Sidebar = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
  background-color: #fff;
  height: 250px;
  z-index: 899;
`
const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
`
