import React from 'react'
import styled from 'styled-components'
import PricingCard from '../../components/Elements/PricingCard'

export const PricingScreen = () => {
  const freeFeature = [
    '1 users',
    '10 downloads per day',
    'Daily content updates',
  ]
  const goldFeature = [
    '5 users',
    'Unlimited downloads',
    'Daily content updates',
    'Fully-editable files',
    'Browse with no ads',
  ]
  const diamondFeature = [
    '10 users',
    'Unlimited downloads',
    'Daily content updates',
    'Fully-editable files',
    'Browse with no ads',
    '24 hrs support',
  ]
  return (
    <Wrapper>
      <div className='lightBg' style={{ padding: '80px 0px' }}>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>Pricing</h1>
            <p className='font13'>
              We offer travel risk management systems, case management systems,{' '}
            </p>
          </HeaderInfo>
          <PricingCardRow className='flex'>
            <PricingCardWrapper>
              <PricingCard
                icon='software'
                type='Free'
                price='$0'
                features={freeFeature}
                url='https://client.assistxgateway.com/'
              />
            </PricingCardWrapper>
            <PricingCardWrapper>
              <PricingCard
                icon='implement'
                type='Gold'
                price='$0'
                features={goldFeature}
                url='https://provider.medxgateway.com/'
              />
            </PricingCardWrapper>
            <PricingCardWrapper>
              <PricingCard
                icon='staffing'
                type='Diamond'
                price='$0'
                features={diamondFeature}
                url='https://alerts.assistxgateway.com/'
              />
            </PricingCardWrapper>
          </PricingCardRow>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  padding-top: 80px;
`
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    width: 100%;
  }
`
const PricingCardRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
  padding: 20px 0px 80px 0px;
`
const PricingCardWrapper = styled.div`
  width: 33%;
  margin: 2rem 0rem;
  margin-right: 0.5%;
  padding: 40px 20px;
  border: 1px solid #dde1e6;
  border-radius: 5px;
  background-color: #fff;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background-color: #f2f4f8;
  }
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`
