import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Box, Button, Grid } from '@mui/material'
import { EditOutlined } from '@mui/icons-material'
import ReactHtmlParser from 'html-react-parser'

import { InformationTile } from '../../../components/shared/InformationTile'
import { GridSkeleton } from '../../../components/shared/GridSkeleton'

import { useGetBlogDetailQuery } from '../../../rtkStore/services/blogApi'

export const BlogDetailScreen = () => {
  const { id } = useParams()

  //   RTK Query
  const {
    data: blogDetail,
    isLoading: loadingBlogDetail,
    error,
  } = useGetBlogDetailQuery(id)

  const PageActions = () => (
    <Link to={`/app/blog/${id}/edit`}>
      <Button variant='contained' startIcon={<EditOutlined />}>
        Edit
      </Button>
    </Link>
  )

  return (
    <>
      {!loadingBlogDetail ? (
        <Box padding={3}>
          <Grid container spacing={4}>
            <Grid item md={7.5}>
              <img
                src={blogDetail?.image}
                alt='Blog Cover'
                style={{
                  height: 410,
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: '.25rem',
                  border: '1px solid lightgray',
                }}
              />
            </Grid>
            <Grid item md={4}>
              <Box display='flex' flexDirection='column' gap={3}>
                <InformationTile title='Title' subtitle={blogDetail?.title} />

                <InformationTile title='Author' subtitle={blogDetail?.author} />

                <InformationTile
                  title='Category'
                  subtitle={blogDetail?.category?.name}
                />

                <InformationTile title='Status'>
                  <div
                    className={`uppercase ${
                      blogDetail?.isActive ? 'text-green-600' : 'text-red-600'
                    }`}
                  >
                    {blogDetail?.isActive ? 'Active' : 'Inactive'}
                  </div>
                </InformationTile>

                <InformationTile
                  title='Is Featured?'
                  subtitle={blogDetail?.isFeatured ? 'Yes' : 'No'}
                />

                <InformationTile title='Tags' subtitle={blogDetail?.tags} />
              </Box>
            </Grid>
          </Grid>
          <Box style={{ color: 'gray' }}>
            {ReactHtmlParser(blogDetail?.content)}
          </Box>
        </Box>
      ) : (
        <GridSkeleton />
      )}
    </>
  )
}
