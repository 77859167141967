import React from 'react'

import styled from 'styled-components'
import { Link as NewLink } from 'react-router-dom'
import { Link } from 'react-scroll'
// import Pagination from '@mui/material/Pagination'
// import Stack from '@mui/material/Stack'
// import ProjectImg1 from '../../assets/img/projects/1.1.jpg'
// import ProjectImg2 from '../../assets/img/projects/2.2.jpg'
// import ProjectImg3 from '../../assets/img/projects/3.3.jpg'
// import ResourcesCard from '../../components/Elements/ResourcesCard'

import image from '../../assets/img/assistX.png'

export const ResourcesScreen = () => {
  return (
    <Wrapper>
      <div className='lightBg'>
        <div className='container'>
          <Advertising className='flexSpaceCenter'>
            <AddLeft>
              <h4 className='font15 semiBold'>RESOURCES</h4>
              <h2 className='font60 extraBold'>Explore all of our Resources</h2>
              <p className='font13'>
                Explore AssistXGateway's resources and contents, e-books,
                webinars, customer stories, tutorials, and other helpful
                information.
              </p>
            </AddLeft>
            <AddRight>
              <StoriesBoxWrapper>
                <NewLink to='/resources/detail'>
                  <img
                    src={image}
                    alt='hero background'
                    style={{
                      height: '199.2px',
                      width: '100%',
                      borderRadius: '5px 5px 0px 0px',
                      // objectFit: 'cover',
                      overflow: 'hidden',
                    }}
                  />
                  <TextWrapper className=''>
                    <h4 className='font14 semiBold'>FEATURE</h4>
                    <h3 className='font20Bold'>
                      Technology solution that works for you
                    </h3>
                    <h4>
                      Read now <i className='fas fa-angle-double-right'></i>
                    </h4>
                  </TextWrapper>
                </NewLink>
              </StoriesBoxWrapper>
            </AddRight>
          </Advertising>
        </div>
      </div>
      <div className='whiteBg' style={{ padding: '60px 0px 30px 0px' }}>
        <div className='container'>
          <h4 className='font18 semiBold darkColor'>Jump to a Section:</h4>
          <UlWrapper className='flexNullCenter'>
            <li className='semiBold font15 pointer'>
              <Link
                activeClass='active'
                style={{
                  padding: '22px 15px',
                  marginRight: '5px',
                  backgroundColor: '#f2f4f8',
                  borderRadius: '5px',
                }}
                to='eBook'
                spy={true}
                smooth={true}
                offset={-80}
              >
                E-book & Guides
              </Link>
            </li>
            <li className='semiBold font15 pointer'>
              <Link
                activeClass='active'
                style={{
                  padding: '22px 15px',
                  marginRight: '5px',
                  backgroundColor: '#f2f4f8',
                  borderRadius: '5px',
                }}
                to='blog2'
                spy={true}
                smooth={true}
                offset={-80}
              >
                Blog
              </Link>
            </li>
            <li className='semiBold font15 pointer'>
              <Link
                activeClass='active'
                style={{
                  padding: '22px 15px',
                  marginRight: '5px',
                  backgroundColor: '#f2f4f8',
                  borderRadius: '5px',
                }}
                to='customerStories'
                spy={true}
                smooth={true}
                offset={-80}
              >
                Customer Stories
              </Link>
            </li>
          </UlWrapper>
        </div>
      </div>
      <div id='eBook' style={{ padding: '0px 0px 60px 0px' }}>
        <div className='containerCard'>
          <div className='row' style={{ padding: '20px 0px' }}>
            <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8'>
              <h2 className='lightBlack'>E-books & Guides</h2>
            </div>

            {/* <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 pagination'>
              <Stack spacing={2}>
                <Pagination
                  count={10}
                  variant='outlined'
                  color='primary'
                  shape='rounded'
                />
              </Stack>
            </div> */}
          </div>
          {/* <div className='row'>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <ResourcesCard
                img={image}
                title='FEATURE'
                text=' Scheduling automation for sales, marketing, and customer success teams 111'
              />
            </div>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <ResourcesCard
                img={ProjectImg2}
                title='FEATURE'
                text=' Scheduling automation for sales, marketing, and customer success
            teams'
              />
            </div>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <ResourcesCard
                img={ProjectImg3}
                title='FEATURE'
                text=' Scheduling automation for sales, marketing, and customer success
            teams'
              />
            </div>
          </div> */}
          <h3> Content not available</h3>
        </div>
      </div>

      <div
        className='whiteBg'
        id='blog2'
        style={{ padding: '0px 0px 100px 0px' }}
      >
        <div className='containerCard'>
          <div className='row' style={{ padding: '20px 0px' }}>
            <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8'>
              <h2 className='lightBlack'>Blog</h2>
            </div>

            {/* <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 pagination'>
              <Stack spacing={2}>
                <Pagination
                  count={10}
                  variant='outlined'
                  color='primary'
                  shape='rounded'
                />
              </Stack>
            </div> */}
          </div>
          {/* <div className='row'>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <ResourcesCard
                img={ProjectImg3}
                title='FEATURE'
                text=' Scheduling automation for sales, marketing, and customer success
            teams'
              />
            </div>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <ResourcesCard
                img={image}
                title='FEATURE'
                text=' Scheduling automation for sales, marketing, and customer success
            teams'
              />
            </div>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <ResourcesCard
                img={ProjectImg1}
                title='FEATURE'
                text=' Scheduling automation for sales, marketing, and customer success
            teams'
              />
            </div>
          </div> */}
          <h3> Content not available</h3>
        </div>
      </div>

      <div id='customerStories' style={{ padding: '0px 0px 100px 0px' }}>
        <div className='containerCard'>
          <div className='row' style={{ padding: '20px 0px' }}>
            <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8'>
              <h2 className='lightBlack'>Customer Stories</h2>
            </div>

            {/* <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 pagination'>
              <Stack spacing={2}>
                <Pagination
                  count={10}
                  variant='outlined'
                  color='primary'
                  shape='rounded'
                />
              </Stack>
            </div> */}
          </div>
          {/* <div className='row'>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <ResourcesCard
                img={ProjectImg2}
                title='FEATURE'
                text=' Scheduling automation for sales, marketing, and customer success
            teams'
              />
            </div>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <ResourcesCard
                img={ProjectImg3}
                title='FEATURE'
                text=' Scheduling automation for sales, marketing, and customer success
            teams'
              />
            </div>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <ResourcesCard
                img={image}
                title='FEATURE'
                text=' Scheduling automation for sales, marketing, and customer success
            teams'
              />
            </div>
          </div> */}
          <h3> Content not available</h3>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  padding: 90px 0;
`
const Advertising = styled.div`
  padding: 90px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 30px 0;
  }
`
const AddLeft = styled.div`
  width: 46%;
  p {
    max-width: 480px;
    padding-top: 2rem;
    font-size: 18px;
  }
  @media (max-width: 860px) {
    width: 100%;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
  > h4 {
    padding-bottom: 20px;
  }
`
const AddRight = styled.div`
  width: 50%;
  float: right;
  @media (max-width: 860px) {
    width: 100%;
    padding-top: 20px;
  }
`
const StoriesBoxWrapper = styled.div`
  width: 60%;
  float: right;
  min-height: 350px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    // background-color: #f2f4f8;
  }

  @media (max-width: 860px) {
    width: 100%;
  }
`
const TextWrapper = styled.div`
  text-align: left;
  padding: 1.2rem;
  margin-bottom: 0.5rem;
  > h3 {
    padding: 1.2rem 0rem 1.5rem 0rem;
    line-height: 1.7rem;
  }
  > h4 {
    font-size: 1rem;
    color: #002984;
  }
`
const UlWrapper = styled.ul`
  padding: 40px 0px;
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`
