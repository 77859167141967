import React from 'react'
// Sections
import Header from '../../components/Sections/Header'
import Services from '../../components/Sections/Services'
import News from '../../components/Sections/News'
import { Resources } from '../../components/Sections/Resources'
import Contact from '../../components/Sections/Contact'
import ClientCarousel from '../../components/Sections/ClientCarousel'
import CarouselContainer from '../../components/Sections/CarouselContainer'

import System from '../../components/Sections/System'
import AboutUs from '../../components/Sections/AboutUs'
import { PlateForm } from '../../components/Sections/PlateForm'
import ExploreAlert from '../../components/Sections/ExploreAlert'
import ExploreProvider from '../../components/Sections/ExploreProvider'
import NumberCounter from '../../components/Sections/NumberCounter'
import { WhomDoWeServe } from '../../components/Sections/WhomDoWeServe'
import ExploreCountries from '../../components/Sections/ExploreCountries'
import ExploreApi from '../../components/Sections/ExploreApi'

export default function Landing() {
  return (
    <>
      <Header />
      {/* <AboutUs /> */}
      {/* <Services /> */}
      {/* <CarouselContainer /> */}
      <NumberCounter />
      <WhomDoWeServe />
      <PlateForm />
      {/* <System /> */}
      <ExploreCountries />
      <ExploreProvider />
      {/* <ExploreAlert /> */}

      <ExploreApi />
      <Resources />
      <News />
      {/* <ClientCarousel /> */}
      <Contact />
    </>
  )
}
