import React from 'react'

import { useRef } from 'react'
import { CircularProgress } from '@mui/material'
import axios from 'axios'

const UploadImage = ({ imageUrl, onUploadSuccess = () => {} }) => {
  const ref = useRef()

  const [uploading, setUploading] = React.useState(false)
  const [previewImageUrl, setPreviewImageUrl] = React.useState(null)

  const uploadToCloudinary = async (e) => {
    setUploading(true)

    const formData = new FormData()
    formData.append('file', e.target.files[0])
    formData.append('upload_preset', 'firstsource')

    axios
      .post(
        'https://api.cloudinary.com/v1_1/firstsourceworld-com/image/upload',
        formData
      )
      .then((res) => {
        if (!res.data) return

        setPreviewImageUrl(res.data.url)

        onUploadSuccess(res.data.url)
        //   if (res.data.width > 512 && res.data.height > 512) {
        //     setLogo(res.data.url);
        //   } else {
        //     setLogo(
        //       res.data.url.split("upload")[0] +
        //         `/upload/b_white,c_pad,h_512,w_512` +
        //         res.data.url.split("upload")[1]
        //     );
        //   }
      })
      .catch((err) => console.log(err.response.data))
      .finally(() => setUploading(false))
  }

  React.useState(() => {
    if (imageUrl) setPreviewImageUrl(imageUrl)
  }, [imageUrl])

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        paddingTop: '100%',
        border: '1px solid gray',
        borderRadius: '.5rem',
        borderStyle: 'dashed',
        overflow: 'hidden',
      }}
    >
      {uploading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            inset: 0,
          }}
        >
          <div
            style={{
              position: 'absolute',
              inset: 0,
              //   background: "#000",
              //   opacity: 0.1,
            }}
          ></div>
          <CircularProgress color='primary' size={40} />
        </div>
      ) : (
        <div style={{ height: '100%', width: '100%' }}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: `translate(-50%, -50%)`,
              zIndex: 1,
              padding: '.5rem 1rem',
              borderRadius: '.25rem',
              cursor: 'pointer',
              background: '#000',
              opacity: 0.8,
              color: 'white',
              fontSize: 12,
            }}
            onClick={() => {
              ref.current.click()
            }}
          >
            {previewImageUrl ? 'Change' : 'Upload'}
          </div>
          {previewImageUrl && (
            <img
              src={previewImageUrl}
              alt='Uploaded'
              style={{
                position: 'absolute',
                inset: 0,
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
          )}
          <input
            ref={ref}
            accept='image/*'
            style={{ display: 'none' }}
            id='image-file'
            multiple
            type='file'
            onChange={uploadToCloudinary}
          />
        </div>
      )}
    </div>
  )
}

export default UploadImage
