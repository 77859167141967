import React from 'react'

import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Box, Button, Grid, TextField } from '@mui/material'
import Select from 'react-select'

export const UserForm = ({
  userDetail = null,
  isBusy = false,
  onSubmit = () => {},
}) => {
  const navigate = useNavigate()

  // react form hook
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  })

  return (
    <>
      <Box padding={3}>
        <Grid container spacing={4}>
          <Grid item md={4}>
            <TextField
              {...register('name', {
                required: 'Name is required.',
                maxLength: { value: 100, message: 'Max 100 characters.' },
              })}
              fullWidth
              label='Name'
              type='text'
              size='small'
              placeholder='Enter user name'
              InputLabelProps={{ shrink: true }}
            />
            {errors.name && <p className='error'>{errors.name.message}</p>}
          </Grid>
          <Grid item md={6} />
          <Grid item md={4}>
            <TextField
              {...register('email', {
                required: 'Email is required.',
                maxLength: { value: 100, message: 'Max 100 characters.' },
              })}
              fullWidth
              label='Email'
              type='text'
              size='small'
              placeholder='Enter user email'
              InputLabelProps={{ shrink: true }}
            />
            {errors.email && <p className='error'>{errors.email.message}</p>}
          </Grid>
          <Grid item md={6} />
          <Grid item md={4}>
            <TextField
              {...register('password', {
                required: 'Password is required.',
              })}
              fullWidth
              label='Password'
              type='password'
              size='small'
              placeholder='Enter password'
              InputLabelProps={{ shrink: true }}
            />
            {errors.password && (
              <p className='error'>{errors.password.message}</p>
            )}
          </Grid>
          <Grid item md={6} />
          <Grid item md={4}>
            <TextField
              {...register('confirmPassword', {
                required: 'Please confirm password.',
              })}
              fullWidth
              label='Confirm Password'
              type='password'
              size='small'
              placeholder='Confirm password'
              InputLabelProps={{ shrink: true }}
            />
            {errors.password && (
              <p className='error'>{errors.password.message}</p>
            )}
          </Grid>
          <Grid item md={6} />
          <Grid item md={6} />{' '}
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          background: '#fafafa',
        }}
      >
        <Button disabled={isBusy} onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button
          variant='contained'
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {userDetail ? 'Update' : 'Add'}
        </Button>
      </Box>
    </>
  )
}
