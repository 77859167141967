import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

import { selectLoggedInUser } from '../../rtkStore/authSlice'

const AuthenticatedRoute = () => {
  const loggedInUser = useSelector(selectLoggedInUser)

  if (!loggedInUser) return <Navigate to='/admin-login' />

  return <Outlet />
}

export default AuthenticatedRoute
