import React from "react";

import Lottie from "lottie-react";

import airplaneAnimation from "../../assets/lottie-airplane.json";

const CustomLoader = () => {
  return <Lottie animationData={airplaneAnimation} style={{ height: 400 }} />;
};

export default CustomLoader;
