import React from 'react'
import ReactDOM from 'react-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './style/flexboxgrid.min.css'
import './style/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { store as rtkStore } from './rtkStore'
import { Provider } from 'react-redux'

ReactDOM.render(
  <>
    <BrowserRouter>
      <Provider store={rtkStore}>
        <App />
      </Provider>
    </BrowserRouter>
  </>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
