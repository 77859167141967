//will be imported

export const countryList = [
  {
    name: 'Dominican Republic',
    cca2: 'DO',
    cca3: 'DOM',
    idd: '1+809',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/do.png',
      svg: 'https://flagcdn.com/do.svg',
    },
  },
  {
    name: 'Heard Island and McDonald Islands',
    cca2: 'HM',
    cca3: 'HMD',
    idd: 'NONE',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/hm.png',
      svg: 'https://flagcdn.com/hm.svg',
    },
  },
  {
    name: 'Ghana',
    cca2: 'GH',
    cca3: 'GHA',
    idd: '233',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gh.png',
      svg: 'https://flagcdn.com/gh.svg',
    },
  },
  {
    name: 'Italy',
    cca2: 'IT',
    cca3: 'ITA',
    idd: '39',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/it.png',
      svg: 'https://flagcdn.com/it.svg',
    },
  },
  {
    name: 'Cambodia',
    cca2: 'KH',
    cca3: 'KHM',
    idd: '855',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/kh.png',
      svg: 'https://flagcdn.com/kh.svg',
    },
  },
  {
    name: 'Bahrain',
    cca2: 'BH',
    cca3: 'BHR',
    idd: '973',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bh.png',
      svg: 'https://flagcdn.com/bh.svg',
    },
  },
  {
    name: 'Israel',
    cca2: 'IL',
    cca3: 'ISR',
    idd: '972',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/il.png',
      svg: 'https://flagcdn.com/il.svg',
    },
  },
  {
    name: 'Qatar',
    cca2: 'QA',
    cca3: 'QAT',
    idd: '974',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/qa.png',
      svg: 'https://flagcdn.com/qa.svg',
    },
  },
  {
    name: 'Turks and Caicos Islands',
    cca2: 'TC',
    cca3: 'TCA',
    idd: '1+649',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/tc.png',
      svg: 'https://flagcdn.com/tc.svg',
    },
  },
  {
    name: 'Romania',
    cca2: 'RO',
    cca3: 'ROU',
    idd: '40',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ro.png',
      svg: 'https://flagcdn.com/ro.svg',
    },
  },
  {
    name: 'Madagascar',
    cca2: 'MG',
    cca3: 'MDG',
    idd: '261',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mg.png',
      svg: 'https://flagcdn.com/mg.svg',
    },
  },
  {
    name: 'Guadeloupe',
    cca2: 'GP',
    cca3: 'GLP',
    idd: '590',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gp.png',
      svg: 'https://flagcdn.com/gp.svg',
    },
  },
  {
    name: 'Latvia',
    cca2: 'LV',
    cca3: 'LVA',
    idd: '371',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/lv.png',
      svg: 'https://flagcdn.com/lv.svg',
    },
  },
  {
    name: 'Nepal',
    cca2: 'NP',
    cca3: 'NPL',
    idd: '977',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/np.png',
      svg: 'https://flagcdn.com/np.svg',
    },
  },
  {
    name: 'Kosovo',
    cca2: 'XK',
    cca3: 'UNK',
    idd: '381',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/xk.png',
      svg: 'https://flagcdn.com/xk.svg',
    },
  },
  {
    name: 'Jersey',
    cca2: 'JE',
    cca3: 'JEY',
    idd: '44',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/je.png',
      svg: 'https://flagcdn.com/je.svg',
    },
  },
  {
    name: 'Ukraine',
    cca2: 'UA',
    cca3: 'UKR',
    idd: '380',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ua.png',
      svg: 'https://flagcdn.com/ua.svg',
    },
  },
  {
    name: 'Greenland',
    cca2: 'GL',
    cca3: 'GRL',
    idd: '299',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gl.png',
      svg: 'https://flagcdn.com/gl.svg',
    },
  },
  {
    name: 'Mauritania',
    cca2: 'MR',
    cca3: 'MRT',
    idd: '222',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mr.png',
      svg: 'https://flagcdn.com/mr.svg',
    },
  },
  {
    name: 'Denmark',
    cca2: 'DK',
    cca3: 'DNK',
    idd: '45',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/dk.png',
      svg: 'https://flagcdn.com/dk.svg',
    },
  },
  {
    name: 'Libya',
    cca2: 'LY',
    cca3: 'LBY',
    idd: '218',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ly.png',
      svg: 'https://flagcdn.com/ly.svg',
    },
  },
  {
    name: 'Turkmenistan',
    cca2: 'TM',
    cca3: 'TKM',
    idd: '993',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/tm.png',
      svg: 'https://flagcdn.com/tm.svg',
    },
  },
  {
    name: 'Angola',
    cca2: 'AO',
    cca3: 'AGO',
    idd: '244',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ao.png',
      svg: 'https://flagcdn.com/ao.svg',
    },
  },
  {
    name: 'Martinique',
    cca2: 'MQ',
    cca3: 'MTQ',
    idd: '596',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mq.png',
      svg: 'https://flagcdn.com/mq.svg',
    },
  },
  {
    name: 'Guam',
    cca2: 'GU',
    cca3: 'GUM',
    idd: '1+671',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gu.png',
      svg: 'https://flagcdn.com/gu.svg',
    },
  },
  {
    name: 'South Georgia',
    cca2: 'GS',
    cca3: 'SGS',
    idd: '500',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gs.png',
      svg: 'https://flagcdn.com/gs.svg',
    },
  },
  {
    name: 'Armenia',
    cca2: 'AM',
    cca3: 'ARM',
    idd: '374',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/am.png',
      svg: 'https://flagcdn.com/am.svg',
    },
  },
  {
    name: 'Thailand',
    cca2: 'TH',
    cca3: 'THA',
    idd: '66',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/th.png',
      svg: 'https://flagcdn.com/th.svg',
    },
  },
  {
    name: 'Azerbaijan',
    cca2: 'AZ',
    cca3: 'AZE',
    idd: '994',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/az.png',
      svg: 'https://flagcdn.com/az.svg',
    },
  },
  {
    name: 'Guinea',
    cca2: 'GN',
    cca3: 'GIN',
    idd: '224',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gn.png',
      svg: 'https://flagcdn.com/gn.svg',
    },
  },
  {
    name: 'Cayman Islands',
    cca2: 'KY',
    cca3: 'CYM',
    idd: '1+345',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ky.png',
      svg: 'https://flagcdn.com/ky.svg',
    },
  },
  {
    name: 'Slovakia',
    cca2: 'SK',
    cca3: 'SVK',
    idd: '421',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sk.png',
      svg: 'https://flagcdn.com/sk.svg',
    },
  },
  {
    name: 'Saint Lucia',
    cca2: 'LC',
    cca3: 'LCA',
    idd: '1+758',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/lc.png',
      svg: 'https://flagcdn.com/lc.svg',
    },
  },
  {
    name: 'Réunion',
    cca2: 'RE',
    cca3: 'REU',
    idd: '262',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/re.png',
      svg: 'https://flagcdn.com/re.svg',
    },
  },
  {
    name: 'Sierra Leone',
    cca2: 'SL',
    cca3: 'SLE',
    idd: '232',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sl.png',
      svg: 'https://flagcdn.com/sl.svg',
    },
  },
  {
    name: 'China',
    cca2: 'CN',
    cca3: 'CHN',
    idd: '86',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cn.png',
      svg: 'https://flagcdn.com/cn.svg',
    },
  },
  {
    name: 'Georgia',
    cca2: 'GE',
    cca3: 'GEO',
    idd: '995',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ge.png',
      svg: 'https://flagcdn.com/ge.svg',
    },
  },
  {
    name: 'Japan',
    cca2: 'JP',
    cca3: 'JPN',
    idd: '81',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/jp.png',
      svg: 'https://flagcdn.com/jp.svg',
    },
  },
  {
    name: 'Falkland Islands',
    cca2: 'FK',
    cca3: 'FLK',
    idd: '500',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/fk.png',
      svg: 'https://flagcdn.com/fk.svg',
    },
  },
  {
    name: 'Eritrea',
    cca2: 'ER',
    cca3: 'ERI',
    idd: '291',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/er.png',
      svg: 'https://flagcdn.com/er.svg',
    },
  },
  {
    name: 'Finland',
    cca2: 'FI',
    cca3: 'FIN',
    idd: '358',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/fi.png',
      svg: 'https://flagcdn.com/fi.svg',
    },
  },
  {
    name: 'Kiribati',
    cca2: 'KI',
    cca3: 'KIR',
    idd: '686',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ki.png',
      svg: 'https://flagcdn.com/ki.svg',
    },
  },
  {
    name: 'Argentina',
    cca2: 'AR',
    cca3: 'ARG',
    idd: '54',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ar.png',
      svg: 'https://flagcdn.com/ar.svg',
    },
  },
  {
    name: 'Philippines',
    cca2: 'PH',
    cca3: 'PHL',
    idd: '63',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ph.png',
      svg: 'https://flagcdn.com/ph.svg',
    },
  },
  {
    name: 'Brunei',
    cca2: 'BN',
    cca3: 'BRN',
    idd: '673',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bn.png',
      svg: 'https://flagcdn.com/bn.svg',
    },
  },
  {
    name: 'Ivory Coast',
    cca2: 'CI',
    cca3: 'CIV',
    idd: '225',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ci.png',
      svg: 'https://flagcdn.com/ci.svg',
    },
  },
  {
    name: 'United Kingdom',
    cca2: 'GB',
    cca3: 'GBR',
    idd: '44',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gb.png',
      svg: 'https://flagcdn.com/gb.svg',
    },
  },
  {
    name: 'Central African Republic',
    cca2: 'CF',
    cca3: 'CAF',
    idd: '236',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cf.png',
      svg: 'https://flagcdn.com/cf.svg',
    },
  },
  {
    name: 'Kyrgyzstan',
    cca2: 'KG',
    cca3: 'KGZ',
    idd: '996',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/kg.png',
      svg: 'https://flagcdn.com/kg.svg',
    },
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    cca2: 'SH',
    cca3: 'SHN',
    idd: '290',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sh.png',
      svg: 'https://flagcdn.com/sh.svg',
    },
  },
  {
    name: 'Namibia',
    cca2: 'NA',
    cca3: 'NAM',
    idd: '264',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/na.png',
      svg: 'https://flagcdn.com/na.svg',
    },
  },
  {
    name: 'Gabon',
    cca2: 'GA',
    cca3: 'GAB',
    idd: '241',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ga.png',
      svg: 'https://flagcdn.com/ga.svg',
    },
  },
  {
    name: 'Kenya',
    cca2: 'KE',
    cca3: 'KEN',
    idd: '254',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ke.png',
      svg: 'https://flagcdn.com/ke.svg',
    },
  },
  {
    name: 'Gibraltar',
    cca2: 'GI',
    cca3: 'GIB',
    idd: '350',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gi.png',
      svg: 'https://flagcdn.com/gi.svg',
    },
  },
  {
    name: 'Kazakhstan',
    cca2: 'KZ',
    cca3: 'KAZ',
    idd: '7',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/kz.png',
      svg: 'https://flagcdn.com/kz.svg',
    },
  },
  {
    name: 'South Korea',
    cca2: 'KR',
    cca3: 'KOR',
    idd: '82',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/kr.png',
      svg: 'https://flagcdn.com/kr.svg',
    },
  },
  {
    name: 'Pakistan',
    cca2: 'PK',
    cca3: 'PAK',
    idd: '92',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/pk.png',
      svg: 'https://flagcdn.com/pk.svg',
    },
  },
  {
    name: 'Somalia',
    cca2: 'SO',
    cca3: 'SOM',
    idd: '252',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/so.png',
      svg: 'https://flagcdn.com/so.svg',
    },
  },
  {
    name: 'Zambia',
    cca2: 'ZM',
    cca3: 'ZMB',
    idd: '260',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/zm.png',
      svg: 'https://flagcdn.com/zm.svg',
    },
  },
  {
    name: 'Malawi',
    cca2: 'MW',
    cca3: 'MWI',
    idd: '265',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mw.png',
      svg: 'https://flagcdn.com/mw.svg',
    },
  },
  {
    name: 'South Sudan',
    cca2: 'SS',
    cca3: 'SSD',
    idd: '211',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ss.png',
      svg: 'https://flagcdn.com/ss.svg',
    },
  },
  {
    name: 'Morocco',
    cca2: 'MA',
    cca3: 'MAR',
    idd: '212',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ma.png',
      svg: 'https://flagcdn.com/ma.svg',
    },
  },
  {
    name: 'Tuvalu',
    cca2: 'TV',
    cca3: 'TUV',
    idd: '688',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/tv.png',
      svg: 'https://flagcdn.com/tv.svg',
    },
  },
  {
    name: 'Nigeria',
    cca2: 'NG',
    cca3: 'NGA',
    idd: '234',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ng.png',
      svg: 'https://flagcdn.com/ng.svg',
    },
  },
  {
    name: 'Grenada',
    cca2: 'GD',
    cca3: 'GRD',
    idd: '1+473',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gd.png',
      svg: 'https://flagcdn.com/gd.svg',
    },
  },
  {
    name: 'Montenegro',
    cca2: 'ME',
    cca3: 'MNE',
    idd: '382',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/me.png',
      svg: 'https://flagcdn.com/me.svg',
    },
  },
  {
    name: 'Ethiopia',
    cca2: 'ET',
    cca3: 'ETH',
    idd: '251',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/et.png',
      svg: 'https://flagcdn.com/et.svg',
    },
  },
  {
    name: 'Spain',
    cca2: 'ES',
    cca3: 'ESP',
    idd: '34',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/es.png',
      svg: 'https://flagcdn.com/es.svg',
    },
  },
  {
    name: 'Benin',
    cca2: 'BJ',
    cca3: 'BEN',
    idd: '229',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bj.png',
      svg: 'https://flagcdn.com/bj.svg',
    },
  },
  {
    name: 'Canada',
    cca2: 'CA',
    cca3: 'CAN',
    idd: '1',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ca.png',
      svg: 'https://flagcdn.com/ca.svg',
    },
  },
  {
    name: 'Cape Verde',
    cca2: 'CV',
    cca3: 'CPV',
    idd: '238',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cv.png',
      svg: 'https://flagcdn.com/cv.svg',
    },
  },
  {
    name: 'Algeria',
    cca2: 'DZ',
    cca3: 'DZA',
    idd: '213',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/dz.png',
      svg: 'https://flagcdn.com/dz.svg',
    },
  },
  {
    name: 'Åland Islands',
    cca2: 'AX',
    cca3: 'ALA',
    idd: '358',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ax.png',
      svg: 'https://flagcdn.com/ax.svg',
    },
  },
  {
    name: 'Peru',
    cca2: 'PE',
    cca3: 'PER',
    idd: '51',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/pe.png',
      svg: 'https://flagcdn.com/pe.svg',
    },
  },
  {
    name: 'Poland',
    cca2: 'PL',
    cca3: 'POL',
    idd: '48',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/pl.png',
      svg: 'https://flagcdn.com/pl.svg',
    },
  },
  {
    name: 'Vanuatu',
    cca2: 'VU',
    cca3: 'VUT',
    idd: '678',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/vu.png',
      svg: 'https://flagcdn.com/vu.svg',
    },
  },
  {
    name: 'Equatorial Guinea',
    cca2: 'GQ',
    cca3: 'GNQ',
    idd: '240',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gq.png',
      svg: 'https://flagcdn.com/gq.svg',
    },
  },
  {
    name: 'Bulgaria',
    cca2: 'BG',
    cca3: 'BGR',
    idd: '359',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bg.png',
      svg: 'https://flagcdn.com/bg.svg',
    },
  },
  {
    name: 'French Guiana',
    cca2: 'GF',
    cca3: 'GUF',
    idd: '594',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gf.png',
      svg: 'https://flagcdn.com/gf.svg',
    },
  },
  {
    name: 'Niger',
    cca2: 'NE',
    cca3: 'NER',
    idd: '227',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ne.png',
      svg: 'https://flagcdn.com/ne.svg',
    },
  },
  {
    name: 'Isle of Man',
    cca2: 'IM',
    cca3: 'IMN',
    idd: '44',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/im.png',
      svg: 'https://flagcdn.com/im.svg',
    },
  },
  {
    name: 'Palau',
    cca2: 'PW',
    cca3: 'PLW',
    idd: '680',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/pw.png',
      svg: 'https://flagcdn.com/pw.svg',
    },
  },
  {
    name: 'Tanzania',
    cca2: 'TZ',
    cca3: 'TZA',
    idd: '255',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/tz.png',
      svg: 'https://flagcdn.com/tz.svg',
    },
  },
  {
    name: 'Norway',
    cca2: 'NO',
    cca3: 'NOR',
    idd: '47',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/no.png',
      svg: 'https://flagcdn.com/no.svg',
    },
  },
  {
    name: 'Caribbean Netherlands',
    cca2: 'BQ',
    cca3: 'BES',
    idd: '599',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bq.png',
      svg: 'https://flagcdn.com/bq.svg',
    },
  },
  {
    name: 'Guernsey',
    cca2: 'GG',
    cca3: 'GGY',
    idd: '44',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gg.png',
      svg: 'https://flagcdn.com/gg.svg',
    },
  },
  {
    name: 'Uzbekistan',
    cca2: 'UZ',
    cca3: 'UZB',
    idd: '998',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/uz.png',
      svg: 'https://flagcdn.com/uz.svg',
    },
  },
  {
    name: 'Svalbard and Jan Mayen',
    cca2: 'SJ',
    cca3: 'SJM',
    idd: '47',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sj.png',
      svg: 'https://flagcdn.com/sj.svg',
    },
  },
  {
    name: 'Turkey',
    cca2: 'TR',
    cca3: 'TUR',
    idd: '90',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/tr.png',
      svg: 'https://flagcdn.com/tr.svg',
    },
  },
  {
    name: 'Fiji',
    cca2: 'FJ',
    cca3: 'FJI',
    idd: '679',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/fj.png',
      svg: 'https://flagcdn.com/fj.svg',
    },
  },
  {
    name: 'Belarus',
    cca2: 'BY',
    cca3: 'BLR',
    idd: '375',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/by.png',
      svg: 'https://flagcdn.com/by.svg',
    },
  },
  {
    name: 'Guatemala',
    cca2: 'GT',
    cca3: 'GTM',
    idd: '502',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gt.png',
      svg: 'https://flagcdn.com/gt.svg',
    },
  },
  {
    name: 'Uruguay',
    cca2: 'UY',
    cca3: 'URY',
    idd: '598',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/uy.png',
      svg: 'https://flagcdn.com/uy.svg',
    },
  },
  {
    name: 'Cook Islands',
    cca2: 'CK',
    cca3: 'COK',
    idd: '682',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ck.png',
      svg: 'https://flagcdn.com/ck.svg',
    },
  },
  {
    name: 'Taiwan',
    cca2: 'TW',
    cca3: 'TWN',
    idd: '886',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/tw.png',
      svg: 'https://flagcdn.com/tw.svg',
    },
  },
  {
    name: 'Lesotho',
    cca2: 'LS',
    cca3: 'LSO',
    idd: '266',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ls.png',
      svg: 'https://flagcdn.com/ls.svg',
    },
  },
  {
    name: 'Timor-Leste',
    cca2: 'TL',
    cca3: 'TLS',
    idd: '670',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/tl.png',
      svg: 'https://flagcdn.com/tl.svg',
    },
  },
  {
    name: 'Venezuela',
    cca2: 'VE',
    cca3: 'VEN',
    idd: '58',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ve.png',
      svg: 'https://flagcdn.com/ve.svg',
    },
  },
  {
    name: 'Sri Lanka',
    cca2: 'LK',
    cca3: 'LKA',
    idd: '94',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/lk.png',
      svg: 'https://flagcdn.com/lk.svg',
    },
  },
  {
    name: 'Liberia',
    cca2: 'LR',
    cca3: 'LBR',
    idd: '231',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/lr.png',
      svg: 'https://flagcdn.com/lr.svg',
    },
  },
  {
    name: 'New Zealand',
    cca2: 'NZ',
    cca3: 'NZL',
    idd: '64',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/nz.png',
      svg: 'https://flagcdn.com/nz.svg',
    },
  },
  {
    name: 'Tokelau',
    cca2: 'TK',
    cca3: 'TKL',
    idd: '690',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/tk.png',
      svg: 'https://flagcdn.com/tk.svg',
    },
  },
  {
    name: 'United States Virgin Islands',
    cca2: 'VI',
    cca3: 'VIR',
    idd: null,
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/vi.png',
      svg: 'https://flagcdn.com/vi.svg',
    },
  },
  {
    name: 'Cuba',
    cca2: 'CU',
    cca3: 'CUB',
    idd: '53',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cu.png',
      svg: 'https://flagcdn.com/cu.svg',
    },
  },
  {
    name: 'Chad',
    cca2: 'TD',
    cca3: 'TCD',
    idd: '235',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/td.png',
      svg: 'https://flagcdn.com/td.svg',
    },
  },
  {
    name: 'Comoros',
    cca2: 'KM',
    cca3: 'COM',
    idd: '269',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/km.png',
      svg: 'https://flagcdn.com/km.svg',
    },
  },
  {
    name: 'Kuwait',
    cca2: 'KW',
    cca3: 'KWT',
    idd: '965',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/kw.png',
      svg: 'https://flagcdn.com/kw.svg',
    },
  },
  {
    name: 'Saint Vincent and the Grenadines',
    cca2: 'VC',
    cca3: 'VCT',
    idd: '1+784',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/vc.png',
      svg: 'https://flagcdn.com/vc.svg',
    },
  },
  {
    name: 'Bermuda',
    cca2: 'BM',
    cca3: 'BMU',
    idd: '1+441',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bm.png',
      svg: 'https://flagcdn.com/bm.svg',
    },
  },
  {
    name: 'Palestine',
    cca2: 'PS',
    cca3: 'PSE',
    idd: '970',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ps.png',
      svg: 'https://flagcdn.com/ps.svg',
    },
  },
  {
    name: 'Vatican City',
    cca2: 'VA',
    cca3: 'VAT',
    idd: '39',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/va.png',
      svg: 'https://flagcdn.com/va.svg',
    },
  },
  {
    name: 'Mali',
    cca2: 'ML',
    cca3: 'MLI',
    idd: '223',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ml.png',
      svg: 'https://flagcdn.com/ml.svg',
    },
  },
  {
    name: 'Papua New Guinea',
    cca2: 'PG',
    cca3: 'PNG',
    idd: '675',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/pg.png',
      svg: 'https://flagcdn.com/pg.svg',
    },
  },
  {
    name: 'Eswatini',
    cca2: 'SZ',
    cca3: 'SWZ',
    idd: '268',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sz.png',
      svg: 'https://flagcdn.com/sz.svg',
    },
  },
  {
    name: 'Vietnam',
    cca2: 'VN',
    cca3: 'VNM',
    idd: '84',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/vn.png',
      svg: 'https://flagcdn.com/vn.svg',
    },
  },
  {
    name: 'Mongolia',
    cca2: 'MN',
    cca3: 'MNG',
    idd: '976',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mn.png',
      svg: 'https://flagcdn.com/mn.svg',
    },
  },
  {
    name: 'United States',
    cca2: 'US',
    cca3: 'USA',
    idd: '1',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/us.png',
      svg: 'https://flagcdn.com/us.svg',
    },
  },
  {
    name: 'Yemen',
    cca2: 'YE',
    cca3: 'YEM',
    idd: '967',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ye.png',
      svg: 'https://flagcdn.com/ye.svg',
    },
  },
  {
    name: 'Macau',
    cca2: 'MO',
    cca3: 'MAC',
    idd: '853',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mo.png',
      svg: 'https://flagcdn.com/mo.svg',
    },
  },
  {
    name: 'Bahamas',
    cca2: 'BS',
    cca3: 'BHS',
    idd: '1+242',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bs.png',
      svg: 'https://flagcdn.com/bs.svg',
    },
  },
  {
    name: 'Saudi Arabia',
    cca2: 'SA',
    cca3: 'SAU',
    idd: '966',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sa.png',
      svg: 'https://flagcdn.com/sa.svg',
    },
  },
  {
    name: 'Cyprus',
    cca2: 'CY',
    cca3: 'CYP',
    idd: '357',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cy.png',
      svg: 'https://flagcdn.com/cy.svg',
    },
  },
  {
    name: 'Laos',
    cca2: 'LA',
    cca3: 'LAO',
    idd: '856',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/la.png',
      svg: 'https://flagcdn.com/la.svg',
    },
  },
  {
    name: 'Puerto Rico',
    cca2: 'PR',
    cca3: 'PRI',
    idd: '1+939',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/pr.png',
      svg: 'https://flagcdn.com/pr.svg',
    },
  },
  {
    name: 'Estonia',
    cca2: 'EE',
    cca3: 'EST',
    idd: '372',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ee.png',
      svg: 'https://flagcdn.com/ee.svg',
    },
  },
  {
    name: 'Mayotte',
    cca2: 'YT',
    cca3: 'MYT',
    idd: '262',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/yt.png',
      svg: 'https://flagcdn.com/yt.svg',
    },
  },
  {
    name: 'Myanmar',
    cca2: 'MM',
    cca3: 'MMR',
    idd: '95',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mm.png',
      svg: 'https://flagcdn.com/mm.svg',
    },
  },
  {
    name: 'British Virgin Islands',
    cca2: 'VG',
    cca3: 'VGB',
    idd: '1+284',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/vg.png',
      svg: 'https://flagcdn.com/vg.svg',
    },
  },
  {
    name: 'Croatia',
    cca2: 'HR',
    cca3: 'HRV',
    idd: '385',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/hr.png',
      svg: 'https://flagcdn.com/hr.svg',
    },
  },
  {
    name: 'Belize',
    cca2: 'BZ',
    cca3: 'BLZ',
    idd: '501',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bz.png',
      svg: 'https://flagcdn.com/bz.svg',
    },
  },
  {
    name: 'North Macedonia',
    cca2: 'MK',
    cca3: 'MKD',
    idd: '389',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mk.png',
      svg: 'https://flagcdn.com/mk.svg',
    },
  },
  {
    name: 'Serbia',
    cca2: 'RS',
    cca3: 'SRB',
    idd: '381',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/rs.png',
      svg: 'https://flagcdn.com/rs.svg',
    },
  },
  {
    name: 'Solomon Islands',
    cca2: 'SB',
    cca3: 'SLB',
    idd: '677',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sb.png',
      svg: 'https://flagcdn.com/sb.svg',
    },
  },
  {
    name: 'Norfolk Island',
    cca2: 'NF',
    cca3: 'NFK',
    idd: '672',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/nf.png',
      svg: 'https://flagcdn.com/nf.svg',
    },
  },
  {
    name: 'Lebanon',
    cca2: 'LB',
    cca3: 'LBN',
    idd: '961',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/lb.png',
      svg: 'https://flagcdn.com/lb.svg',
    },
  },
  {
    name: 'Nauru',
    cca2: 'NR',
    cca3: 'NRU',
    idd: '674',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/nr.png',
      svg: 'https://flagcdn.com/nr.svg',
    },
  },
  {
    name: 'Ireland',
    cca2: 'IE',
    cca3: 'IRL',
    idd: '353',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ie.png',
      svg: 'https://flagcdn.com/ie.svg',
    },
  },
  {
    name: 'Iran',
    cca2: 'IR',
    cca3: 'IRN',
    idd: '98',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ir.png',
      svg: 'https://flagcdn.com/ir.svg',
    },
  },
  {
    name: 'Bosnia and Herzegovina',
    cca2: 'BA',
    cca3: 'BIH',
    idd: '387',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ba.png',
      svg: 'https://flagcdn.com/ba.svg',
    },
  },
  {
    name: 'Honduras',
    cca2: 'HN',
    cca3: 'HND',
    idd: '504',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/hn.png',
      svg: 'https://flagcdn.com/hn.svg',
    },
  },
  {
    name: 'Tajikistan',
    cca2: 'TJ',
    cca3: 'TJK',
    idd: '992',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/tj.png',
      svg: 'https://flagcdn.com/tj.svg',
    },
  },
  {
    name: 'Costa Rica',
    cca2: 'CR',
    cca3: 'CRI',
    idd: '506',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cr.png',
      svg: 'https://flagcdn.com/cr.svg',
    },
  },
  {
    name: 'France',
    cca2: 'FR',
    cca3: 'FRA',
    idd: '33',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/fr.png',
      svg: 'https://flagcdn.com/fr.svg',
    },
  },
  {
    name: 'Moldova',
    cca2: 'MD',
    cca3: 'MDA',
    idd: '373',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/md.png',
      svg: 'https://flagcdn.com/md.svg',
    },
  },
  {
    name: 'Switzerland',
    cca2: 'CH',
    cca3: 'CHE',
    idd: '41',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ch.png',
      svg: 'https://flagcdn.com/ch.svg',
    },
  },
  {
    name: 'Czechia',
    cca2: 'CZ',
    cca3: 'CZE',
    idd: '420',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cz.png',
      svg: 'https://flagcdn.com/cz.svg',
    },
  },
  {
    name: 'Brazil',
    cca2: 'BR',
    cca3: 'BRA',
    idd: '55',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/br.png',
      svg: 'https://flagcdn.com/br.svg',
    },
  },
  {
    name: 'Botswana',
    cca2: 'BW',
    cca3: 'BWA',
    idd: '267',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bw.png',
      svg: 'https://flagcdn.com/bw.svg',
    },
  },
  {
    name: 'Republic of the Congo',
    cca2: 'CG',
    cca3: 'COG',
    idd: '242',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cg.png',
      svg: 'https://flagcdn.com/cg.svg',
    },
  },
  {
    name: 'Burundi',
    cca2: 'BI',
    cca3: 'BDI',
    idd: '257',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bi.png',
      svg: 'https://flagcdn.com/bi.svg',
    },
  },
  {
    name: 'El Salvador',
    cca2: 'SV',
    cca3: 'SLV',
    idd: '503',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sv.png',
      svg: 'https://flagcdn.com/sv.svg',
    },
  },
  {
    name: 'Malta',
    cca2: 'MT',
    cca3: 'MLT',
    idd: '356',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mt.png',
      svg: 'https://flagcdn.com/mt.svg',
    },
  },
  {
    name: 'San Marino',
    cca2: 'SM',
    cca3: 'SMR',
    idd: '378',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sm.png',
      svg: 'https://flagcdn.com/sm.svg',
    },
  },
  {
    name: 'Guyana',
    cca2: 'GY',
    cca3: 'GUY',
    idd: '592',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gy.png',
      svg: 'https://flagcdn.com/gy.svg',
    },
  },
  {
    name: 'Saint Kitts and Nevis',
    cca2: 'KN',
    cca3: 'KNA',
    idd: '1+869',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/kn.png',
      svg: 'https://flagcdn.com/kn.svg',
    },
  },
  {
    name: 'Netherlands',
    cca2: 'NL',
    cca3: 'NLD',
    idd: '31',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/nl.png',
      svg: 'https://flagcdn.com/nl.svg',
    },
  },
  {
    name: 'Syria',
    cca2: 'SY',
    cca3: 'SYR',
    idd: '963',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sy.png',
      svg: 'https://flagcdn.com/sy.svg',
    },
  },
  {
    name: 'Burkina Faso',
    cca2: 'BF',
    cca3: 'BFA',
    idd: '226',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bf.png',
      svg: 'https://flagcdn.com/bf.svg',
    },
  },
  {
    name: 'Wallis and Futuna',
    cca2: 'WF',
    cca3: 'WLF',
    idd: '681',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/wf.png',
      svg: 'https://flagcdn.com/wf.svg',
    },
  },
  {
    name: 'Hungary',
    cca2: 'HU',
    cca3: 'HUN',
    idd: '36',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/hu.png',
      svg: 'https://flagcdn.com/hu.svg',
    },
  },
  {
    name: 'Samoa',
    cca2: 'WS',
    cca3: 'WSM',
    idd: '685',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ws.png',
      svg: 'https://flagcdn.com/ws.svg',
    },
  },
  {
    name: 'Tunisia',
    cca2: 'TN',
    cca3: 'TUN',
    idd: '216',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/tn.png',
      svg: 'https://flagcdn.com/tn.svg',
    },
  },
  {
    name: 'Jamaica',
    cca2: 'JM',
    cca3: 'JAM',
    idd: '1+876',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/jm.png',
      svg: 'https://flagcdn.com/jm.svg',
    },
  },
  {
    name: 'North Korea',
    cca2: 'KP',
    cca3: 'PRK',
    idd: '850',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/kp.png',
      svg: 'https://flagcdn.com/kp.svg',
    },
  },
  {
    name: 'American Samoa',
    cca2: 'AS',
    cca3: 'ASM',
    idd: '1+684',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/as.png',
      svg: 'https://flagcdn.com/as.svg',
    },
  },
  {
    name: 'Colombia',
    cca2: 'CO',
    cca3: 'COL',
    idd: '57',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/co.png',
      svg: 'https://flagcdn.com/co.svg',
    },
  },
  {
    name: 'Andorra',
    cca2: 'AD',
    cca3: 'AND',
    idd: '376',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ad.png',
      svg: 'https://flagcdn.com/ad.svg',
    },
  },
  {
    name: 'Guinea-Bissau',
    cca2: 'GW',
    cca3: 'GNB',
    idd: '245',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gw.png',
      svg: 'https://flagcdn.com/gw.svg',
    },
  },
  {
    name: 'Lithuania',
    cca2: 'LT',
    cca3: 'LTU',
    idd: '370',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/lt.png',
      svg: 'https://flagcdn.com/lt.svg',
    },
  },
  {
    name: 'Oman',
    cca2: 'OM',
    cca3: 'OMN',
    idd: '968',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/om.png',
      svg: 'https://flagcdn.com/om.svg',
    },
  },
  {
    name: 'Luxembourg',
    cca2: 'LU',
    cca3: 'LUX',
    idd: '352',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/lu.png',
      svg: 'https://flagcdn.com/lu.svg',
    },
  },
  {
    name: 'Mozambique',
    cca2: 'MZ',
    cca3: 'MOZ',
    idd: '258',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mz.png',
      svg: 'https://flagcdn.com/mz.svg',
    },
  },
  {
    name: 'Portugal',
    cca2: 'PT',
    cca3: 'PRT',
    idd: '351',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/pt.png',
      svg: 'https://flagcdn.com/pt.svg',
    },
  },
  {
    name: 'Bolivia',
    cca2: 'BO',
    cca3: 'BOL',
    idd: '591',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bo.png',
      svg: 'https://flagcdn.com/bo.svg',
    },
  },
  {
    name: 'Uganda',
    cca2: 'UG',
    cca3: 'UGA',
    idd: '256',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ug.png',
      svg: 'https://flagcdn.com/ug.svg',
    },
  },
  {
    name: 'Haiti',
    cca2: 'HT',
    cca3: 'HTI',
    idd: '509',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ht.png',
      svg: 'https://flagcdn.com/ht.svg',
    },
  },
  {
    name: 'São Tomé and Príncipe',
    cca2: 'ST',
    cca3: 'STP',
    idd: '239',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/st.png',
      svg: 'https://flagcdn.com/st.svg',
    },
  },
  {
    name: 'India',
    cca2: 'IN',
    cca3: 'IND',
    idd: '91',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/in.png',
      svg: 'https://flagcdn.com/in.svg',
    },
  },
  {
    name: 'New Caledonia',
    cca2: 'NC',
    cca3: 'NCL',
    idd: '687',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/nc.png',
      svg: 'https://flagcdn.com/nc.svg',
    },
  },
  {
    name: 'Paraguay',
    cca2: 'PY',
    cca3: 'PRY',
    idd: '595',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/py.png',
      svg: 'https://flagcdn.com/py.svg',
    },
  },
  {
    name: 'Sint Maarten',
    cca2: 'SX',
    cca3: 'SXM',
    idd: '1+721',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sx.png',
      svg: 'https://flagcdn.com/sx.svg',
    },
  },
  {
    name: 'Sudan',
    cca2: 'SD',
    cca3: 'SDN',
    idd: '249',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sd.png',
      svg: 'https://flagcdn.com/sd.svg',
    },
  },
  {
    name: 'Saint Pierre and Miquelon',
    cca2: 'PM',
    cca3: 'SPM',
    idd: '508',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/pm.png',
      svg: 'https://flagcdn.com/pm.svg',
    },
  },
  {
    name: 'Iceland',
    cca2: 'IS',
    cca3: 'ISL',
    idd: '354',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/is.png',
      svg: 'https://flagcdn.com/is.svg',
    },
  },
  {
    name: 'Djibouti',
    cca2: 'DJ',
    cca3: 'DJI',
    idd: '253',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/dj.png',
      svg: 'https://flagcdn.com/dj.svg',
    },
  },
  {
    name: 'Antigua and Barbuda',
    cca2: 'AG',
    cca3: 'ATG',
    idd: '1+268',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ag.png',
      svg: 'https://flagcdn.com/ag.svg',
    },
  },
  {
    name: 'United Arab Emirates',
    cca2: 'AE',
    cca3: 'ARE',
    idd: '971',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ae.png',
      svg: 'https://flagcdn.com/ae.svg',
    },
  },
  {
    name: 'Senegal',
    cca2: 'SN',
    cca3: 'SEN',
    idd: '221',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sn.png',
      svg: 'https://flagcdn.com/sn.svg',
    },
  },
  {
    name: 'Iraq',
    cca2: 'IQ',
    cca3: 'IRQ',
    idd: '964',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/iq.png',
      svg: 'https://flagcdn.com/iq.svg',
    },
  },
  {
    name: 'Zimbabwe',
    cca2: 'ZW',
    cca3: 'ZWE',
    idd: '263',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/zw.png',
      svg: 'https://flagcdn.com/zw.svg',
    },
  },
  {
    name: 'Mexico',
    cca2: 'MX',
    cca3: 'MEX',
    idd: '52',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mx.png',
      svg: 'https://flagcdn.com/mx.svg',
    },
  },
  {
    name: 'Pitcairn Islands',
    cca2: 'PN',
    cca3: 'PCN',
    idd: 'NONE',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/pn.png',
      svg: 'https://flagcdn.com/pn.svg',
    },
  },
  {
    name: 'Mauritius',
    cca2: 'MU',
    cca3: 'MUS',
    idd: '230',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mu.png',
      svg: 'https://flagcdn.com/mu.svg',
    },
  },
  {
    name: 'Russia',
    cca2: 'RU',
    cca3: 'RUS',
    idd: '7',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ru.png',
      svg: 'https://flagcdn.com/ru.svg',
    },
  },
  {
    name: 'Tonga',
    cca2: 'TO',
    cca3: 'TON',
    idd: '676',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/to.png',
      svg: 'https://flagcdn.com/to.svg',
    },
  },
  {
    name: 'Suriname',
    cca2: 'SR',
    cca3: 'SUR',
    idd: '597',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sr.png',
      svg: 'https://flagcdn.com/sr.svg',
    },
  },
  {
    name: 'Togo',
    cca2: 'TG',
    cca3: 'TGO',
    idd: '228',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/tg.png',
      svg: 'https://flagcdn.com/tg.svg',
    },
  },
  {
    name: 'Montserrat',
    cca2: 'MS',
    cca3: 'MSR',
    idd: '1+664',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ms.png',
      svg: 'https://flagcdn.com/ms.svg',
    },
  },
  {
    name: 'Monaco',
    cca2: 'MC',
    cca3: 'MCO',
    idd: '377',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mc.png',
      svg: 'https://flagcdn.com/mc.svg',
    },
  },
  {
    name: 'Ecuador',
    cca2: 'EC',
    cca3: 'ECU',
    idd: '593',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ec.png',
      svg: 'https://flagcdn.com/ec.svg',
    },
  },
  {
    name: 'Panama',
    cca2: 'PA',
    cca3: 'PAN',
    idd: '507',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/pa.png',
      svg: 'https://flagcdn.com/pa.svg',
    },
  },
  {
    name: 'Rwanda',
    cca2: 'RW',
    cca3: 'RWA',
    idd: '250',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/rw.png',
      svg: 'https://flagcdn.com/rw.svg',
    },
  },
  {
    name: 'Trinidad and Tobago',
    cca2: 'TT',
    cca3: 'TTO',
    idd: '1+868',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/tt.png',
      svg: 'https://flagcdn.com/tt.svg',
    },
  },
  {
    name: 'Malaysia',
    cca2: 'MY',
    cca3: 'MYS',
    idd: '60',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/my.png',
      svg: 'https://flagcdn.com/my.svg',
    },
  },
  {
    name: 'Sweden',
    cca2: 'SE',
    cca3: 'SWE',
    idd: '46',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/se.png',
      svg: 'https://flagcdn.com/se.svg',
    },
  },
  {
    name: 'Saint Martin',
    cca2: 'MF',
    cca3: 'MAF',
    idd: '590',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mf.png',
      svg: 'https://flagcdn.com/mf.svg',
    },
  },
  {
    name: 'Albania',
    cca2: 'AL',
    cca3: 'ALB',
    idd: '355',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/al.png',
      svg: 'https://flagcdn.com/al.svg',
    },
  },
  {
    name: 'Bouvet Island',
    cca2: 'BV',
    cca3: 'BVT',
    idd: 'NONE',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bv.png',
      svg: 'https://flagcdn.com/bv.svg',
    },
  },
  {
    name: 'Germany',
    cca2: 'DE',
    cca3: 'DEU',
    idd: '49',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/de.png',
      svg: 'https://flagcdn.com/de.svg',
    },
  },
  {
    name: 'South Africa',
    cca2: 'ZA',
    cca3: 'ZAF',
    idd: '27',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/za.png',
      svg: 'https://flagcdn.com/za.svg',
    },
  },
  {
    name: 'Micronesia',
    cca2: 'FM',
    cca3: 'FSM',
    idd: '691',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/fm.png',
      svg: 'https://flagcdn.com/fm.svg',
    },
  },
  {
    name: 'Seychelles',
    cca2: 'SC',
    cca3: 'SYC',
    idd: '248',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sc.png',
      svg: 'https://flagcdn.com/sc.svg',
    },
  },
  {
    name: 'Greece',
    cca2: 'GR',
    cca3: 'GRC',
    idd: '30',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gr.png',
      svg: 'https://flagcdn.com/gr.svg',
    },
  },
  {
    name: 'Christmas Island',
    cca2: 'CX',
    cca3: 'CXR',
    idd: '61',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cx.png',
      svg: 'https://flagcdn.com/cx.svg',
    },
  },
  {
    name: 'Northern Mariana Islands',
    cca2: 'MP',
    cca3: 'MNP',
    idd: '1+670',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mp.png',
      svg: 'https://flagcdn.com/mp.svg',
    },
  },
  {
    name: 'Slovenia',
    cca2: 'SI',
    cca3: 'SVN',
    idd: '386',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/si.png',
      svg: 'https://flagcdn.com/si.svg',
    },
  },
  {
    name: 'Indonesia',
    cca2: 'ID',
    cca3: 'IDN',
    idd: '62',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/id.png',
      svg: 'https://flagcdn.com/id.svg',
    },
  },
  {
    name: 'Singapore',
    cca2: 'SG',
    cca3: 'SGP',
    idd: '65',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/sg.png',
      svg: 'https://flagcdn.com/sg.svg',
    },
  },
  {
    name: 'Bangladesh',
    cca2: 'BD',
    cca3: 'BGD',
    idd: '880',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bd.png',
      svg: 'https://flagcdn.com/bd.svg',
    },
  },
  {
    name: 'Egypt',
    cca2: 'EG',
    cca3: 'EGY',
    idd: '20',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/eg.png',
      svg: 'https://flagcdn.com/eg.svg',
    },
  },
  {
    name: 'Belgium',
    cca2: 'BE',
    cca3: 'BEL',
    idd: '32',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/be.png',
      svg: 'https://flagcdn.com/be.svg',
    },
  },
  {
    name: 'Liechtenstein',
    cca2: 'LI',
    cca3: 'LIE',
    idd: '423',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/li.png',
      svg: 'https://flagcdn.com/li.svg',
    },
  },
  {
    name: 'Marshall Islands',
    cca2: 'MH',
    cca3: 'MHL',
    idd: '692',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mh.png',
      svg: 'https://flagcdn.com/mh.svg',
    },
  },
  {
    name: 'Barbados',
    cca2: 'BB',
    cca3: 'BRB',
    idd: '1+246',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bb.png',
      svg: 'https://flagcdn.com/bb.svg',
    },
  },
  {
    name: 'Maldives',
    cca2: 'MV',
    cca3: 'MDV',
    idd: '960',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/mv.png',
      svg: 'https://flagcdn.com/mv.svg',
    },
  },
  {
    name: 'DR Congo',
    cca2: 'CD',
    cca3: 'COD',
    idd: '243',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cd.png',
      svg: 'https://flagcdn.com/cd.svg',
    },
  },
  {
    name: 'Australia',
    cca2: 'AU',
    cca3: 'AUS',
    idd: '61',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/au.png',
      svg: 'https://flagcdn.com/au.svg',
    },
  },
  {
    name: 'Austria',
    cca2: 'AT',
    cca3: 'AUT',
    idd: '43',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/at.png',
      svg: 'https://flagcdn.com/at.svg',
    },
  },
  {
    name: 'Cameroon',
    cca2: 'CM',
    cca3: 'CMR',
    idd: '237',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cm.png',
      svg: 'https://flagcdn.com/cm.svg',
    },
  },
  {
    name: 'French Southern and Antarctic Lands',
    cca2: 'TF',
    cca3: 'ATF',
    idd: null,
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/tf.png',
      svg: 'https://flagcdn.com/tf.svg',
    },
  },
  {
    name: 'Aruba',
    cca2: 'AW',
    cca3: 'ABW',
    idd: '297',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/aw.png',
      svg: 'https://flagcdn.com/aw.svg',
    },
  },
  {
    name: 'French Polynesia',
    cca2: 'PF',
    cca3: 'PYF',
    idd: '689',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/pf.png',
      svg: 'https://flagcdn.com/pf.svg',
    },
  },
  {
    name: 'Saint Barthélemy',
    cca2: 'BL',
    cca3: 'BLM',
    idd: '590',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bl.png',
      svg: 'https://flagcdn.com/bl.svg',
    },
  },
  {
    name: 'Gambia',
    cca2: 'GM',
    cca3: 'GMB',
    idd: '220',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/gm.png',
      svg: 'https://flagcdn.com/gm.svg',
    },
  },
  {
    name: 'Anguilla',
    cca2: 'AI',
    cca3: 'AIA',
    idd: '1+264',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ai.png',
      svg: 'https://flagcdn.com/ai.svg',
    },
  },
  {
    name: 'Curaçao',
    cca2: 'CW',
    cca3: 'CUW',
    idd: '599',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cw.png',
      svg: 'https://flagcdn.com/cw.svg',
    },
  },
  {
    name: 'Hong Kong',
    cca2: 'HK',
    cca3: 'HKG',
    idd: '852',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/hk.png',
      svg: 'https://flagcdn.com/hk.svg',
    },
  },
  {
    name: 'Bhutan',
    cca2: 'BT',
    cca3: 'BTN',
    idd: '975',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/bt.png',
      svg: 'https://flagcdn.com/bt.svg',
    },
  },
  {
    name: 'Nicaragua',
    cca2: 'NI',
    cca3: 'NIC',
    idd: '505',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/ni.png',
      svg: 'https://flagcdn.com/ni.svg',
    },
  },
  {
    name: 'Cocos (Keeling) Islands',
    cca2: 'CC',
    cca3: 'CCK',
    idd: '61',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cc.png',
      svg: 'https://flagcdn.com/cc.svg',
    },
  },
  {
    name: 'Chile',
    cca2: 'CL',
    cca3: 'CHL',
    idd: '56',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/cl.png',
      svg: 'https://flagcdn.com/cl.svg',
    },
  },
  {
    name: 'Niue',
    cca2: 'NU',
    cca3: 'NIU',
    idd: '683',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/nu.png',
      svg: 'https://flagcdn.com/nu.svg',
    },
  },
  {
    name: 'Jordan',
    cca2: 'JO',
    cca3: 'JOR',
    idd: '962',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/jo.png',
      svg: 'https://flagcdn.com/jo.svg',
    },
  },
  {
    name: 'Afghanistan',
    cca2: 'AF',
    cca3: 'AFG',
    idd: '93',
    status: 'Active',
    flagUrl: {
      png: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png',
      svg: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg',
    },
  },
  {
    name: 'Faroe Islands',
    cca2: 'FO',
    cca3: 'FRO',
    idd: '298',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/fo.png',
      svg: 'https://flagcdn.com/fo.svg',
    },
  },
  {
    name: 'Dominica',
    cca2: 'DM',
    cca3: 'DMA',
    idd: '1+767',
    status: 'Active',
    flagUrl: {
      png: 'https://flagcdn.com/w320/dm.png',
      svg: 'https://flagcdn.com/dm.svg',
    },
  },
]
