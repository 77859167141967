import React from 'react'
import styled from 'styled-components'

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className='animate pointer radius8'
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  )
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? '#002984' : '#002984')};
  background-color: ${(props) => (props.border ? 'transparent' : '#002984')};
  width: 100%;
  padding: 15px;
  outline: none;
  font-size: 1rem;
  letter-spacing: 0.8px;
  color: ${(props) => (props.border ? '#002984' : '#fff')};
  :hover {
    background-color: ${(props) => (props.border ? 'transparent' : '#3f51bf')};
    border: 1px solid #3f51bf;
    color: ${(props) => (props.border ? '#002984' : '#fff')};
  }
`
