import React from 'react'

import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined'
// import AdminPanelSettingsOutlined from "@mui/icons-material/AdminPanelSettingsOutlined";
import EmailOutlined from '@mui/icons-material/EmailOutlined'

import { InformationTile } from '../../../components/shared/InformationTile'
import { GridSkeleton } from '../../../components/shared/GridSkeleton'
import WithHeader from '../../../layouts/WithHeader'

import { useDetailUserQuery } from '../../../rtkStore/services/userApi'

export const UserDetailScreen = () => {
  const { id } = useParams()

  const breadcrumbs = [
    { title: 'Users', path: '/admin/user/list' },
    { title: 'User Detail' },
  ]

  // RTK Query
  const {
    data: adminDetail,
    isLoading: loadingUserDetail,
    error,
  } = useDetailUserQuery(id)

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingUserDetail}
      error={error}
    >
      {!loadingUserDetail ? (
        <Box display='flex' flexDirection='column' padding={3} gap={4}>
          <InformationTile
            icon={() => <AccountCircleOutlined color='primary' />}
            title='Name'
            subtitle={adminDetail?.name}
          />
          <InformationTile
            icon={() => <EmailOutlined color='primary' />}
            title='Email'
            subtitle={adminDetail?.email}
          />
          {/* <InformationTile
            icon={() => <AdminPanelSettingsOutlined color="primary" />}
            title="Role"
            subtitle={adminDetail?.role?.role}
          /> */}
        </Box>
      ) : (
        <GridSkeleton />
      )}
    </WithHeader>
  )
}
