import { baseApi } from './baseApi'

const USER_BASE_URL = '/users'

export const userApi = baseApi.injectEndpoints({
  tagTypes: ['User'],

  endpoints: (builder) => ({
    listUser: builder.query({
      query: () => `${USER_BASE_URL}/list`,
      providesTags: (result) =>
        result
          ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'User', id })),
              { type: 'User', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'User', id: 'LIST' }` is invalidated
            [{ type: 'User', id: 'LIST' }],
    }),

    addUser: builder.mutation({
      query(body) {
        return {
          url: `${USER_BASE_URL}/add`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),

    detailUser: builder.query({
      query: (id) => `${USER_BASE_URL}/detail/${id}`,
      providesTags: (result, error, id) => [{ type: 'User', id }],
    }),

    registerUser: builder.mutation({
      query(body) {
        return {
          url: `${USER_BASE_URL}/register`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const { useListUserQuery, useAddUserMutation, useDetailUserQuery } =
  userApi
