import React, { useState } from 'react'
import styled from 'styled-components'
import travelAlert2 from '../../assets/img/image1.jpg'
import { countryList } from '../../assets/countryList'
import { Autocomplete, TextField } from '@mui/material'

export default function ExploreCountries() {
  const [selectedOption, setSelectedOption] = useState('')

  const mappedCountries = countryList?.map((c) => ({
    label: c?.name,
    value: c?.cca2,
  }))

  const handleSubmit = (data) => {
    setSelectedOption(data.value)
    window.open(
      `https://alerts.assistxgateway.com/?cca2=${data.value}`,
      '_blank'
    )
  }

  return (
    <Wrapper id='explore'>
      <div className='lightBg'>
        <div className='container'>
          <Advertising className='flexSpaceCenter'>
            <AddLeft>
              <AddImgWrap1>
                <img src={travelAlert2} alt='office' />
              </AddImgWrap1>
            </AddLeft>
            <AddRight>
              <AddRightInner>
                <h2 className='font40 extraBold'>Explore Countries & Alerts</h2>
                <p className='font13'>
                  Explore Countries for essential information about your travel
                  destination's risk assessments, medical facilities, and travel
                  advisories, and stay informed during your trip with real-time
                  alerts from our system.
                </p>
                <Autocomplete
                  mt='5'
                  disablePortal
                  id='combo-box-demo'
                  options={mappedCountries}
                  getOptionLabel={(option) => option.label}
                  size='small'
                  sx={{ width: '100%' }}
                  onChange={(_, data) => handleSubmit(data)}
                  style={{ marginTop: '20px' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant='outlined'
                      placeholder='Search for country to see its risk data'
                    />
                  )}
                />
              </AddRightInner>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
`
const Advertising = styled.div`
  padding: 80px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 40px 0px;
  }
`
const AddLeft = styled.div`
  float: left;
  align-text: left;
  width: 50%;
  p {
    max-width: 600px;
  }
  @media (max-width: 860px) {
    width: 100%;
    margin-left: 25px;
    align-text: center;
    order: 1;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }

    p {
      margin: 0 auto;
    }
  }
  > h4 {
    padding-top: 20px;
  }
  > h1 {
    line-height: 3rem;
    margin: 0px 0 10px 0;
  }
`
const AddRight = styled.div`
  width: 50%;
  float: right;
  @media (max-width: 860px) {
    width: 100%;
    margin-bottom: 40px;
  }
`
const AddRightInner = styled.div`
  width: 100%;
`
const AddImgWrap1 = styled.div`
  width: 50%;
  margin: 0 6% 10px 6%;
  float: left;

  img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  @media (max-width: 860px) {
    width: 80%;
    float: center;
    order: 2;
    align-text: center;
  }
`
