import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      id='technology'
      width={43.42}
      height={43.42}
      viewBox='0 0 300 300'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <g>
          <path
            d='m134 144v-3.9c0-13.31-10.79-24.1-24.1-24.1h-35.8c-13.31 0-24.1 10.79-24.1 24.1v3.9'
            fill='#9bc9ff'
          />
          <path
            d='m134 148c-2.209 0-4-1.791-4-4v-3.9c0-11.082-9.018-20.1-20.1-20.1h-35.8c-11.082 0-20.1 9.018-20.1 20.1v3.9c0 2.209-1.791 4-4 4s-4-1.791-4-4v-3.9c0-15.494 12.605-28.1 28.1-28.1h35.8c15.494 0 28.1 12.605 28.1 28.1v3.9c0 2.209-1.791 4-4 4z'
            fill='#1e81ce'
          />
        </g>
        <g>
          <path
            d='m94 128h-4c-4.418 0-8-3.582-8-8v-20h20v20c0 4.418-3.582 8-8 8z'
            fill='#fff'
          />
          <path
            d='m94 132h-4c-6.617 0-12-5.383-12-12v-20c0-2.209 1.791-4 4-4h20c2.209 0 4 1.791 4 4v20c0 6.617-5.383 12-12 12zm-8-28v16c0 2.205 1.795 4 4 4h4c2.205 0 4-1.795 4-4v-16z'
            fill='#1e81ce'
          />
        </g>
        <g>
          <path
            d='m92 108c-14.359 0-26-11.641-26-26v-18h52v18c0 14.359-11.641 26-26 26z'
            fill='#fff'
          />
          <path
            d='m92 112c-16.543 0-30-13.457-30-30v-18c0-2.209 1.791-4 4-4h52c2.209 0 4 1.791 4 4v18c0 16.543-13.457 30-30 30zm-22-44v14c0 12.131 9.869 22 22 22s22-9.869 22-22v-14z'
            fill='#1e81ce'
          />
        </g>
        <g>
          <path
            d='m108.379 48h-2.379v-.214c0-6.509-5.277-11.786-11.786-11.786h-21.248c-8.266 0-14.966 6.7-14.966 14.966v13.034h48v4c0 6.627 5.373 12 12 12v-16-6.379c0-5.314-4.308-9.621-9.621-9.621z'
            fill='#cceaff'
          />
          <path
            d='m118 84c-8.822 0-16-7.178-16-16h-44c-2.209 0-4-1.791-4-4v-13.035c0-10.457 8.508-18.965 18.967-18.965h21.248c7.418 0 13.656 5.143 15.338 12.051 6.963.597 12.447 6.455 12.447 13.57v22.379c0 2.209-1.791 4-4 4zm-56-24h44c2.209 0 4 1.791 4 4v4c0 2.955 1.611 5.541 4 6.926v-17.305c0-3.1-2.521-5.621-5.621-5.621h-2.379c-2.209 0-4-1.791-4-4 0-4.508-3.492-8-7.785-8h-21.248c-6.047 0-10.967 4.918-10.967 10.965z'
            fill='#1e81ce'
          />
        </g>
        <g>
          <path d='m42 148h100v60h-100z' fill='#fff' />
          <path
            d='m142 212h-100c-2.209 0-4-1.791-4-4v-60c0-2.209 1.791-4 4-4h100c2.209 0 4 1.791 4 4v60c0 2.209-1.791 4-4 4zm-96-8h92v-52h-92z'
            fill='#1e81ce'
          />
        </g>
        <circle cx='92' cy='178' fill='#fff' r='10' />
        <g>
          <path
            d='m250 144v-3.9c0-13.31-10.79-24.1-24.1-24.1h-35.8c-13.31 0-24.1 10.79-24.1 24.1v3.9'
            fill='#fff'
          />
          <path
            d='m250 148c-2.209 0-4-1.791-4-4v-3.9c0-11.082-9.018-20.1-20.1-20.1h-35.8c-11.082 0-20.1 9.018-20.1 20.1v3.9c0 2.209-1.791 4-4 4s-4-1.791-4-4v-3.9c0-15.494 12.605-28.1 28.1-28.1h35.8c15.494 0 28.1 12.605 28.1 28.1v3.9c0 2.209-1.791 4-4 4z'
            fill='#1e81ce'
          />
        </g>
        <g>
          <path
            d='m210 128h-4c-4.418 0-8-3.582-8-8v-20h20v20c0 4.418-3.582 8-8 8z'
            fill='#fff'
          />
          <path
            d='m210 132h-4c-6.617 0-12-5.383-12-12v-20c0-2.209 1.791-4 4-4h20c2.209 0 4 1.791 4 4v20c0 6.617-5.383 12-12 12zm-8-28v16c0 2.205 1.795 4 4 4h4c2.205 0 4-1.795 4-4v-16z'
            fill='#1e81ce'
          />
        </g>
        <g>
          <path
            d='m208 108c-14.359 0-26-11.641-26-26v-18h52v18c0 14.359-11.641 26-26 26z'
            fill='#fff'
          />
          <path
            d='m208 112c-16.543 0-30-13.457-30-30v-18c0-2.209 1.791-4 4-4h52c2.209 0 4 1.791 4 4v18c0 16.543-13.457 30-30 30zm-22-44v14c0 12.131 9.869 22 22 22s22-9.869 22-22v-14z'
            fill='#1e81ce'
          />
        </g>
        <g>
          <path
            d='m224.379 48h-2.379v-.214c0-6.509-5.277-11.786-11.786-11.786h-21.248c-8.266 0-14.966 6.7-14.966 14.966v13.034h48v4c0 6.627 5.373 12 12 12v-16-6.379c0-5.314-4.308-9.621-9.621-9.621z'
            fill='#92ceff'
          />
          <path
            d='m234 84c-8.822 0-16-7.178-16-16h-44c-2.209 0-4-1.791-4-4v-13.035c0-10.457 8.508-18.965 18.967-18.965h21.248c7.418 0 13.656 5.143 15.338 12.051 6.963.597 12.447 6.455 12.447 13.57v22.379c0 2.209-1.791 4-4 4zm-56-24h44c2.209 0 4 1.791 4 4v4c0 2.955 1.611 5.541 4 6.926v-17.305c0-3.1-2.521-5.621-5.621-5.621h-2.379c-2.209 0-4-1.791-4-4 0-4.508-3.492-8-7.785-8h-21.248c-6.047 0-10.967 4.918-10.967 10.965z'
            fill='#1e81ce'
          />
        </g>
        <g>
          <path d='m158 148h100v60h-100z' fill='#fff' />
          <path
            d='m258 212h-100c-2.209 0-4-1.791-4-4v-60c0-2.209 1.791-4 4-4h100c2.209 0 4 1.791 4 4v60c0 2.209-1.791 4-4 4zm-96-8h92v-52h-92z'
            fill='#1e81ce'
          />
        </g>
        <circle cx='208' cy='178' fill='#fff' r='10' />
        <g>
          <path d='m30 208h240v16h-240z' fill='#92ceff' />
          <path
            d='m270 228h-240c-2.209 0-4-1.791-4-4v-16c0-2.209 1.791-4 4-4h240c2.209 0 4 1.791 4 4v16c0 2.209-1.791 4-4 4zm-236-8h232v-8h-232z'
            fill='#1e81ce'
          />
        </g>
        <g>
          <path d='m50 264v-40h16v40' fill='#92ceff' />
          <path
            d='m66 268c-2.209 0-4-1.791-4-4v-36h-8v36c0 2.209-1.791 4-4 4s-4-1.791-4-4v-40c0-2.209 1.791-4 4-4h16c2.209 0 4 1.791 4 4v40c0 2.209-1.791 4-4 4z'
            fill='#1e81ce'
          />
        </g>
        <g>
          <path d='m234 264v-40h16v40' fill='#92ceff' />
          <path
            d='m250 268c-2.209 0-4-1.791-4-4v-36h-8v36c0 2.209-1.791 4-4 4s-4-1.791-4-4v-40c0-2.209 1.791-4 4-4h16c2.209 0 4 1.791 4 4v40c0 2.209-1.791 4-4 4z'
            fill='#1e81ce'
          />
        </g>
        <g>
          <path d='m66 224h168v28h-168z' fill='#cceaff' />
          <path
            d='m234 256h-168c-2.209 0-4-1.791-4-4v-28c0-2.209 1.791-4 4-4h168c2.209 0 4 1.791 4 4v28c0 2.209-1.791 4-4 4zm-164-8h160v-20h-160z'
            fill='#1e81ce'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
