import React from 'react'
import styled from 'styled-components'
import ServiceBox from '../Elements/ServiceBox'

export default function System() {
  return (
    <Wrapper id='services'>
      <div className='lightBg' style={{ padding: '80px 0px' }}>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>Our Services </h1>
            <p className='font13'>
              AssistXGateway is complete technology suite for global assistance
              industry participants. We provide customer system, support system
              and provider system to manage every aspect of your assistance
              business.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className='flex'>
            <ServiceBoxWrapper>
              <ServiceBox
                icon='trip'
                title='Travel Risk Management Free'
                subtitle='AssistXGateway Customer system provides you and your employees information to prepare and prevent from health and security risks worldwide. We provide you with 24/7 comprehensive support & response wherever you are.'
              />
              <BtnWrapper>
                <a
                  activeClass='active'
                  className='radius6 NabBtn'
                  style={{ padding: '10px 15px', color: '#fff' }}
                  href='https://alerts.assistxgateway.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Get Started
                </a>
              </BtnWrapper>
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon='trip'
                title='Travel Risk Management Enterprise'
                subtitle='AssistXGateway Customer system provides you and your employees information to prepare and prevent from health and security risks worldwide. We provide you with 24/7 comprehensive support & response wherever you are.'
              />
              <BtnWrapper>
                <a
                  activeClass='active'
                  className='radius6 NabBtn'
                  style={{ padding: '10px 15px', color: '#fff' }}
                  href='https://alerts.assistxgateway.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Get Started
                </a>
              </BtnWrapper>
            </ServiceBoxWrapper>

            <ServiceBoxWrapper>
              <ServiceBox
                icon='monitor'
                title='Case Management Enterprise'
                subtitle='AssistXGateway Provider system helps you manage simple to complex assistance cases, notes, tasks, documents, checklists, workflows, reserves, guarantee of payments, invoices and global network of providers.'
              />
              <BtnWrapper>
                <a
                  activeClass='active'
                  className='radius6 NabBtn'
                  style={{ padding: '10px 15px', color: '#fff' }}
                  href='https://provider.medxgateway.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Get Started
                </a>
              </BtnWrapper>
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon='browser'
                title='API Services Enterprise'
                subtitle='AssistXGateway Support system helps you manage tickets, chats, calls, agents, teams, queues, communications, emails to successfully operates assistance help desks and call centers. Provides superior customer support.'
              />
              <BtnWrapper>
                <a
                  activeClass='active'
                  className='radius6 NabBtn'
                  style={{ padding: '10px 15px', color: '#fff' }}
                  href='https://assistxgateway.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Get Started
                </a>
              </BtnWrapper>
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
`
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
  padding: 20px 0px 0px 0px;
`
const ServiceBoxWrapper = styled.div`
  margin: 2rem 0rem;
  margin-right: 0.5%;
  padding: 40px 10px;
  border: 1px solid #dde1e6;
  border-radius: 5px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background-color: #f2f4f8;
  }
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    width: 100%;
  }
  padding-right: 40px;
`
const BtnWrapper = styled.div`
  max-width: 190px;
  margin-top: 20px;
  @media (max-width: 960px) {
    margin: auto;
  }
`
