import React from "react"
import { Routes, Route, useLocation } from "react-router-dom"
import Landing from "../screens/Home/Landing"
import { NewsDetailScreen } from "../screens/Home/NewsDetailScreen"
import { NewsListScreen } from "../screens/Home/NewsListScreen"
import { ResourcesScreen } from "../screens/Home/ResourcesScreen"
import { ResourcesDetailScreen } from "../screens/Home/ResourcesDetailScreen"
import { PrivacyPolicyScreen } from "../screens/Home/PrivacyPolicyScreen"
import { TermsConditionsScreen } from "../screens/Home/TermsConditionsScreen"

import GuestLayout from "../layouts/GuestLayout"
import { PricingScreen } from "../screens/Home/PricingScreen"

export const HomeRouter = () => {
  const location = useLocation()
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  return (
    <Routes>
      <Route element={<GuestLayout />}>
        <Route path='/' element={<Landing />} exact />
        <Route path='/news/detail' element={<NewsDetailScreen />} exact />
        <Route path='/news' element={<NewsListScreen />} exact />
        <Route path='/resources' element={<ResourcesScreen />} exact />
        <Route path='/pricing' element={<PricingScreen />} exact />
        <Route
          path='/resources/detail'
          element={<ResourcesDetailScreen />}
          exact
        />
        <Route path='/privacy-policy' element={<PrivacyPolicyScreen />} exact />
        <Route
          path='/terms-condition'
          element={<TermsConditionsScreen />}
          exact
        />
      </Route>
    </Routes>
  )
}
