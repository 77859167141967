import React, { useState } from 'react'
import styled from 'styled-components'
import Alert from '@mui/material/Alert'
import axios from 'axios'
import { Box, Grid, TextField } from '@mui/material'
import EmailField from '@mui/material/TextField'

export default function Contact() {
  const [fullName, setFullName] = useState('')
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')

  ///emal functions

  ///end  email fns
  const [data, setData] = useState('')
  const contactSubmit = async (event) => {
    event.preventDefault()
    axios
      .post(`/api/contactus-email/app/email-list/create`, {
        fullName,
        email,
        message,
        subject,
      })
      .then((res) => setData(res.data))
  }

  return (
    <>
      <Wrapper id='contact'>
        <div className='whiteBg' style={{ padding: '80px 0px' }}>
          <div className='container'>
            <HeaderInfo>
              <h1 className='font40 extraBold'>Let's get in touch</h1>
              <p className='font13'>
                Please contact us and we will get back to you as soon as
                possible.
                <br />
              </p>
            </HeaderInfo>

            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                <ContactBox>
                  <div className='row'>
                    <div className='col-sm-3'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='30'
                        height='30'
                        fill='currentColor'
                        className='bi bi-telephone-fill'
                        viewBox='0 0 16 16'
                        style={{ margin: '30px' }}
                      >
                        <path
                          fill-rule='evenodd'
                          d='M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z'
                        />
                      </svg>
                    </div>
                    <div className='col-sm-9'>
                      <h3 style={{ color: '#000', paddingBottom: '0.8rem' }}>
                        Phone:
                      </h3>
                      <p className='font14'> +61 (0)2 454 1328 </p>
                      <p className='font14'> +61 426 992 880 </p>
                    </div>
                  </div>
                </ContactBox>
              </div>
              <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                <ContactBox>
                  <div className='row'>
                    <div className='col-sm-3'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='30'
                        height='30'
                        fill='currentColor'
                        className='bi bi-map-fill'
                        viewBox='0 0 16 16'
                        style={{ margin: '30px' }}
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.502.502 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5V.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.498.498 0 0 0-.196 0L5 14.09zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1-.5-.1z'
                        />
                      </svg>
                    </div>
                    <div className='col-sm-9'>
                      <h3 style={{ color: '#000', paddingBottom: '0.8rem' }}>
                        Address:
                      </h3>
                      <p className='font14'>27/2 O'Connell St., Parramatta,</p>
                      <p className='font14'> NSW, 2150, Australia</p>
                    </div>
                  </div>
                </ContactBox>
              </div>
              <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                <ContactBox>
                  <div className='row'>
                    <div
                      className='col-sm-3'
                      style={{ alignText: 'center', justifyContent: 'center' }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='30'
                        height='30'
                        fill='currentColor'
                        className='bi bi-envelope-fill'
                        viewBox='0 0 16 16'
                        style={{ margin: '30px' }}
                      >
                        <path d='M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z' />
                      </svg>
                    </div>
                    <div className='col-sm-9'>
                      <h3 style={{ color: '#000', paddingBottom: '0.8rem' }}>
                        Email:
                      </h3>
                      <p className='font14'>support@assistxgateway.tech</p>
                      <p className='font14'>sales@assistxgateway.tech</p>
                    </div>
                  </div>
                </ContactBox>
                <Box
                  sx={{
                    marginTop: 2,
                  }}
                >
                  {data?.message === 'success' && (
                    <Alert severity='success'>Message send successfully.</Alert>
                  )}
                </Box>
              </div>
            </div>

            <div className='row' style={{ paddingBottom: '30px' }}>
              <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                <form component='form' onSubmit={contactSubmit}>
                  {/* <label className='font13'>First name:</label> */}
                  <Grid item xs={12}>
                    <TextField
                      autoComplete='given-name'
                      name='fname'
                      required
                      fullWidth
                      id='fname'
                      label='First Name'
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      autoFocus
                    />
                  </Grid>
                  {/* <input
                    type='text'
                    id='fname'
                    name='fname'
                    className='font20 extraBold'
                  /> */}
                  <Grid item xs={12} my={3}>
                    <EmailField
                      required
                      fullWidth
                      type='email'
                      id='email'
                      label='Email Address'
                      name='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete='email'
                    />
                  </Grid>
                  {/* <label className='font13'>Email:</label>
                  <input
                    type='text'
                    id='email'
                    name='email'
                    className='font20 extraBold'
                  /> */}
                  {/* <label className='font13'>Subject:</label> */}
                  <Grid item xs={12} my={3}>
                    <TextField
                      autoComplete='given-name'
                      name='subject'
                      required
                      fullWidth
                      id='subject'
                      label='Subject'
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      autoFocus
                    />
                  </Grid>
                  {/* <input
                    type='text'
                    id='subject'
                    name='subject'
                    className='font20 extraBold'
                  /> */}
                  <Grid item xs={12} my={3}>
                    <TextField
                      className='py-3'
                      required
                      fullWidth
                      name='message'
                      label='Message'
                      type='message'
                      id='message'
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      autoComplete='message'
                      multiline
                      rows={4}
                    />
                  </Grid>

                  {/* <label className='font13'>Message:</label>
                  <textarea
                    rows='4'
                    cols='50'
                    type='text'
                    id='message'
                    name='message'
                    className='font20 extraBold'
                  /> */}
                  <SumbitWrapper className='flex'>
                    <ButtonInput
                      type='submit'
                      value='Send Message'
                      className='pointer animate radius8'
                      style={{ maxWidth: '220px' }}
                    />
                  </SumbitWrapper>
                </form>
              </div>
              <div
                className='col-xs-12 col-sm-12 col-md-6 col-lg-6 flex p-3'
                style={{
                  boxShadow: '5px 2px 15px rgba(0, 0, 0, 0.3)',
                  padding: '0px',
                  height: '450px',
                  borderRadius: '10px',
                }}
              >
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.3599243379863!2d150.83738160000001!3d-33.777202500000016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b129b62de430155%3A0x31c971356777b5ed!2sGatewayX%20Techologies!5e0!3m2!1sen!2sph!4v1665757277900!5m2!1sen!2sph'
                  frameBorder='0'
                  title='googlemap'
                  allowFullScreen=''
                  aria-hidden='false'
                  tabIndex='0'
                  height='100%'
                  width='100%'
                />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.section`
  width: 100%;
`
const HeaderInfo = styled.div`
  padding: 0px 0px 40px 0px;
  @media (max-width: 860px) {
  }
`
const Form = styled.form`
  padding: 70px 40px 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`
const ButtonInput = styled.input`
  border: 1px solid #002984;
  background-color: #002984;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  font-size: 1.1rem;
  letter-spacing: 1px;
  :hover {
    background-color: #3f51bf;
    border: 1px solid #3f51bf;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`
const ContactBox = styled.form`
  // padding: 10px;
  // background-color: #002984;
  color: #002984;
  // border-radius: 0px;
  // box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  margin-bottom: 40px;
  > h3 {
    color: #000;
  }
`
