import React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { Container, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import './AdminCss.css'
import { Controller, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { useLoginMutation } from '../../rtkStore/services/baseApi'
import { setUser } from '../../rtkStore/authSlice'

const INIITAL_STATE = {
  email: '',
  password: '',
}

export const LoginScreen = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const signInButton = React.useRef()

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      ...INIITAL_STATE,
    },
  })

  // RTK Query
  const [loginUser, { isLoading: loggingIn }] = useLoginMutation()

  // methods
  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      signInButton?.current && signInButton?.current?.click()
    }
  }

  const handleSignIn = async (data) => {
    try {
      const userData = await loginUser(data).unwrap()
      dispatch(setUser(userData))
      navigate('/admin/dashboard')
      reset({ ...INIITAL_STATE })
    } catch (err) {
      toast.error(err?.data?.message ?? 'Something went wrong!')
    }
  }

  return (
    <Container className='dashboard-container-login' maxWidth='sm'>
      <Card className='dashboard-card'>
        <Typography className='loginHeading'>Login - Admin Panel</Typography>
        <div onKeyDown={handleEnterPress}>
          <Box mb={3}>
            <Controller
              name='email'
              control={control}
              rules={{ required: 'Please enter email' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Email Address'
                  placeholder='Enter email'
                  size='small'
                  type='email'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {errors.email && <p className='error'>{errors.email.message}</p>}
          </Box>

          <Box mb={3}>
            <Controller
              name='password'
              control={control}
              rules={{ required: 'Please enter password' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Password'
                  placeholder='Enter password'
                  size='small'
                  type='password'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {errors.password && (
              <p className='error'>{errors.password.message}</p>
            )}
          </Box>

          <div className='flex items-baseline  justify-between'>
            <div className='flex space-x-4'>
              <Button
                variant='contained'
                ref={signInButton}
                disabled={loggingIn}
                style={{ marginRight: '10px' }}
                onClick={handleSubmit((data) => handleSignIn(data))}
              >
                Sign In
              </Button>

              <Link to='/'>
                <Button>Cancel</Button>
              </Link>
            </div>
          </div>
        </div>
      </Card>
    </Container>
  )
}
