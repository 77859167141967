import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import worldMap from '../../assets/img/worldMap3.png'

const useStyles = makeStyles((theme) => ({
  counter: {
    color: '#3f51bf',
    fontSize: '5rem',
    fontWeight: 900,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  counterItem: {
    // textAlign: 'center',
    // paddingTop: '1rem',
  },
}))

export default function NumberCounter() {
  const classes = useStyles()

  // Counter values
  const [clients, setClients] = useState(0)
  const [products, setProducts] = useState(0)
  const [countries, setCountries] = useState(0)

  // Final values
  const finalClients = 246
  const finalProducts = 2000
  const finalCountries = 10000

  const counterRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0]
        if (firstEntry.isIntersecting) {
          const interval = setInterval(() => {
            setClients((prevClients) =>
              prevClients < finalClients ? prevClients + 1 : finalClients
            )
            setProducts((prevProducts) =>
              prevProducts < finalProducts ? prevProducts + 1 : finalProducts
            )
            setCountries((prevCountries) =>
              prevCountries < finalCountries
                ? prevCountries + 1
                : finalCountries
            )
          }, 10) // Interval duration (in milliseconds)

          // Cleanup function to clear the interval when the component unmounts
          return () => clearInterval(interval)
        }
      },
      { threshold: 0.5 } // 50% visibility threshold for triggering the counter
    )

    if (counterRef.current) {
      observer.observe(counterRef.current)
    }

    // Cleanup function to disconnect the observer when the component unmounts
    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current)
      }
    }
  }, [])
  return (
    <Wrapper id='numberCounter'>
      <div className='whiteBg'>
        <div className='container'>
          <h2 className='font40 extraBold'>Our Numbers</h2>
          <p className='font13'>
            Our global assistance technology suite has been trusted by many
            companies worldwide to manage their assistance business, support
            their customers, and keep their travellers safe. Learn more about
            our impressive track record and proven results.
          </p>
          <Advertising className='flexSpaceCenter'>
            <AddLeft>
              <div className={classes.counterItem}>
                <div ref={counterRef} className={classes.counter}>
                  <div className={classes.counterItem}>
                    <h2 className='font40Number extraBold'>{clients}</h2>
                    <Typography variant='subtitle1'>Countries</Typography>
                  </div>
                  <div className={classes.counterItem}>
                    <h2 className='font40Number extraBold'>{products}</h2>
                    <Typography variant='subtitle1'>Providers</Typography>
                  </div>
                  <div className={classes.counterItem}>
                    <h2 className='font40Number extraBold'>{countries}</h2>
                    <Typography variant='subtitle1'>Active Alerts</Typography>
                  </div>
                </div>
              </div>
            </AddLeft>
            <AddRight>
              <StoriesBoxWrapper>
                <img
                  src={worldMap}
                  alt='hero background'
                  style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '5px 5px 0px 0px',
                    float: 'right',
                    // overflow: 'hidden',
                  }}
                />
              </StoriesBoxWrapper>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 40px;
`
const Advertising = styled.div`
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 50px 0 30px 0;
    margin: 0px 0 0px 0;
  }
`
const AddLeft = styled.div`
  width: 30%;
  p {
    max-width: 600px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    padding: 0px 0px;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
  > h4 {
    padding-bottom: 20px;
  }
`
const StoriesBoxWrapper = styled.div`
  width: 100%;
  min-height: 450px;
  @media (max-width: 860px) {
    width: 100%;
    padding: 0px;
    margin: 0px;
    min-height: 250px;
  }
`
const AddRight = styled.div`
  width: 100%;
  float: right;
  padding-top: 40px;
  @media (max-width: 860px) {
    width: 100%;
    padding: 0px;
    margin: 0px;
  }
`
