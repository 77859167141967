import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import image1 from '../../assets/img/image1.jpg'
import image2 from '../../assets/img/image2.jpg'
import image3 from '../../assets/img/image3.jpg'

const useStyles = makeStyles({
  carouselContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: 'rgba(0, 0, 0 , 0.4)',
    padding: '20px',
    borderRadius: '20px',
  },
  heading: {
    marginBottom: 10,
    fontWeight: 'bold',
    fontSize: 24,
    textAlign: 'center',
    opacity: '1',
  },
  paragraph: {
    marginBottom: 20,
    fontSize: 16,
    textAlign: 'center',
  },
  bannerImage: {
    width: '100%',
    height: 150,
    objectFit: 'cover',
  },
})

const CarouselContainer = ({ heading, paragraph, imageUrl }) => {
  const classes = useStyles()

  return (
    <div className={classes.carouselContainer}>
      <Typography
        variant='h2'
        style={{ color: '#fff' }}
        className={classes.heading}
      >
        {heading}
      </Typography>
      <Typography
        variant='body1'
        style={{ color: '#fff' }}
        className={classes.paragraph}
      >
        {paragraph}
      </Typography>
      {/* <img src={imageUrl} alt='Banner' className={classes.bannerImage} /> */}
    </div>
  )
}

const CarouselWithBanner = () => {
  const carouselData = [
    {
      heading: 'Carousel 1',
      paragraph:
        'This is the first carousel container. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries',
      imageUrl: image1,
    },
    {
      heading: 'Carousel 2',
      paragraph:
        'This is the second carousel container. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries',
      imageUrl: image2,
    },
    {
      heading: 'Carousel 3',
      paragraph:
        'This is the third carousel container. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries',
      imageUrl: image3,
    },
  ]
  console.log(carouselData)

  return (
    <Carousel>
      {carouselData.map((data, index) => (
        <CarouselContainer
          key={index}
          heading={data.heading}
          paragraph={data.paragraph}
          imageUrl={data.imageUrl}
        />
      ))}
    </Carousel>
  )
}

export default CarouselWithBanner
